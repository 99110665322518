const pipe = (...fns) => x => fns.reduce((v, f) => f(v), x);
const compose = (...fns) => x => fns.reduceRight((v, f) => f(v), x);
const curry = fn => (...args) => fn.bind(null, ...args);

const map = curry((fn, arr) => arr.map(fn));

const filter = curry((fn, arr) => arr.filter(fn));

const find = curry((fn, arr) => arr.find(fn));

const join = curry((str, arr) => arr.join(str));

const split = curry((splitOn, str) => str.split(splitOn));

const tap = curry((fn, x) => {
  fn(x);
  return x;
});

export { pipe, compose, curry, map, join, split, tap, filter, find };
