import React, { Component } from "react";
import { connect } from "react-redux";
import { COP as currency } from "../../../../../utils";
import { Switch, withStyles } from "@material-ui/core/";
import FormDialog from "../../../../controls/dialog/FormDialog";
import NumberTextField from "../../../../controls/textfields/NumberTextField";
import { updateDiscountOnItems, updateDiscountOnTotal } from "../../../../../actions/cart";

const styles = () => ({
  switch: { marginLeft: "-20px" },
  label: { fontSize: "13px", fontWeight: 400 }
});

class DiscountPopup extends Component {
  initialState = { discount: "", checkedB: false, error: "", discountText: "Descuento en Dinero $" };

  state = { ...this.initialState };

  onDiscountChange = e => {
    let discount = "";
    const { value } = e.target;
    if (value !== "") {
      discount = e.target.value;
    }
    this.setState({ discount, error: "" });
  };

  onCancel = () => {
    this.setState({ ...this.initialState });
    this.props.close();
  };

  onSave = () => {
    const { discount } = this.state;
    if (discount === "") {
      this.setState({ error: "Ingrese el Valor" });
      return;
    }
    this.updateDiscountOnItems(discount);
  };

  updateDiscountOnTotal = discount => {
    const { summary } = this.props;
    if (currency(summary.total).value < discount) {
      this.setState({
        error: "El descuento introducido es mayor que el precio total neto."
      });
      return;
    }
    this.props.updateDiscountOnTotal(discount);
    this.setState({ ...this.initialState });
    this.props.close();
  };

  updateDiscountOnItems = discount => {
    const { summary, user } = this.props;
    const { checkedB } = this.state;
    const total = summary.netTotal;
    //let totalItems = 0;
    let discountTotal = discount;
    if (!checkedB) {
      discountTotal = Number(discount * 100) / total;
      if (summary.maxAmountDiscountTotal < discount && user.role !== "admin") {
        this.setState({
          error: `El mayor descuento permitido es de ${summary.maxAmountDiscountTotal}`
        });
        return;
      }
    } else {
      if (summary.maxPercentDiscountTotal < discount && user.role !== "admin") {
        this.setState({
          error: `El mayor descuento permitido es de ${summary.maxPercentDiscountTotal} %`
        });
        return;
      }
    }
    if (Math.round(total) < discount) {
      this.setState({ error: "El descuento ingresado es mayor al precio de la venta" });
      return;
    }
    this.props.updateDiscountOnItems(discountTotal);
    this.setState({ ...this.initialState });
    this.props.close();
  };

  handleChange = () => {
    const checkedB = !this.state.checkedB;
    const { netTotal } = this.props.summary;
    let { discount } = this.state;
    if (checkedB === true) {
      discount = discount !== "" ? (Number(discount) * 100) / Number(netTotal) : 0;
      this.setState({ discountText: "Descuento en Porcentaje %", checkedB, discount, error: "" });
    } else {
      discount = discount !== "" ? (Number(discount) * Number(netTotal)) / 100 : 0;
      this.setState({ discountText: "Descuento en Dinero $", checkedB, discount, error: "" });
    }
  };

  render() {
    const { discount, error, checkedB, discountText } = this.state;
    const { open, classes } = this.props;

    return (
      <FormDialog onSave={this.onSave} onCancel={this.onCancel} open={open} title="Descuento precio con IVA">
        <NumberTextField
          style={{ width: "250px" }}
          error={!!error}
          name="discount"
          value={discount}
          label="Cantidad"
          onChange={this.onDiscountChange}
          helperText={error}
        />
        <div>
          <Switch
            className={classes.switch}
            checked={checkedB}
            onChange={this.handleChange}
            value="checkedB"
            color="primary"
          />
          <span className={classes.label}>{discountText}</span>
        </div>
      </FormDialog>
    );
  }
}

function mapStateToProps(state) {
  return { user: state.auth.user };
}

const mapDispatchToProps = { updateDiscountOnItems, updateDiscountOnTotal };

const component = withStyles(styles, { withTheme: true })(DiscountPopup);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
