import React from "react";
import { TableRow, TableHead, Tooltip } from "@material-ui/core/";
import CustomTableCell from "../controls/CustomTableCell";
import DeleteButton from "../controls/DeleteButton";

const CartHeader = ({ isCartEmpty, onDeleteAll }) => (
  <TableHead>
    <TableRow>
      <CustomTableCell style={{ width: 150, textAlign: "center" }}>Producto</CustomTableCell>
      <CustomTableCell align="center">Precio</CustomTableCell>
      <CustomTableCell align="center">
        Cantidad
      </CustomTableCell>
      <Tooltip title="Cantidades disponibles">
        <CustomTableCell align="center">Dispon.</CustomTableCell>
      </Tooltip>
      <CustomTableCell align="right" style={{ paddingRight: "20px" }}>Total</CustomTableCell>
      <CustomTableCell align="right" style={{ width: 30, paddingRight: "5px" }}>
        {!isCartEmpty && <DeleteButton onDelete={onDeleteAll} />}
      </CustomTableCell>
    </TableRow>
  </TableHead>
);

export default CartHeader;
