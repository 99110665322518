import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import { BrowserRouter, Route } from "react-router-dom";

import App from "./components/app/App";
import store from "./store";

//import registerServiceWorker from "./registerServiceWorker";
import { IntlProvider } from "react-intl";
import "./index.css";

ReactDOM.render(
  <IntlProvider locale="en">
    <BrowserRouter>
      <Provider store={store}>
        <Route component={App} />
      </Provider>
    </BrowserRouter>
  </IntlProvider>,
  document.getElementById("root")
);

//registerServiceWorker();
