import { COP as currency } from "../utils";
import {
  ADD_ITEM_TO_LIST,
  EMPTY_LIST,
  SET_HEADER,
  UPDATE_HEADER,
  REMOVE_ITEM_FROM_LIST,
  CLEAR_LIST,
  HYDRATE_LIST,
  ADD_ITEM_SERIALS,
} from "../types";

const initialState = {
  serials: {},
  items: {},
  header: { vendorId: "", vendorName: "", payedAt: null, invoice: "", totalInvoice: "0", observation: "" },
};

const cloneObj = (state) => {
  const clone = { serials: {}, items: {}, header: {} };
  Object.assign(clone.serials, state.serials);
  Object.assign(clone.items, state.items);
  Object.assign(clone.header, state.header);
  return clone;
};

/*toma los datos enviados y asigna los valores a la cabecera dejando el datos de los items vacio  */
const setHeader = (oldState, data) => {
  const state = cloneObj(oldState);
  const { header, items, serials } = state;
  for (const key in items) {
    delete state.items[key];
  }
  for (const key in serials) {
    delete state.serials[key];
  }
  header.vendorId = data.vendorId;
  header.vendorName = data.vendorName;
  header.payedAt = data.payedAt ? data.payedAt : null;
  header.invoice = data.invoice ? data.invoice : "";
  header.totalInvoice = data.totalInvoice ? data.totalInvoice : "0";
  header.observation = data.observation ? data.observation : "";
  return state;
};

/* toma los datos enviados y asigna los valores a la cabecera dejando el datos de los items vacio */
const updateHeader = (oldState, data) => {
  const state = cloneObj(oldState);
  const { header } = state;
  header.vendorId = data.vendorId;
  header.vendorName = data.vendorName;
  header.payedAt = data.payedAt ? data.payedAt : null;
  header.invoice = data.invoice ? data.invoice : "";
  header.totalInvoice = data.totalInvoice ? data.totalInvoice : "0";
  header.observation = data.observation ? data.observation : "";
  return state;
};

let myProductId = "";

/*toma el item y asigna los valores a un item de la lista de items */
const setListItem = (state, item) => {
  // eslint-disable-next-line no-param-reassign
  state.items[item.id] = {};
  const newItem = state.items[item.id];
  newItem.productId = item.id;
  myProductId = item.id;
  newItem.name = item.name;
  newItem.qty = item.qty;
  newItem.sellingPrice = currency(item.sellingPrice).value;
  newItem.ivaPercent = item.ivaPercent;
  newItem.costPrice = currency(item.costPrice).value;
  newItem.ivaCost = item.ivaCost;
  const costPriceWithoutIva = newItem.costPrice / (1 + newItem.ivaPercent / 100);
  newItem.ivaAmountCost = Number(newItem.ivaPercent) > 0 ? costPriceWithoutIva * (Number(newItem.ivaPercent) / 100) : 0;
  newItem.hasSerial = item.hasSerial;
};

/* toma el item y asigna los valores a un item de la lista de items */
const setItemSerials = (state, serial) => {
  // eslint-disable-next-line no-param-reassign
  const key = serial.key;
  state.serials[key] = {};
  const newSerial = state.serials[key];
  newSerial.key = serial.key;
  newSerial.id = serial.id;
  newSerial.productId = serial.productId;
  newSerial.serial = serial.serial;
  newSerial.description = serial.description;
  newSerial.state = serial.state ? serial.state : "Stock";
};

/*agrega una lista de seriales de un producto a la lista de seriales general*/
const addItemSerials = (oldState, serials) => {
  serials.forEach((item) => {
    item.productId = myProductId;
  });
  const state = cloneObj(oldState);
  const newSerials = Object.values(serials);
  setItemSerials(state, newSerials[0]);
  newSerials.forEach((item) => {
    setItemSerials(state, item);
  });
  // for (let x = 0; x < newSerials.length; x++) {
  //   for (let j = 0; j < oldSerials.length; j++) {
  //     if (newSerials[x].id === oldSerials[j].id) {
  //       delete state.serials[j];
  //       setItemSerials(state, newSerials[x]);
  //     }
  //   }
  // }
  return state;
};

/*agrega un item nuevo a la lista de productos y aumenta el valor de totalInvoice*/
const addItemToList = (oldState, item) => {
  const state = cloneObj(oldState);
  setListItem(state, item);
  const newItem = state.items[item.id];
  const oldItem = oldState.items[item.id];
  const { header } = state;
  if (oldItem) {
    header.totalInvoice = currency(header.totalInvoice)
      .add(currency(newItem.costPrice).multiply(newItem.qty))
      .subtract(currency(oldItem.costPrice).multiply(oldItem.qty)).value;
  } else {
    header.totalInvoice = currency(header.totalInvoice).add(currency(newItem.costPrice).multiply(newItem.qty)).value;
  }
  return state;
};

/*elimina un item de la lista de items y resta su valor del totalInvoice */
const removeItemFromList = (oldState, item) => {
  const state = cloneObj(oldState);
  let remove = true;
  Object.values(state.serials).forEach((serial) => {
    if (serial.productId === item.productId) {
      if (serial.state !== "Vendido" && remove) {
        delete state.serials[serial.serialId];
      } else {
        remove = false;
      }
    }
  });
  if (remove) {
    delete state.items[item.productId];
    const { header } = state;
    header.totalInvoice = currency(header.totalInvoice).subtract(item.costPrice * item.qty).value;
  }
  return state;
};

/* Elimina la lista de los items sin modificar la informacion del header y dejando el totalInvoice en 0 */
const clearList = (oldState) => {
  const state = cloneObj(oldState);
  const { header, items, serials } = state;
  for (const key in items) {
    delete state.items[key];
  }
  for (const key in serials) {
    delete state.serials[key];
  }
  header.totalInvoice = "0";
  return state;
};

/*Toma la data y asignas los valores a la estructura principal tanto header como items */
const hydrateList = (state, data) => {
  const items = data.receivingDetails
    .map((detail) => ({
      productId: detail.productId,
      name: detail.product.name,
      qty: detail.qty,
      sellingPrice: currency(detail.sellingPrice).value,
      ivaPercent: detail.ivaPercent,
      costPrice: currency(detail.costPrice).value,
      ivaCost: detail.ivaPercent,
      ivaAmountCost: currency(detail.ivaAmountCost).value,
      hasSerial: detail.product.hasSerial,
    }))
    .reduce((a, b) => ({ ...a, ...{ [b.productId]: b } }), {});
  const serials = data.productSerials.map((serial, indice) => ({
    key: indice,
    id: serial.id,
    productId: serial.productId,
    serial: serial.serial,
    description: serial.extra ? serial.extra.description : null,
    state: serial.state,
  }));
  const header = {
    vendorId: data.receivingHeader.vendorId,
    vendorName: data.receivingHeader.vendor.name,
    payedAt: data.receivingHeader.payedAt,
    invoice: data.receivingHeader.invoice,
    totalInvoice: data.receivingHeader.totalInvoice,
    observation: data.receivingHeader.observation,
  };
  return { ...state, items, header, serials };
};

export default function receiving(state = initialState, action = {}) {
  switch (action.type) {
    case ADD_ITEM_TO_LIST:
      return addItemToList(state, action.data);

    case ADD_ITEM_SERIALS:
      return addItemSerials(state, action.serials);

    case EMPTY_LIST:
      return initialState;

    case SET_HEADER:
      return setHeader(state, action.data);

    case UPDATE_HEADER:
      return updateHeader(state, action.data);

    case REMOVE_ITEM_FROM_LIST:
      return removeItemFromList(state, action.data);

    case CLEAR_LIST:
      return clearList(state);

    case HYDRATE_LIST:
      return hydrateList(state, action.data);

    default:
      return state;
  }
}
