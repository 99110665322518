import React, { Fragment } from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import NumberFormatCustom from "./NumberFormatCustom";

// eslint-disable-next-line
//  TODO remove this and make it common if textfield and this has same styles.
const styles = theme => ({
  fillWidth: {
    width: "100% !important"
  },
  textField: {
    [theme.breakpoints.up("xs")]: {
      width: 250
    },
    [theme.breakpoints.up("sm")]: {
      width: 300
    },
    [theme.breakpoints.up("md")]: {
      width: 500
    },
    marginRight: 10
  },
  textFieldFormLabel: {
    fontSize: "1.05rem"
  }
});

const NumberTextField = props => {
  const { classes, autoComplete, className, fillWidth, InputProps, ...rest } = props;
  let ac = "off";
  if (autoComplete !== undefined) ac = autoComplete;

  let clsName = classNames(classes.textField, {
    [classes.fillWidth]: fillWidth
  });
  if (className) {
    clsName = classNames(classes.textField, className);
  }

  return (
    <Fragment>
      <TextField
        {...rest}
        InputLabelProps={{
          shrink: true,
          className: classes.textFieldFormLabel
        }}
        InputProps={{
          ...InputProps,
          inputComponent: NumberFormatCustom
        }}
        className={clsName}
        margin="normal"
        autoComplete={ac}
      />
    </Fragment>
  );
};

export default withStyles(styles, { withTheme: true })(NumberTextField);
