import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogContent, useMediaQuery, DialogActions, Button } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import TransportItemForm from "./TransportItemForm";
import { TRANSPORT } from "./AddNewAPU";
import { useError } from "../../../hooks/useErrors";

function TransportModal(props) {
  const { open, item, edit, sections, totalWeight, onClose, onSubmit } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const startWith = !edit
    ? null
    : {
        id: item.product.id,
        name: item.product.name,
        weight: item.weight,
        price: item.price,
        percentOfPrice: item.percentOfPrice,
        distance: item.distance,
        kmPrice: item.kmPrice
      };
  let basePrice = 0;
  for (const key in sections) {
    if (key !== TRANSPORT) {
      basePrice += sections[key].totalSectionPrice;
    }
  }
  const [mode, setMode] = useState(item.mode || "km");
  const [state, setState] = useState(
    startWith || {
      id: "" + new Date().getTime(),
      name: "",
      weight: totalWeight || 1,
      price: basePrice,
      percentOfPrice: 20,
      distance: 1,
      kmPrice: 1
    }
  );

  const [errors] = useError(() => {
    const errors = {};
    if (!state.name) {
      errors.name = "El nombre del item es requerido";
    }
    if (state.name && state.name.length > 191) {
      errors.name = "El nombre del item no debe superar 191 caracteres.";
    }
    if (mode === "km" && !state.weight) {
      errors.weight = "El peso es requerido";
    }
    if (mode === "km" && !state.distance) {
      errors.distance = "La distancia es requerida";
    }
    if (mode === "km" && !state.kmPrice) {
      errors.kmPrice = "El precio por kilometro es requerido";
    }
    if (mode === "percent" && !state.price) {
      errors.price = "El precio base es requerido";
    }
    if (mode === "percent" && !state.percentOfPrice) {
      errors.percentOfPrice = "El porcentaje es requerido";
    }
    return errors;
  }, [state]);

  const [total, setTotal] = useState(0);
  useEffect(() => {
    let total = 0;
    if (mode === "percent") {
      total = Number.isNaN(state.price * (state.percentOfPrice / 100)) ? 0 : state.price * (state.percentOfPrice / 100);
    } else {
      total = Number.isNaN(state.weight * state.distance * state.kmPrice)
        ? 0
        : state.weight * state.distance * state.kmPrice;
    }
    setTotal(total);
  }, [state, mode]);
  function onFormSubmit(ev) {
    ev.preventDefault();
    if (Object.keys(errors).length > 0 || !total) {
      return;
    }
    let form = { qty: 1, product: { id: state.id, name: state.name, unit: "" }, mode, name: state.name };
    if (mode === "km") {
      form = { ...form, weight: state.weight, distance: state.distance, kmPrice: state.kmPrice, totalPrice: total };
    } else {
      form = { ...form, price: state.price, percentOfPrice: state.percentOfPrice, totalPrice: total };
    }
    onSubmit(form);
    onClose();
  }

  function onChange(ev) {
    const name = ev.target.name;
    const value = name === "name" ? ev.target.value : +ev.target.value;
    setState({ ...state, [name]: value });
  }

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={onClose}>
      <DialogContent>
        <TransportItemForm
          totalWeight={totalWeight}
          startWithState={startWith}
          basePrice={basePrice}
          mode={mode}
          setMode={setMode}
          total={total}
          errors={errors}
          state={state}
          onChange={onChange}
          onSubmit={onFormSubmit}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={onFormSubmit} color="primary">
          Registrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

TransportModal.propTypes = {
  item: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default TransportModal;
