import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  List,
  withStyles,
  Divider,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelActions,
  ExpansionPanelDetails,
  Fab
} from "@material-ui/core";
import APUListItem from "./APUListItem";
import { COP as currency } from "../../../utils";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
const styles = theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  title: {
    flexBasis: "66.66%"
  },
  total: {
    flexBasis: "33.33%",
    textAlign: "end"
  },
  inset: {
    marginLeft: 92
  },
  footer: {
    justifyContent: "space-between"
  }
});

function APUSection(props) {
  const { classes, section, items, total, onItemClick, onDelete, onAdd } = props;
  const [expanded, setExpanded] = useState(false);
  const onExpand = (_, expand) => setExpanded(expand);
  return (
    <>
      <ExpansionPanel expanded={expanded} onChange={onExpand}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`contenido-${section}`}
          id={"encabezado-" + section}
        >
          <div className={classes.title}>
            <Typography variant="h6">{section}</Typography>
          </div>
          <div className={classes.total}>
            {!expanded && (
              <Typography variant="h6" component="span">
                {currency(total).format(true)}
              </Typography>
            )}
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <List className={classes.root}>
            {items.map((tool, index) => (
              <React.Fragment key={index}>
                <APUListItem {...tool} onClick={onItemClick} onDelete={onDelete} />
                {index < items.length - 1 && <Divider variant="inset" component="li" className={classes.inset} />}
              </React.Fragment>
            ))}
          </List>
        </ExpansionPanelDetails>
        <ExpansionPanelActions className={classes.footer}>
          <Typography variant="h6" component="span">
            <span>Total: </span>
            {currency(total).format(true)}
          </Typography>
          <Fab aria-label="añadir" color="primary" size="small" onClick={onAdd}>
            <AddIcon fontSize="large" />
          </Fab>
        </ExpansionPanelActions>
      </ExpansionPanel>
    </>
  );
}

APUSection.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      product: PropTypes.shape({
        id: PropTypes.string.isRequired
      }),
      qty: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      unit: PropTypes.string,
      totalPrice: PropTypes.number.isRequired,
      performance: PropTypes.number,
      weight: PropTypes.number
    })
  ).isRequired,
  onAdd: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired
};

export default withStyles(styles, { withTheme: true })(APUSection);
