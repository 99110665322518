import React, { Component } from "react";
import { COP as currency } from "../../../../utils";
import { Paper } from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";
import Form from "../../../controls/Form";
import NumberTextField from "../../../controls/textfields/NumberTextField";
import Message from "../../../controls/Message";
import CustomLabel from "./CustomLabel";
import { connect } from "react-redux";
import { onChangeBox } from "../../../../actions/cart";
import api from "../../../../api";
import BoxList from "../../../box/BoxList";

const styles = (theme) => ({
  textField: { width: "470px", [theme.breakpoints.up("md")]: { width: "470px" } },
});
/**
 * TODO: Lift el state de la caja seleccionada al componente padre para evitar que este conozca el Redux
 */
class NormalSaleForm extends Component {
  constructor(props) {
    super(props);
    this.state = { showMessage: false, boxes: [] };
  }

  async componentDidMount() {
    const res = await api.users.fetchById("my-user/me");
    api.cashRegister
      .fetchAll()
      .then((val) => {
        this.setState({
          boxes: val.data.filter((box) => box.state === "Abierto").map((box) => ({ value: box.id, label: box.name })),
          canOpenBox: res.data.canOpenBox,
        });
      })
      .catch((err) => console.error(err));
  }

  onChangeBox = (val) => this.props.onChangeBox(val);

  render() {
    const { errors, data, transactionId, cart, classes, onChange, onSubmit, onCancel } = this.props;
    let { summary } = cart;
    const { canOpenBox, showMessage } = this.state;
    const totalDiscount = currency(summary.discountOnTotal).format("$");
    summary.amountPaid = data.amountPaid;

    return (
      <Paper elevation={2}>
        <Form style={{ marginLeft: "0px", padding: "15px" }} id="customer" onSubmit={onSubmit} onCancel={onCancel}>
          <Message
            style={{ width: 430 }}
            title="Mensaje"
            message="Algo salio mal. Por favor Intente mas tarde"
            show={showMessage}
            isError={true}
            onCloseClick={this.onMessageCloseClick}
          />
          <CustomLabel
            labelStyle={{ color: "red" }}
            title="Identificador de la Transacción"
            text={transactionId}
            helperText="Por favor anote está id en caso de algún error al guardar."
          />
          <br />
          <CustomLabel title="Cliente" text={summary.customer ? summary.customer.name : ""} />
          <br />
          <CustomLabel title="Total Compra" text={currency(summary.total).format("$")} />
          <br />
          <CustomLabel title="Total Descuentos" text={totalDiscount + ` (${summary.discountPercent.toFixed(3)}%)`} />
          <br />
          <CustomLabel title="Total IVA" text={currency(summary.totalDetailsIva).format("$")} />
          <br />
          <CustomLabel title="Total Impoconsumo" text={currency(summary.totalDetailsImpoconsumo).format("$")} />
          <br />
          <CustomLabel title="Total Impuestos" text={currency(summary.taxAmount).format("$")} />
          <br />
          <CustomLabel title="Total Facturado" text={currency(summary.netTotal).format("$")} />
          <br />
          <CustomLabel title="Neto a Pagar" text={currency(summary.totalPay).format("$")} />
          <br />
          <NumberTextField
            autoFocus
            className={classes.textField}
            error={!!errors.amountPaid}
            name="amountPaid"
            value={data.amountPaid}
            label="Cantidad pagada por el cliente"
            onChange={onChange}
            helperText={errors.amountPaid}
          />
          <br />
          <CustomLabel title="Balance a devolver" text={currency(data.balanceToPay).format("$")} />
          <BoxList
            className={classes.textField}
            selectedBoxId={cart.boxId}
            errorSelectedBoxId={errors.boxId}
            onBoxDropdownChange={this.onChangeBox}
            canOpenBox={canOpenBox}
            setFirstLoad={this.props.onChangeBox}
          />
        </Form>
      </Paper>
    );
  }
}

const mapDispatchToProps = { onChangeBox };
function mapStateToProps(state) {
  return { boxId: state.cart.boxId };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(NormalSaleForm));
