import React, { Component, Fragment } from "react";
import { List, ListSubheader } from "@material-ui/core";
import { withRouter } from "react-router";
import {
  GroupAdd,
  Storage,
  SupervisorAccount,
  ShoppingBasket,
  StoreMallDirectorySharp,
  Assessment,
  More,
  LocalAtm,
  AssignmentInd,
  AssessmentOutlined,
} from "@material-ui/icons";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import SidebarMenu from "../../controls/SidebarMenu";
import { connect } from "react-redux";
import PrintIcon from "@material-ui/icons/Print";
import images from "../../images/logo.png";


const MENU_REGISTRY = [
  {
    title: "Clientes",
    content: <GroupAdd />,
    forAdmin: false,
    forSeller: false,
    forStorer: false,
    text: "customers",
    url: "/customers",
  },
  {
    title: "Compras",
    content: <SupervisorAccount />,
    forAdmin: false,
    forSeller: true,
    forStorer: false,
    text: "receivings",
    url: "/receivings",
  },
  {
    title: "Productos",
    content: <More />,
    forAdmin: false,
    forSeller: false,
    forStorer: false,
    text: "products",
    url: "/products",
  },
  {
    title: "Presupuestos",
    content: <AssessmentOutlined />,
    forAdmin: false,
    forSeller: false,
    forStorer: false,
    onlyFor: ["900303701", "900303701-0", "900303701-10", "900199755-1", "900688231-2", "24243669-7"],
    text: "budget",
    url: "/budget",
  },
  {
    title: "Gastos",
    content: <LocalAtm />,
    forAdmin: false,
    forSeller: false,
    forStorer: false,
    text: "expense",
    url: "/expense",
  },
  {
    title: "Inventario",
    content: <Storage />,
    forAdmin: false,
    forSeller: true,
    forStorer: false,
    text: "stock",
    url: "/stock",
  },
  {
    title: "Usuarios",
    content: <AssignmentInd />,
    forAdmin: false,
    forSeller: true,
    forStorer: true,
    text: "users",
    url: "/users",
  },

  {
    title: "Mantenimiento Celulares",
    content: <PhoneIphoneIcon />,
    forAdmin: false,
    forSeller: false,
    forStorer: true,
    text: "maintenance",
    url: "/maintenance",
  },

  {
    title: "Bordados",
    content: <PrintIcon />,
    forAdmin: false,
    forSeller: false,
    forStorer: false,
    onlyFor: ["900303701", "900303701-0", "900303701-10", "900199755-1", "900688231-2", "24243669-7"],
    text: "embroidery",
    url: "/embroidery",
  },
];

class Menus extends Component {
  state = {};

  isSelected = (path) =>
    this.props.history.location.pathname === `/${path}` || this.props.history.location.pathname.includes(`/${path}/`);

  onMenuClick = (route) => {
    this.props.history.push(route);
  };

  render() {
    const { user } = this.props;
    let menus =
      user.role !== "admin"
        ? user.role !== "storer"
          ? MENU_REGISTRY.filter((stat) => !stat.forSeller)
          : MENU_REGISTRY.filter((stat) => !stat.forStorer)
        : MENU_REGISTRY.filter((stat) => !stat.forAdmin);
    menus = menus.filter((menu) => {
      if (!menu.onlyFor) {
        return true;
      }
      return menu.onlyFor.includes(user.nit);
    });
    const content = menus.map((menu) => (
      <SidebarMenu
        key={menu.title}
        isSelected={this.isSelected(menu.text)}
        onClick={() => this.onMenuClick(menu.url)}
        text={menu.title}
        icon={menu.content}
      />
    ));
    return (
      <Fragment>
        <List>
          <SidebarMenu
            key="Venta"
            isSelected={this.isSelected("sale")}
            onClick={() => this.onMenuClick("/sale")}
            text="Venta"
            icon={<ShoppingBasket />}
          />
          <SidebarMenu
            key="Cajas"
            isSelected={this.isSelected("cashRegister")}
            onClick={() => this.onMenuClick("/cashRegister")}
            text="Cajas"
            icon={<StoreMallDirectorySharp />}
          />

          <ListSubheader>MASTER</ListSubheader>
          <div>{content}</div>

          <ListSubheader>INFORME</ListSubheader>
          <SidebarMenu
            isSelected={this.isSelected("invoice")}
            onClick={() => this.onMenuClick("/invoice")}
            text="Ventas"
            icon={<Assessment />}
          />
        </List>
        <div>
          <img src={images} alt="" style={{ width: "200px", marginTop: "30px" }} />
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return { user: state.auth.user };
}

export default withRouter(connect(mapStateToProps, null)(Menus));
