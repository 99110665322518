import React, { Component } from "react";
import { withStyles } from "@material-ui/core/";
import { AppBar, Tabs, Tab } from "@material-ui/core";

// eslint-disable-next-line
const styles = theme => ({
  tab: {
    boxShadow: "none",
    border: "1px solid #e0e0e0"
  },
  tabItem: {
    // fontSize: "12px"
  },
  indicator: {
    backgroundColor: "#3f51b5"
  }
});
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}
class CustomTabs extends Component {
  state = {};

  renderTabItems = () =>
    this.props.items.map((i, index) => (
      <Tab
        key={i}
        wrapped
        // classes={{ label: this.props.classes.tabItem }}
        label={i}
        className={this.props.classes.tabItem}
        {...a11yProps(index)}
      />
    ));

  render() {
    const { classes, value, onChange } = this.props;

    return (
      <AppBar position="static" className={classes.tab} color="default">
        <Tabs
          classes={{
            indicator: classes.indicator
          }}
          variant="scrollable"
          scrollButtons="auto"
          value={value}
          onChange={onChange}
        >
          {this.renderTabItems()}
        </Tabs>
      </AppBar>
    );
  }
}

export default withStyles(styles, { withTheme: true })(CustomTabs);
