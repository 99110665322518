import axios from "axios";

const apiBuilder = (mainRouteName, transformer) => ({
  fetchDetailsById: id =>
    mainRouteName === "products"
      ? axios.get(`/api/${mainRouteName}/${id}/details`)
      : axios.get(`/api/${mainRouteName}/${id}`),
  fetchById: (id, cancelToken) => axios.get(`/api/${mainRouteName}/${id}`, { cancelToken }),

  fetchByPages: (pageNum = 1, perPage = 10, cancelToken) =>
    axios.get(`/api/${mainRouteName}?per_page=${perPage}&page=${pageNum}`, { cancelToken }),

  fetchAll: (cancelToken = undefined) => axios.get(`/api/${mainRouteName}/all/items`, { cancelToken }),

  fetchAllQuery: (data, cancelToken = undefined) =>
    axios.get(`/api/${mainRouteName}/all/items`, { params: data, cancelToken }),

  searchByIdAndGetByPages: (id, cancelToken) =>
    axios.get(`/api/${mainRouteName}`, { params: { q: id, per_page: "10", page: "1" }, cancelToken }),

  searchByParamsAndGetByPages: (params, page = 1, per_page = 10, cancelToken) =>
    axios.get(`/api/${mainRouteName}`, { params: { ...params, per_page, page }, cancelToken }),

  searchByIdAndGetAll: (id, cancelToken) => axios.get(`/api/${mainRouteName}/all?q=${id}`, { cancelToken }),

  createNew: (item, cancelToken) => axios.post(`/api/${mainRouteName}`, item, { cancelToken }),

  update: (id, item, cancelToken) => axios.put(`/api/${mainRouteName}/${id}`, item, { cancelToken }),

  delete: (id, cancelToken) => axios.delete(`/api/${mainRouteName}/${id}`, { cancelToken }),

  sync: cancelToken => axios.post(`/api/${mainRouteName}/sync`, null, { cancelToken })
});

export default apiBuilder;
