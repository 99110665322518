import React, { useEffect, useState, memo } from "react";
import api from "../../../api";
import axios from "../../../api/axiosClient";
import SwipeableViews from "react-swipeable-views";
import CustomTabs from "../../controls/Tabs";
import {
  withStyles,
  Typography,
  ButtonGroup,
  IconButton,
  Box,
  FormGroup,
  FormControlLabel,
  Switch
} from "@material-ui/core";
import ProductListView from "./ProductListView";
import ListIcon from "@material-ui/icons/List";
import SearchIcon from "@material-ui/icons/Search";
import { virtualize } from "react-swipeable-views-utils";
import { getPaginationInfo } from "../../../utils";
import ProductSearchView from "./ProductSearchView";
import CircularLoader from "../../controls/loader/CircularLoader";
const VirtualizeSwipeableViews = virtualize(SwipeableViews);
const styles = theme => ({
  root: {
    flexGrow: 1,
    maxWidth: "100vw",
    backgroundColor: theme.palette.background.paper
  }
});

function ProductPicker(props) {
  const { classes } = props;
  const [productTypes, setProductTypes] = useState([]);
  const [options, setOptions] = useState({
    isRental: false
  });
  const [isLoading, setIsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [pickerMode, setPickerMode] = useState("list");

  const handleChange = (event, newValue) => {
    setCurrentIndex(newValue);
  };

  const handleChangeIndex = index => {
    setCurrentIndex(index);
  };

  const handleOptionChange = key => ev => {
    const value = ev.target.checked;
    setOptions({ ...options, [key]: value });
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchProductType = async () => {
      setIsLoading(true);
      try {
        let productTypes = await api.productType.fetchAll(source.token);
        productTypes = productTypes.data.sort((a, b) => {
          if (a.description < b.description) {
            return -1;
          }
          if (a.description > b.description) {
            return 1;
          }
          return 0;
        });
        setProductTypes(productTypes);
      } catch (error) {
        if (error instanceof axios.Cancel) {
          return;
        }
      }
      setIsLoading(false);
    };
    fetchProductType();
    return () => {
      source.cancel("Cancelado por Unmount");
    };
  }, []);
  return (
    <div className={classes.root}>
      <CircularLoader isLoading={isLoading} />
      <Box justifyContent="space-between" display="flex">
        <Box>
          <Typography variant="h5">Selecciona los productos</Typography>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch checked={options.isRental} onChange={handleOptionChange("isRental")} value={options.isRental} />
              }
              label="Solo para alquilar"
            />
          </FormGroup>
        </Box>
        <ButtonGroup aria-label="Selecciona el tipo de busqueda">
          <IconButton
            aria-label="Lista"
            color={pickerMode === "list" ? "secondary" : null}
            onClick={() => setPickerMode("list")}
          >
            <ListIcon />
          </IconButton>
          <IconButton
            aria-label="Busqueda de texto"
            color={pickerMode === "search" ? "secondary" : null}
            onClick={() => setPickerMode("search")}
          >
            <SearchIcon />
          </IconButton>
        </ButtonGroup>
      </Box>
      {pickerMode === "list" && (
        <>
          <CustomTabs onChange={handleChange} value={currentIndex} items={productTypes.map(tab => tab.description)} />
          <div>
            <VirtualizeSwipeableViews
              index={currentIndex}
              onChangeIndex={handleChangeIndex}
              slideRenderer={({ index, key }) => {
                const productTypeId = productTypes[index] ? productTypes[index].id : null;
                return (
                  <ViewContainer
                    productTypeId={productTypeId}
                    RenderComponent={ProductListView}
                    setIsLoading={setIsLoading}
                    options={options}
                    key={key}
                  />
                );
              }}
            />
          </div>
        </>
      )}
      {pickerMode === "search" && (
        <>
          <ProductSearchView options={options} />
        </>
      )}
    </div>
  );
}

function ViewContainer(props) {
  const { productTypeId, options, RenderComponent, setIsLoading } = props;
  const [products, setProducts] = useState({
    hasNextPage: false,
    isNextPageLoading: false,
    nextPageUrl: null,
    items: []
  });

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchProduct = async () => {
      if (!productTypeId) return;
      setIsLoading(true);
      try {
        let product = await api.product.searchByParamsAndGetByPages({
          productTypeId,
          isRental$eq: options.isRental ? 1 : undefined
        });
        const pageInfo = getPaginationInfo(product.headers.link);
        setProducts({
          items: pageInfo.current === "1" ? product.data : [...product.items, ...product.data],
          hasNextPage: pageInfo.currentPage !== pageInfo.next,
          isNextPageLoading: false,
          nextPageUrl: pageInfo.next
        });
      } catch (error) {
        if (error instanceof axios.Cancel) {
          return;
        }
      }
      setIsLoading(false);
    };
    fetchProduct();
    return () => {
      source.cancel("Cancelado por Unmount");
    };
  }, [productTypeId, setIsLoading, options]);
  async function loadNextPage() {
    if (products.isNextPageLoading || !products.nextPageUrl) {
      return;
    }
    const res = await axios.get(products.nextPageUrl);
    const pageInfo = getPaginationInfo(res.headers.link);
    if (pageInfo.currentPage === pageInfo.next) {
      setProducts({
        items: pageInfo.current === "1" ? res.data : [...products.items, ...res.data],
        hasNextPage: false,
        isNextPageLoading: false,
        nextPageUrl: null
      });
    } else {
      setProducts({
        items: pageInfo.current === "1" ? res.data : [...products.items, ...res.data],
        hasNextPage: true,
        isNextPageLoading: false,
        nextPageUrl: pageInfo.next
      });
    }
  }
  return (
    <div style={{ width: "100%", height: "70vh" }}>
      <ProductSelectionContext.Consumer>
        {({ key, mode }) => (
          <RenderComponent
            products={products.items}
            mode={mode}
            keyId={key}
            loadNextPage={loadNextPage}
            hasNextPage={products.hasNextPage}
            isNextPageLoading={products.isNextPageLoading}
          />
        )}
      </ProductSelectionContext.Consumer>
    </div>
  );
}

const PSC = React.createContext({
  key: "search",
  mode: "multipe"
});
PSC.displayName = "ProductSelection";
export const ProductSelectionContext = PSC;

export default withStyles(styles, { withTheme: true })(memo(ProductPicker));
