import {
  ADD_ITEM_TO_APU,
  REMOVE_ITEM_FROM_APU,
  EMPTY_APU,
  SET_APU_ATTRIBUTES,
  SET_SELLING_PRICE,
  HYDRATE_APU_FROM_REMOTE
} from "../types";

export const addItem = (item, section) => ({
  type: ADD_ITEM_TO_APU,
  data: item,
  section
});

export const removeItem = (item, section) => ({
  type: REMOVE_ITEM_FROM_APU,
  data: item,
  section
});

export const emptyAPU = () => ({ type: EMPTY_APU });

export const setAttributes = data => ({
  type: SET_APU_ATTRIBUTES,
  data: data
});

export const setSellingPrice = newPrice => ({
  type: SET_SELLING_PRICE,
  data: newPrice
});

export const hydrateAPU = data => ({
  type: HYDRATE_APU_FROM_REMOTE,
  data
});
