import axios from "axios";
import { setAuthorizationHeader } from "../utils";

setAuthorizationHeader(sessionStorage.getItem("token"));

// Add a response interceptor
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const { data } = error.response;
      if (data.errors) {
        return Promise.reject(data);
      }
      if (data.message) {
        return Promise.reject(new Error(data.message));
      }
    }
    return Promise.reject(error);
  }
);
axios.defaults.baseURL = process.env.NODE_ENV === "development" ? "http://127.0.0.1:3500" : "";

export default axios;
