import {
  ADD_ITEM_TO_BUDGET,
  ADD_TOP_LEVEL_SECTION_BUDGET,
  UPDATE_ITEM_BUDGET,
  REMOVE_ITEM_FROM_BUDGET,
  SET_BUDGET_ATTRIBUTES,
  EMPTY_BUDGET,
  HYDRATE_BUDGET_FROM_REMOTE,
  ADD_EXTRA_COST_TO_BUDGET,
  UPDATE_EXTRA_COST_FROM_BUDGET,
  DELETE_EXTRA_COST_FROM_BUDGET
} from "../types";
import update from "immutability-helper";

export const COST_AIU = "aiu";
export const COST_CONTRACT = "contract";
export const COST_OTHER = "others";

const initialState = {
  name: "",
  description: "",
  subtotal: 0, // El subtotal de las actividades, osea los children
  aiuSubtotal: 0, // Subtotal de los costos de COST_AIU
  contractSubtotal: 0, // Subtotal de los costos de contrato
  otherSubtotal: 0, // Subtotal de otros elementos
  total: 0, // El total: subtotal+subtotalAIU+subtotalContract+subtotalOthers
  children: [],
  extraCosts: [
    {
      type: COST_AIU,
      name: "Administración",
      factor: 21,
      preserve: true,
      value: null,
      defaultTo: 0,
      subtotal: 0
    },
    {
      type: COST_AIU,
      name: "Imprevistos",
      factor: 1,
      preserve: true,
      value: null,
      defaultTo: 0,
      subtotal: 0
    },
    {
      type: COST_AIU,
      name: "Utilidad",
      factor: 10,
      preserve: true,
      value: null,
      defaultTo: 0,
      subtotal: 0
    }
  ]
};

/**
 * Calcula la posición decodificada de un nuevo hijo en el árbol del presupuesto. Siempre se asume
 * que un nuevo hijo se inserta al final de la lista
 * @param {Array<number>} siblingsList
 * @param {Array<number>} parentDecoded
 */
function getDecodedForNew(siblingsList, parentDecoded) {
  const lastSibling = siblingsList[siblingsList.length - 1];
  if (!lastSibling) {
    return [...parentDecoded, 0];
  }
  const lastSiblingIdx = siblingsList.length || 0;
  return [...parentDecoded, lastSiblingIdx];
}

/**
 * Codifica la posición en una represencación String
 * @param {Array<number>} decodedNum
 */
function toCodedNum(decodedNum) {
  return decodedNum.map(num => num + 1).join(".");
}

/**
 * Suma el subtotal de un arreglo de hijos
 * @param {Array<{subtotal:number}>} children
 * @returns {number}
 */
function sumChildren(children) {
  return children.reduce((prev, curr) => prev + curr.subtotal, 0);
}

/**
 * Reindexa todos los items en una sublista
 * @param {Array<{children?: Array}>} items
 * @param {Array<number>} parentDecoded
 */
function updateIndexes(items = [], parentDecoded = []) {
  return items.map((item, idx) => {
    const decodedNum = [...parentDecoded, idx];
    const codedNum = toCodedNum(decodedNum);
    if (!item.children) {
      return { ...item, decodedNum, codedNum };
    }
    return { ...item, decodedNum, codedNum, children: updateIndexes(item.children, decodedNum) };
  });
}

/**
 * Actualiza el subtotal del árbol de presupuesto optmimizado para solo actualizar la rama que tuvo cambios
 * @param {Array<number>} decodedNum Camino hacia el APU que sufrió algun cambio. SOLO para APUs
 * @param {{subtotal:number, children?:Array}} pointer
 */
function updateSubtotal(decodedNum, pointer) {
  const [first, ...remainder] = decodedNum;
  // Si no tiene hijos el subtotal es el subtotal de ese item
  if (!pointer.children) {
    return update(pointer, {
      subtotal: {
        $set: pointer.subtotal || 0
      }
    });
  }

  // Si no tiene ningun hijo (porque se eliminó) el subtotal es 0
  if (pointer.children.length === 0 || !pointer.children[first]) {
    return update(pointer, {
      subtotal: {
        $set: sumChildren(pointer.children)
      }
    });
  }
  // Actualizo el estado del hijo de forma recursiva
  const updatedPointer = update(pointer, {
    children: {
      [first]: {
        $apply: function(childToUpdate) {
          if (!childToUpdate) return undefined;
          return updateSubtotal(remainder, childToUpdate);
        }
      }
    }
  });
  // actualizo el estado del subtotal actual con la suma de los hijos
  return update(updatedPointer, {
    subtotal: {
      $set: sumChildren(updatedPointer.children)
    }
  });
}

/**
 * Calcula el subtotal para un item extra del presupuesto
 * @param {{subtotal:number, factor: number, value: null|number, defaultTo: number }} extra
 */
export function calculateSubtotalForExtra(extra) {
  const factor = extra.factor / 100;
  return update(extra, {
    subtotal: { $set: extra.value !== null ? extra.value * factor : extra.defaultTo * factor }
  });
}

function updateExtraCostDefaults(state, type, newDefault) {
  return update(state, {
    extraCosts: {
      $apply: function(costs) {
        return costs.map(cost => {
          if (cost.type !== type) {
            return { ...cost };
          }
          return calculateSubtotalForExtra({ ...cost, defaultTo: newDefault });
        });
      }
    }
  });
}

/**
 *  Calcula el valor por defecto para un tipo de costo extra
 * @param {{ subtotal, aiuSubtotal }} budget
 * @param {COST_AIU|COST_CONTRACT|COST_OTHER} type
 */
export function defaultValue({ subtotal, aiuSubtotal }, type) {
  return type === COST_CONTRACT ? subtotal + aiuSubtotal : subtotal;
}

function updateDependantSubtotals(state) {
  try {
    const subtotal = sumChildren(state.children);
    const newState0 = update(state, { subtotal: { $set: subtotal } });
    const newState = updateExtraCostDefaults(newState0, COST_AIU, defaultValue(newState0, COST_AIU));
    const aiuSubtotal = sumChildren(newState.extraCosts.filter(cost => cost.type === COST_AIU));
    const newState1_5 = update(newState, { aiuSubtotal: { $set: aiuSubtotal } });
    const newState2 = updateExtraCostDefaults(newState1_5, COST_CONTRACT, defaultValue(newState1_5, COST_CONTRACT));
    const contractSubtotal = sumChildren(newState2.extraCosts.filter(cost => cost.type === COST_CONTRACT));
    const newState2_5 = update(newState2, { contractSubtotal: { $set: contractSubtotal } });
    const newState3 = updateExtraCostDefaults(newState2_5, COST_OTHER, defaultValue(newState2_5, COST_OTHER));
    const otherSubtotal = sumChildren(newState3.extraCosts.filter(cost => cost.type === COST_OTHER));
    return update(newState3, {
      subtotal: {
        $set: subtotal
      },
      aiuSubtotal: {
        $set: aiuSubtotal
      },
      contractSubtotal: {
        $set: contractSubtotal
      },
      otherSubtotal: {
        $set: otherSubtotal
      },
      total: {
        $set: subtotal + aiuSubtotal + contractSubtotal + otherSubtotal
      }
    });
  } catch (err) {
    console.error(err);
    return state;
  }
}

function addNewExtraCost(state, item) {
  return updateDependantSubtotals(
    update(state, {
      extraCosts: {
        $push: [item]
      }
    })
  );
}

function updateExtraCost(state, { item, changes }) {
  const itemIndex = state.extraCosts.indexOf(item);
  const applyChanges = item.preserve ? { ...changes, name: item.name, preserve: true } : changes;
  return updateDependantSubtotals(
    update(state, {
      extraCosts: {
        $splice: [[itemIndex, 1, { ...item, ...applyChanges }]]
      }
    })
  );
}

function deleteExtraCost(state, item) {
  const itemIndex = state.extraCosts.indexOf(item);
  if (item.preserve) {
    console.warn("No se debe eliminar este item");
    return state;
  }
  return updateDependantSubtotals(
    update(state, {
      extraCosts: {
        $splice: [[itemIndex, 1]]
      }
    })
  );
}

function onAddNewSection(state) {
  const decoded = getDecodedForNew(state.children, []);
  return update(state, {
    children: {
      $push: [
        {
          text: "Nuevo Capitulo",
          codedNum: toCodedNum(decoded),
          decodedNum: decoded,
          type: "section",
          subtotal: 0,
          children: []
        }
      ]
    }
  });
}

function addItem(state, parent, itemData) {
  const item = {
    ...itemData,
    decodedNum: getDecodedForNew(parent.children, parent.decodedNum),
    codedNum: toCodedNum(getDecodedForNew(parent.children, parent.decodedNum))
  };
  const modifiedParent = { ...parent, children: [...parent.children, item] };

  const addedChild = update(state, {
    children: {
      $apply: function(oldState) {
        let pointer = { children: oldState };
        parent.decodedNum.forEach((num, idx) => {
          if (idx === parent.decodedNum.length - 1) {
            pointer.children.splice(num, 1, modifiedParent);
          } else {
            pointer = pointer.children[num];
          }
        });
        return [...oldState];
      }
    }
  });

  return updateDependantSubtotals(updateSubtotal(item.decodedNum, addedChild));
}

function updateItem(state, item, changes) {
  const updatedChild = update(state, {
    children: {
      $apply: function(oldState) {
        let pointer = { children: oldState };
        item.decodedNum.forEach((num, idx) => {
          if (idx === item.decodedNum.length - 1) {
            pointer.children[num] = { ...pointer.children[num], ...changes };
          } else {
            pointer = pointer.children[num];
          }
        });
        return [...oldState];
      }
    }
  });

  return item.type === "apu" ? updateDependantSubtotals(updateSubtotal(item.decodedNum, updatedChild)) : updatedChild;
}

function deleteItem(state, item) {
  const deletedChild = update(state, {
    children: {
      $apply: function(oldState) {
        let pointer = { children: oldState };
        item.decodedNum.forEach((num, idx) => {
          if (idx === item.decodedNum.length - 1) {
            pointer.children.splice(num, 1);
          } else {
            pointer = pointer.children[num];
          }
        });
        return updateIndexes(oldState, []);
      }
    }
  });

  return updateDependantSubtotals(updateSubtotal(item.decodedNum, deletedChild));
}

function setAttributes(state, data) {
  return update(state, {
    name: {
      $set: data.name
    },
    description: {
      $set: data.description
    }
  });
}

export default function budget(state = initialState, action) {
  switch (action.type) {
    case ADD_TOP_LEVEL_SECTION_BUDGET:
      return onAddNewSection(state);
    case ADD_ITEM_TO_BUDGET:
      return addItem(state, action.data.parent, action.data.item);
    case UPDATE_ITEM_BUDGET:
      return updateItem(state, action.data.item, action.data.changes);
    case REMOVE_ITEM_FROM_BUDGET:
      return deleteItem(state, action.data);
    case SET_BUDGET_ATTRIBUTES:
      return setAttributes(state, action.data);
    case ADD_EXTRA_COST_TO_BUDGET:
      return addNewExtraCost(state, action.data);
    case UPDATE_EXTRA_COST_FROM_BUDGET:
      return updateExtraCost(state, action.data);
    case DELETE_EXTRA_COST_FROM_BUDGET:
      return deleteExtraCost(state, action.data);
    case EMPTY_BUDGET:
      return initialState;
    case HYDRATE_BUDGET_FROM_REMOTE:
      return action.data;
    default:
      return state;
  }
}
