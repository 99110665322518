import withContainer from "../../controls/withContainer";
import React, { useState, useEffect } from "react";
import { Grid, withStyles, Typography } from "@material-ui/core";
import APUSection from "./APUSection";
import AddItemModal from "./AddItemModal";
import { ProductSelectionContext } from "../../products/ProductPicker/ProductPicker";
import { initializeSelected, updateSelectedProduct } from "../../../actions/productList";
import { addItem, removeItem, setAttributes, hydrateAPU, emptyAPU } from "../../../actions/apu";
import { connect } from "react-redux";
import EditItemModal from "./EditItemModal";
import TransportModal from "./TransportModal";
import Form from "../../controls/Form";
import CustomTextField from "../../controls/textfields/CustomTextField";
import { useError } from "../../../hooks/useErrors";
import { COP as currency } from "../../../utils";
import api from "../../../api";

export const TOOLS = "tools";
export const MATERIALS = "materials";
export const WORKFORCE = "workforce";
export const TRANSPORT = "transport";

const styles = theme => ({
  gridItem: {
    flexFlow: "column",
    padding: 8
  },
  form: {
    margin: theme.spacing(3)
  }
});

function AddNewAPU(props) {
  const {
    isEdit,
    setTitle,
    initializeSelected,
    productList,
    id,
    showError,
    setIsLoading,
    setShowMessageDialog,
    classes,
    addItem,
    removeItem,
    updateSelected,
    setAttributes,
    hydrateAPU,
    emptyAPU,
    history,
    apu
  } = props;
  const { sections, totalAPUPrice: totalPrice, totalWeight, name, description } = apu;
  setTitle({
    edit: "Editar APU",
    register: "Registrar nuevo APU"
  });
  productList[TOOLS] || initializeSelected(TOOLS);
  productList[MATERIALS] || initializeSelected(MATERIALS);
  productList[WORKFORCE] || initializeSelected(WORKFORCE);
  productList[TRANSPORT] || initializeSelected(TRANSPORT);

  useEffect(() => {
    async function fetchById(id) {
      try {
        setIsLoading(true);
        const res = await api.apu.fetchById(id);
        hydrateAPU(res.data);
      } catch (err) {
        showError(err);
      } finally {
        setIsLoading(false);
      }
    }
    if (isEdit && id && id !== apu.id) {
      fetchById(id);
    } else if (!isEdit && apu.id) {
      emptyAPU();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, id]);

  async function createNew(data) {
    await api.apu.createNew(data);
  }

  async function update(data) {
    await api.apu.update(id, data);
  }
  function onChange(ev) {
    const fieldName = ev.target.name;
    const val = ev.target.value;
    setAttributes({ description, name, [fieldName]: val });
  }
  const [errors] = useError(() => {
    const errors = {};
    if (!name) {
      errors.name = "El nombre es requerido";
    }
    if (name && name.length > 191) {
      errors.name = "El nombre supera el tamaño maximo de caracteres";
    }
    if (description && description.length > 600) {
      errors.description = "La descripción supera el tamaño maximo de caracteres";
    }
    return errors;
  }, [name, description]);
  const [currentSection, setCurrentSection] = useState(TOOLS);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openTransport, setOpenTransport] = useState(false);
  const [editingTransport, setEditingTransport] = useState(false);
  const [currentItem, setCurrentItem] = useState({ product: { id: null } });
  function onAddItem(section) {
    return () => {
      setCurrentSection(section);
      if (section === TRANSPORT) {
        setOpenTransport(true);
        setEditingTransport(false);
      } else {
        setOpen(true);
      }
    };
  }
  function updateItem(section) {
    return product => () => {
      const currentItem = sections[section].items[product.id];
      setCurrentItem(currentItem);
      setCurrentSection(section);
      if (section === TRANSPORT) {
        setOpenTransport(true);
        setEditingTransport(true);
      } else {
        setOpenEdit(true);
      }
    };
  }
  function deleteItem(section) {
    return product => {
      const currentItem = sections[section].items[product.id];
      removeItem(currentItem, section);
      updateSelected(currentSection, product, 0); // Deselecciona
    };
  }
  function onItemFormSubmit(formData) {
    const item = { ...formData, name: formData.product.name, unit: formData.product.unit };
    addItem(item, currentSection);
    updateSelected(currentSection, formData.product, 0); // Deselecciona
  }

  async function onSubmit(ev) {
    ev.preventDefault();

    try {
      setIsLoading(true);
      if (!isEdit) {
        await createNew(apu);
      } else {
        await update(apu);
      }
      setShowMessageDialog(true);
      emptyAPU();
    } catch (err) {
      showError(err);
    } finally {
      setIsLoading(false);
    }
  }

  function onCancel() {
    emptyAPU();
    history.goBack();
  }

  return (
    <>
      <ProductSelectionContext.Provider value={{ key: currentSection, mode: "single" }}>
        <AddItemModal onClose={() => setOpen(!open)} open={open} onSubmit={onItemFormSubmit} />
        <EditItemModal
          onClose={() => setOpenEdit(!openEdit)}
          item={currentItem}
          open={openEdit}
          onSubmit={onItemFormSubmit}
        />
        <TransportModal
          key={`transport-${new Date().getTime()}`} //Rerender en cada apertura para evitar derivar estado de props
          onClose={() => setOpenTransport(!openTransport)}
          item={currentItem}
          sections={sections}
          open={openTransport}
          totalWeight={totalWeight}
          edit={editingTransport}
          onSubmit={onItemFormSubmit}
        />
      </ProductSelectionContext.Provider>
      <Form className={classes.form} onSubmit={onSubmit} onCancel={onCancel}>
        <Grid id="registrer" container spacing={1}>
          <Grid item xs={12}>
            <CustomTextField
              fillWidth
              label="Nombre"
              id="name"
              name="name"
              value={name}
              error={!!errors.name}
              helperText={errors.name ? errors.name : null}
              maxLength={!errors.name ? 191 : null}
              required
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              multiline
              rows="4"
              fillWidth
              label="Descripción"
              id="description"
              name="description"
              error={!!errors.description}
              helperText={errors.description ? errors.description : null}
              maxLength={!errors.description ? 600 : null}
              onChange={onChange}
              value={description}
            />
          </Grid>
        </Grid>
        <Grid id="apu" container>
          <Grid item xs={12} md={6} className={classes.gridItem}>
            <APUSection
              items={Object.values(sections.tools.items)}
              total={sections.tools.totalSectionPrice}
              section="Herramientas"
              onAdd={onAddItem(TOOLS)}
              onItemClick={updateItem(TOOLS)}
              onDelete={deleteItem(TOOLS)}
            />
            <APUSection
              items={Object.values(sections.materials.items)}
              total={sections.materials.totalSectionPrice}
              section="Materiales"
              onAdd={onAddItem(MATERIALS)}
              onItemClick={updateItem(MATERIALS)}
              onDelete={deleteItem(MATERIALS)}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.gridItem}>
            <APUSection
              items={Object.values(sections.workforce.items)}
              total={sections.workforce.totalSectionPrice}
              section="Personal"
              onAdd={onAddItem(WORKFORCE)}
              onItemClick={updateItem(WORKFORCE)}
              onDelete={deleteItem(WORKFORCE)}
            />
            <APUSection
              items={Object.values(sections.transport.items)}
              total={sections.transport.totalSectionPrice}
              section="Transporte"
              onAdd={onAddItem(TRANSPORT)}
              onItemClick={updateItem(TRANSPORT)}
              onDelete={deleteItem(TRANSPORT)}
            />
          </Grid>
        </Grid>
        <Typography variant="h5" align="right" style={{ marginTop: 16 }}>
          <strong>TOTAL COSTO DIRECTO: </strong> {currency(totalPrice).format(true)}
        </Typography>
      </Form>
    </>
  );
}

function mapStateToProps(state) {
  return {
    productList: state.productList,
    sections: state.apu.sections,
    totalPrice: state.apu.totalAPUPrice,
    totalWeight: state.apu.totalWeight,
    name: state.apu.name,
    apu: state.apu,
    description: state.apu.description
  };
}
const mapDispatchToProps = {
  initializeSelected,
  addItem,
  removeItem,
  updateSelected: updateSelectedProduct,
  setAttributes,
  hydrateAPU,
  emptyAPU
};
const Component = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNewAPU);

export default withContainer(withStyles(styles, { withTheme: true })(Component));
