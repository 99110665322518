import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ProductSelectionContext } from "../../products/ProductPicker/ProductPicker";
import Form from "../../controls/Form";
import { Grid, Avatar, makeStyles, Typography, Box } from "@material-ui/core";
import { getFirstLetters, COP as currency } from "../../../utils";
import classNames from "classnames";
import NumberTextField from "../../controls/textfields/NumberTextField";
import { WORKFORCE } from "./AddNewAPU";
import { useError } from "../../../hooks/useErrors";
import { connect } from "react-redux";
import { setSellingPrice } from "../../../actions/apu";
import { MINOR_TOOL_ID } from "../../../reducers/apu";

const useStyles = makeStyles(theme => ({
  fitWidth: {
    width: "100%",
    margin: 0
  },
  noDisplay: {
    display: "none"
  },
  form: {
    margin: 0,
    padding: theme.spacing(2)
  },
  outOfStockAvatar: {
    backgroundColor: theme.palette.grey[400] + " !important"
  },
  avatar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    textTransform: "uppercase",
    fontSize: theme.typography.fontSize * 4,
    margin: `${theme.spacing(2)}px auto`,
    width: 80,
    height: 80,
    [theme.breakpoints.up("sm")]: {
      width: 120,
      height: 120
    },
    [theme.breakpoints.up("md")]: {
      width: 160,
      height: 160
    },
    [theme.breakpoints.up("lg")]: {
      width: 180,
      height: 180
    },
    [theme.breakpoints.up("xl")]: {
      width: 240,
      height: 240
    }
  }
}));

const initialState = {
  performance: 1,
  benefits: null,
  weight: null
};

function ItemForm(props) {
  let localRef = null;
  const classes = useStyles();
  const { key } = useContext(ProductSelectionContext);
  const { productList, updateSelected, onSubmit, startWithState, setSellingPrice, sellingPrice, onCancel } = props;
  const selected = Object.entries(productList[key].selected)[0];
  const [, { product, qty }] = selected || [null, { product: { weight: null }, qty: 0 }]; // Obtiene los valores de selected
  const [state, setState] = useState(startWithState || initialState);
  useEffect(() => {
    setState({ ...state, weight: startWithState ? startWithState.weight : product.weight || 0 }); // Pone el peso inicial al formulario
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product.weight, startWithState]);
  useEffect(() => {
    if (localRef) {
      // Tenemos que enfocar la cantidad manualmente en cada cambio poque sino pierde el foco por el rerender del componente
      localRef.focus();
    }
  }, [qty, localRef]);
  const [errors] = useError(
    function checkForErrors() {
      const errors = {};
      if (!qty) {
        errors.qty = "La cantidad es requerida";
      }
      if (!state.performance) {
        errors.performance = "El rendimiento es requerido";
      }
      return errors;
    },
    [state, qty]
  );
  const avatarName = selected ? getFirstLetters(product.name, 2) : null;
  const hasStock = !selected
    ? false
    : product.stock && product.stock.qty && product.stock.qty > 0 && product.stock.qty >= qty;
  const canEditPrice = product.id === MINOR_TOOL_ID;
  const unitPrice = canEditPrice ? sellingPrice : product.sellingPrice;
  function onChange(ev) {
    const name = ev.target.name;
    const val = ev.target.value.endsWith(".") ? ev.target.value : +ev.target.value; // Infortunadamente tenemos que hacer esto para que se registren los decimales
    switch (name) {
      case "qty":
        updateSelected(key, product, ev.target.value);
        break;
      case "sellingPrice":
        product.sellingPrice = val || 0; // evita que el cambio del valor del producto se pierda cuando se envía el form
        canEditPrice && setSellingPrice(val);
        break;
      default:
        setState({ ...state, [name]: val });
        break;
    }
  }
  function getTotal() {
    if (qty && product && unitPrice && state.performance) {
      return (qty * unitPrice) / state.performance + (qty * unitPrice * state.benefits) / state.performance / 100;
    }
    return 0;
  }

  function onFormSubmit(ev) {
    ev.preventDefault();
    const hasErrors = Object.keys(errors).length > 0;
    if (hasErrors) {
      return;
    }
    onSubmit({
      product,
      qty,
      totalPrice: getTotal(),
      ...state,
      unitWeight: state.weight,
      weight: state.weight !== null ? state.weight * qty : null
    });
  }
  function onFormCancel() {
    onCancel();
  }
  return (
    <Form id="item" onSubmit={onFormSubmit} onCancel={onFormCancel} className={classes.form}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Avatar
            className={classNames(classes.avatar, {
              [classes.outOfStockAvatar]: !hasStock
            })}
          >
            {avatarName}
          </Avatar>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            {selected ? product.name : null}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1} alignContent="center" alignItems="flex-end">
            {canEditPrice && (
              <Grid item xs={12}>
                <NumberTextField
                  className={classes.fitWidth}
                  name="sellingPrice"
                  required
                  value={sellingPrice}
                  label="Precio Unitario"
                  onChange={onChange}
                />
              </Grid>
            )}
            <Grid item xs={9}>
              <NumberTextField
                className={classes.fitWidth}
                inputRef={ref => {
                  if (ref) {
                    localRef = ref;
                  }
                }}
                name="qty"
                error={selected && !!errors.qty}
                required
                value={selected ? selected[1].qty : ""}
                label="Cantidad"
                helperText={selected && errors.qty ? errors.qty : null}
                disabled={!selected}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1">{product.unit}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <NumberTextField
            className={classes.fitWidth}
            error={!!errors.performance}
            name="performance"
            required
            value={selected ? state.performance : ""}
            helperText={selected && errors.performance ? errors.performance : null}
            label="Rendimiento"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <NumberTextField
            className={classNames(classes.fitWidth, {
              [classes.noDisplay]: key === WORKFORCE
            })}
            aria-hidden={key === WORKFORCE}
            name="weight"
            value={selected ? state.weight : ""}
            label="Peso Unitario (Kg)"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <NumberTextField
            className={classNames(classes.fitWidth, {
              [classes.noDisplay]: key !== WORKFORCE
            })}
            aria-hidden={key !== WORKFORCE}
            name="benefits"
            value={state.benefits}
            label="Prestaciones (%)"
            onChange={onChange}
          />
        </Grid>
      </Grid>
      <Box marginTop={2}>
        <Typography align="right">
          <strong>Valor Unitario:</strong> {currency(unitPrice || 0).format(true)}
        </Typography>
        <Typography align="right">
          <strong>Peso Total:</strong> {currency(state.weight * qty).format()}
        </Typography>
        <Typography align="right">
          <strong>Valor Total:</strong> {currency(getTotal()).format(true)}
        </Typography>
      </Box>
    </Form>
  );
}

ItemForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

ItemForm.defaultProps = {
  onCancel: () => {}
};

const mapDispatchToProps = {
  setSellingPrice
};

function mapStateToProps(state) {
  return {
    sellingPrice: state.apu.sections.tools.items[MINOR_TOOL_ID].product.sellingPrice
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemForm);
