import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import { Menu, MenuItem, IconButton } from "@material-ui/core";
import { logout } from "../../../actions/auth";
import { withRouter } from "react-router";

// eslint-disable-next-line
const styles = theme => ({
  menuLeft: {
    float: "right"
  },
  menuItem: {
    fontSize: "13.5px",
    padding: "5px 20px 5px 20px"
  }
});

class Menus extends Component {
  state = { anchorEl: null };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  logout = () => {
    this.props.logout();
  };

  myProfile = () => {
    this.props.history.push("/my-profile");
  };

  render() {
    const { anchorEl } = this.state;
    const { classes } = this.props;
    const open = Boolean(anchorEl);

    return (
      <Fragment>
        <IconButton
          className={classes.menuLeft}
          aria-owns={open ? "menu-appbar" : null}
          aria-haspopup="true"
          onClick={this.handleMenu}
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={open}
          onClose={this.handleClose}
        >
          <MenuItem className={classes.menuItem} onClick={this.myProfile}>
            Mi Perfil
          </MenuItem>
          <MenuItem className={classes.menuItem} onClick={this.logout}>
            Salida
          </MenuItem>
        </Menu>
      </Fragment>
    );
  }
}

export default withRouter(
  connect(
    null,
    { logout }
  )(withStyles(styles, { withTheme: true })(Menus))
);
