import { COP as currency, uuidv4 } from "../utils";
import {
  ADD_ITEM_TO_LIST,
  EMPTY_LIST,
  SET_HEADER_ORDER,
  UPDATE_HEADER_ORDER,
  REMOVE_ITEM_FROM_LIST,
  CLEAR_LIST,
  HYDRATE_ORDER,
  SET_CONVEYOR,
} from "../types";

const initialState = {
  items: {},
  header: {
    id: "",
    sequential: "",
    requirementId: "",
    vendorId: "",
    vendorName: "",
    requestDate: "",
    ref: "",
    bankId: "",
    bankName: "",
    bankNum: "",
    subTotal: "0",
    reteFuente: "0",
    reteICA: "0",
    ivaTotal: "0",
    discount: "0",
    totalOrder: "0",
    totalConsignment: "0",
    paymentMethod: "",
    paymentMethodId: "",
    deliveryTime: null,
    aproxDeliveryTime: null,
    offer: "",
    address: "",
    shipping: false,
    shippingLabel: "NO INCLUYE",
    observation: null,
  },
  conveyor: { id: "", costShipping: "0", conveyorName: "", licensePlate: "", driver: "", driverId: "" },
};

const cloneObj = (state) => {
  const clone = { items: {}, header: {}, conveyor: {} };
  Object.assign(clone.items, state.items);
  Object.assign(clone.header, state.header);
  Object.assign(clone.conveyor, state.conveyor);
  return clone;
};

/*toma los datos enviados y asigna los valores a la cabecera dejando el datos de los items vacio  */
const setHeaderOrder = (oldState, data) => {
  const state = cloneObj(oldState);
  const { header, items } = state;
  for (const key in items) {
    delete state.items[key];
  }
  header.id = data.id ? data.id : "";
  header.sequential = data.sequential ? data.orderHeader.sequential : "";
  header.requirementId = data.requirementId ? data.requirementId : null;
  header.vendorId = data.vendorId;
  header.vendorName = data.vendorName;
  header.requestDate = data.requestDate;
  header.ref = data.ref;
  header.bankId = data.bankId;
  header.bankName = data.bankName;
  header.bankNum = data.bankNum;
  header.subTotal = data.subTotal;
  header.reteFuente = data.reteFuente;
  header.ivaTotal = data.ivaTotal;
  header.discount = data.discount;
  header.totalOrder = data.totalOrder;
  header.totalConsignment = data.totalConsignment;
  header.paymentMethod = data.paymentMethod;
  header.paymentMethodId = data.paymentMethodId;
  header.deliveryTime = data.deliveryTime ? data.deliveryTime : null;
  header.aproxDeliveryTime = data.aproxDeliveryTime;
  header.offer = data.offer;
  header.address = data.address;
  header.shipping = data.shipping;
  header.shippingLabel = data.shippingLabel;
  header.observation = data.observation ? data.observation : null;
  return state;
};

/* toma los datos enviados y asigna los valores a la cabecera*/
const updateHeaderOrder = (oldState, data) => {
  const state = cloneObj(oldState);
  const { header } = state;
  header.id = data.id ? data.id : "";
  header.sequential = data.sequential ? (data.sequential ? data.sequential : data.orderHeader.sequential) : "";
  header.requirementId = data.requirementId ? data.requirementId : null;
  header.vendorId = data.vendorId;
  header.vendorName = data.vendorName;
  header.requestDate = data.requestDate;
  header.ref = data.ref;
  header.bankId = data.bankId;
  header.bankName = data.bankName;
  header.bankNum = data.bankNum;
  header.subTotal = data.subTotal;
  header.reteFuente = data.reteFuente;
  header.reteICA = data.reteICA;
  header.ivaTotal = data.ivaTotal;
  header.discount = data.discount;
  header.totalOrder = currency(header.subTotal ? header.subTotal : 0)
    .add(header.ivaTotal ? header.ivaTotal : 0)
    .subtract(header.discount ? header.discount : 0).value;
  header.totalConsignment = currency(header.totalOrder ? header.totalOrder : 0)
    .subtract(header.reteFuente ? header.reteFuente : 0)
    .subtract(header.reteICA ? header.reteICA : 0).value;
  header.paymentMethod = data.paymentMethod;
  header.paymentMethodId = data.paymentMethodId;
  header.deliveryTime = data.deliveryTime ? data.deliveryTime : null;
  header.aproxDeliveryTime = data.aproxDeliveryTime;
  header.offer = data.offer;
  header.address = data.address;
  header.shipping = data.shipping;
  header.shippingLabel = data.shippingLabel;
  header.observation = data.observation ? data.observation : null;
  if (!header.shipping) {
    setConveyor(state, initialState.conveyor);
  }
  return state;
};

/*toma el item y asigna los valores a un item de la lista de items */
const setListItem = (state, item) => {
  // eslint-disable-next-line no-param-reassign
  state.items[item.id] = {};
  const newItem = state.items[item.id];
  newItem.id = item.id;
  newItem.productId = item.productId ? item.productId : null;
  newItem.sku = item.sku;
  newItem.name = item.name;
  newItem.qty = item.qty;
  newItem.costPrice = currency(item.costPrice).value;
  newItem.costTotal = currency(item.costPrice).multiply(newItem.qty).value;
  newItem.ivaCost = item.ivaCost;
  newItem.ivaAmountCost = Number(newItem.ivaCost) > 0 ? newItem.costTotal - newItem.costTotal /( 1 + (Number(newItem.ivaCost) / 100) ) : 0;
  newItem.costPriceWithoutIva = newItem.costTotal - newItem.ivaAmountCost;
  newItem.typeOrder = item.typeOrder ? item.typeOrder : "PRODUCTO";
  newItem.marca = item.marca;
};

/* toma los datos enviados y asigna los valores a la informacion de envio*/
const setConveyor = (oldState, data) => {
  const state = cloneObj(oldState);
  const { conveyor } = state;
  conveyor.id = data.id ? data.id : "";
  conveyor.conveyorName = data.conveyorName ? data.conveyorName : "";
  conveyor.licensePlate = data.licensePlate ? data.licensePlate : "";
  conveyor.driver = data.driver ? data.driver : "";
  conveyor.driverId = data.driverId ? data.driverId : "";
  return state;
};

/*agrega un item nuevo a la lista de productos y aumenta el valor de totalInvoice*/
const addItemToList = (oldState, item) => {
  const state = cloneObj(oldState);
  item.id = item.id ? item.id : uuidv4();
  setListItem(state, item);
  const newItem = state.items[item.id];
  const oldItem = oldState.items[item.id];
  const { header } = state;
  const percentFuente = currency(currency(header.reteFuente).multiply(100).value).divide(header.subTotal).value;
  const percentICA = currency(currency(header.reteICA).multiply(100).value).divide(header.subTotal).value;
  header.subTotal = currency(header.subTotal)
    .add(newItem.costPriceWithoutIva)
    .subtract(oldItem ? oldItem.costPriceWithoutIva : 0).value;
  header.ivaTotal = currency(header.ivaTotal)
    .add(newItem.ivaAmountCost)
    .subtract(oldItem ? oldItem.ivaAmountCost : 0).value;
  header.reteFuente = currency(header.subTotal).multiply(percentFuente).divide(100).value;
  header.reteICA = currency(header.subTotal).multiply(percentICA).divide(100).value;
  header.totalOrder = currency(header.subTotal).add(header.ivaTotal).subtract(header.discount).value;
  header.totalConsignment = currency(header.totalOrder ? header.totalOrder : 0)
    .subtract(header.reteFuente ? header.reteFuente : 0)
    .subtract(header.reteICA ? header.reteICA : 0).value;
  return state;
};

/*elimina un item de la lista de items y resta su valor del totalInvoice */
const removeItemFromList = (oldState, item) => {
  const state = cloneObj(oldState);
  let remove = true;
  if (remove) {
    delete state.items[item.id];
    const { header } = state;
    header.totalInvoice = currency(header.totalInvoice).subtract(item.costPrice * item.qty).value;
    const percentFuente = currency(currency(header.reteFuente).multiply(100).value).divide(header.subTotal).value;
    const percentICA = currency(currency(header.reteICA).multiply(100).value).divide(header.subTotal).value;
    header.subTotal = currency(header.subTotal).subtract(item.costPriceWithoutIva).value;
    header.ivaTotal = currency(header.ivaTotal).subtract(item.ivaAmountCost).value;
    header.reteFuente = currency(header.subTotal).multiply(percentFuente).divide(100).value;
    header.reteICA = currency(header.subTotal).multiply(percentICA).divide(100).value;
    header.totalOrder = currency(header.subTotal ? header.subTotal : 0)
      .add(header.ivaTotal ? header.ivaTotal : 0)
      .subtract(header.discount ? header.discount : 0).value;
    header.totalConsignment = currency(header.totalOrder ? header.totalOrder : 0)
      .subtract(header.reteFuente ? header.reteFuente : 0)
      .subtract(header.reteICA ? header.reteICA : 0).value;
  }
  return state;
};

/* Elimina la lista de los items sin modificar la informacion del header y dejando el totalInvoice en 0 */
const clearList = (oldState) => {
  const state = cloneObj(oldState);
  const { header, items } = state;
  for (const key in items) {
    delete state.items[key];
  }
  header.subTotal = 0;
  header.ivaTotal = 0;
  header.reteFuente = 0;
  header.reteICA = 0;
  header.totalOrder = 0;
  header.totalConsignment = 0;
  return state;
};

/*Toma la data y asignas los valores a la estructura principal tanto header como items */
const hydrateOrder = (state, data) => {
  const items = data.orderDetails
    .map((detail) => ({
      id: detail.id !== "" ? detail.id : uuidv4(),
      productId: detail.productId,
      sku: detail.product ? detail.product.sku : "",
      name: detail.name,
      qty: detail.qty,
      costPrice: currency(detail.costPrice).value,
      costTotal: currency(detail.costPrice).multiply(detail.qty).value,
      ivaCost: detail.ivaPercent ? detail.ivaPercent : 0,
      ivaAmountCost: detail.ivaAmountCost ? currency(detail.ivaAmountCost).value : 0,
      typeOrder: detail.typeOrder ? detail.typeOrder : "PRODUCTO",
      marca:
        detail.product && detail.product.attributes
          ? detail.product.attributes.MARCA
            ? detail.product.attributes.MARCA
            : detail.marca
          : detail.marca,
    }))
    .reduce((a, b) => ({ ...a, ...{ [b.id]: b } }), {});
  const subTotal = data.orderHeader.subTotal
    ? Number(data.orderHeader.subTotal)
    : Object.values(items).reduce((a, b) => a + b.costTotal, 0);
  const reteFuente = data.orderHeader.reteFuente ? Number(data.orderHeader.reteFuente) : 0;
  const reteICA = data.orderHeader.reteICA ? Number(data.orderHeader.reteICA) : 0;
  const ivaTotal = data.orderHeader.ivaTotal
    ? Number(data.orderHeader.ivaTotal)
    : Object.values(items).reduce((a, b) => a + b.ivaAmountCost, 0);
  const discount = data.orderHeader.discount ? Number(data.orderHeader.discount) : 0;
  const totalOrder = data.orderHeader.totalOrder ? Number(data.orderHeader.totalOrder) : subTotal + ivaTotal - discount;
  const totalConsignment = data.orderHeader.totalConsignment
    ? Number(data.orderHeader.totalConsignment)
    : totalOrder - reteFuente - reteICA;
  const header = {
    id: data.orderHeader.id ? null : data.orderHeader.id,
    sequential: data.orderHeader.sequential ? data.orderHeader.sequential : null,
    requirementId: data.orderHeader.requirementId ? data.orderHeader.requirementId : null,
    vendorId: data.orderHeader.vendor.id,
    vendorName: data.orderHeader.vendor.name,
    requestDate: data.orderHeader.requestDate,
    ref: data.orderHeader.ref ? data.orderHeader.ref : "",
    bankId: data.orderHeader.bankId ? data.orderHeader.bankId : "",
    bankName: data.orderHeader.bank
      ? data.orderHeader.bank.name
      : data.orderHeader.bankAcount
      ? data.orderHeader.bankAcount.bank
      : "",
    bankNum: data.orderHeader.bankAcount ? data.orderHeader.bankAcount.num : "",
    subTotal: subTotal,
    reteFuente: reteFuente,
    reteICA: reteICA,
    ivaTotal: ivaTotal,
    discount: discount,
    totalOrder: totalOrder,
    totalConsignment: totalConsignment,
    paymentMethod: data.orderHeader.paymentMethod ? data.orderHeader.paymentMethod : "CONTADO",
    paymentMethodId: data.orderHeader.paymentMethodId ? data.orderHeader.paymentMethodId : "",
    deliveryTime: data.orderHeader.deliveryTime ? data.orderHeader.deliveryTime : null,
    aproxDeliveryTime: data.orderHeader.aproxDeliveryTime ? data.orderHeader.aproxDeliveryTime : null,
    offer: data.orderHeader.offer ? data.orderHeader.offer : "",
    address: data.orderHeader.address,
    shipping: data.orderHeader.shipping ? data.orderHeader.shipping : false,
    shippingLabel: data.orderHeader.shipping ? "INCLUYE" : "NO INCLUYE",
    observation: data.orderHeader.observation ? data.orderHeader.observation : null,
  };
  if (data.orderHeader.conveyor) {
    const conveyor = {
      id: data.orderHeader.conveyor.id ? data.orderHeader.conveyor.id : "",
      conveyorName: data.orderHeader.conveyor.conveyorName,
      licensePlate: data.orderHeader.conveyor.licensePlate,
      driver: data.orderHeader.conveyor.driver,
      driverId: data.orderHeader.conveyor.driverId,
    };
    return { ...state, items, header, conveyor };
  } else {
    return { ...state, items, header };
  }
};

export default function orderReceiving(state = initialState, action = {}) {
  switch (action.type) {
    case ADD_ITEM_TO_LIST:
      return addItemToList(state, action.data);

    case EMPTY_LIST:
      return initialState;

    case SET_HEADER_ORDER:
      return setHeaderOrder(state, action.data);

    case SET_CONVEYOR:
      return setConveyor(state, action.data);

    case UPDATE_HEADER_ORDER:
      return updateHeaderOrder(state, action.data);

    case REMOVE_ITEM_FROM_LIST:
      return removeItemFromList(state, action.data);

    case CLEAR_LIST:
      return clearList(state);

    case HYDRATE_ORDER:
      return hydrateOrder(state, action.data);

    default:
      return state;
  }
}
