import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import PosSection from "./posSection/PosSection";
import SaleDetails from "./detailsSection";
import { Grid } from "@material-ui/core";
import CircularLoader from "../controls/loader/CircularLoader";
import { LoadingContext } from "./LoadingContext";

class Sale extends Component {
  constructor(props) {
    super(props);
    this.changeIsLoading = val => {
      this.setState({ isLoading: val });
    };
    this.state = {
      isLoading: false,
      changeIsLoading: this.changeIsLoading
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <CircularLoader isLoading={this.state.isLoading} />
        <LoadingContext.Provider value={this.state}>
          <Grid container className={classes.container} alignContent="center">
            <Grid item xs={12} md={6} className={classes.item}>
              <PosSection />
            </Grid>
            <Grid item xs={12} md={6} className={classes.item}>
              <SaleDetails />
            </Grid>
          </Grid>
        </LoadingContext.Provider>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Sale);
