import axios from "axios";
import "./axiosClient";

const getTransactionId = () => axios.get("/api/sales/initTransaction");

const saveNormalSale = (sale, transactionId) => axios.post(`/api/sales/${transactionId}/normalCheckout`, sale);

const saveQuote = (sale, transactionId) => axios.post(`/api/sales/${transactionId}/quote`, sale);

const saveCreditSale = (sale, transactionId) => axios.post(`/api/sales/${transactionId}/CreditCheckout`, sale);

const savePaidCredit = (sale, transactionId) => axios.post(`/api/sales/${transactionId}/PaidCredit`, sale);

const saveQuotedNormalSale = (sale, transactionId) => axios.post(`/api/sales/${transactionId}/quoteNormalSale`, sale);

const saveQuoteCreditSale = (sale, transactionId) => axios.post(`/api/sales/${transactionId}/quoteCreditSale`, sale);

const saveAnnulledNormalSale = (sale, transactionId) =>
  axios.post(`/api/sales/${transactionId}/annulledNormalSale`, sale);

const saveAnnulledCreditSale = (sale, transactionId) =>
  axios.post(`/api/sales/${transactionId}/annulledCreditSale`, sale);

const getCreditDetailsByCreditId = transactionId => axios.get(`/api/credit/details/${transactionId}`);

const getInvoice = (transactionId, type) =>
  axios.get(`api/invoices/${transactionId}/${type}`, { responseType: "blob" });

const getRequirements = requirementsId =>
  axios.get(`api/requirement/${requirementsId}/report`, { responseType: "blob" });

const getOrders = ordersId => axios.get(`api/orders/${ordersId}/report`, { responseType: "blob" });

const getInvoiceupdate = (transactionId, customerId) => axios.put(`/api/sales/${transactionId}/${customerId}`);

const updateCanceledTransaction = (transactionId, data) => axios.put(`/api/invoices/${transactionId}/annul`, data);

const closeBox = boxId => axios.put(`/api/balanceBox/${boxId}/close`);

const balanceDataReport = balanceId => axios.get(`/api/balanceBox/${balanceId}/dataReport`, { responseType: "blob" });

const InvoiceUpdateState = (transactionId, state) => axios.put(`/api/sales/${transactionId}/${state}`);

const getStockRemissions = (params, per_page = 10, page = 1, cancelToken) =>
  axios.get(`/api/remission`, { params: { ...params, per_page, page }, cancelToken });

const getRemissionsByTransaction = (transactionId, per_page = 10, page = 1) =>
  axios.get(`/api/remission/transaction/${transactionId}`, { params: { per_page, page } });

const searchRemissionByTransactionIdAndGetByPages = (transactionId, id) =>
  axios.get(`/api/remission/transaction/${transactionId}`, {
    params: { q: id, per_page: "10", page: "1" }
  });
const filterReport = params =>
  axios.post(`/api/invoices/generalReport`, null, {
    responseType: "blob",
    params: { ...params }
  });

const getVendorByRequirement = id => axios.get(`/api/requirement/${id}/vendor`);

const getTypeParameter = type => axios.get(`/api/parameters/${type}/items`);

const getRemissionReport = (id, type) => axios.get(`/api/remission/${id}/${type}`, { responseType: "blob" });

const createRemission = (transactionId, data) => axios.post(`/api/remission/transaction/${transactionId}`, data);

const createRemissionFromStock = data => axios.post(`/api/remission`, data);

const updateRemission = (remissionId, data) => axios.put(`/api/remission/${remissionId}`, data);

const changeRemission = transactionId => axios.put(`/api/remission/changeRemission/${transactionId}`);

const deleteRemission = remissionId => axios.delete(`/api/remission/${remissionId}`);

const deleteExpense = (expenseId, data) => axios.delete(`/api/expenses/${expenseId}`, { data });

const updateLimitStock = (stockId, limit) => axios.put(`/api/stocks/limit/${stockId}`, limit);

const exportAllStock = () => axios.get(`/api/stocks/export/all`, { responseType: "blob" });

const createEcommerceInvoice = (sale, transactionId) =>
  axios.post(`/api/sales/${transactionId}/ecommerceNormalSale`, sale);

const changeState = (id, description) => axios.post(`/api/products/${id}/changeState`, { description });

const updateState = (id, description) => axios.post(`/api/products/${id}/updateState`, { description });

export default {
  getRemissionReport,
  getStockRemissions,
  getRemissionsByTransaction,
  searchRemissionByTransactionIdAndGetByPages,
  createRemissionFromStock,
  createRemission,
  updateRemission,
  deleteRemission,
  getTransactionId,
  saveNormalSale,
  saveCreditSale,
  getInvoice,
  getRequirements,
  getOrders,
  getVendorByRequirement,
  saveQuote,
  getInvoiceupdate,
  savePaidCredit,
  saveQuotedNormalSale,
  saveQuoteCreditSale,
  saveAnnulledNormalSale,
  saveAnnulledCreditSale,
  getCreditDetailsByCreditId,
  closeBox,
  InvoiceUpdateState,
  balanceDataReport,
  updateCanceledTransaction,
  filterReport,
  createEcommerceInvoice,
  changeRemission,
  updateLimitStock,
  deleteExpense,
  exportAllStock,
  changeState,
  getTypeParameter,
  updateState
};
