import React, { Component } from "react";
import { COP as currency } from "../../../../utils";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import FullPageDialog from "../../../controls/dialog/FullPageDialog";
import api from "../../../../api";
import Message from "../../../controls/Message";
import { getCartItemsArraySelector } from "../../../../selectors";
import CreditSaleForm from "./CreditSaleForm";
import CircularLoader from "../../../controls/loader/CircularLoader";
import { initTransaction, cancelTransaction, editAnnulledTransaction } from "../../../../actions/transaction";
import { emptyCart } from "../../../../actions/cart";
import YesNo from "../../../controls/dialog/YesNo";
import print from "print-js";
import { saveAs } from "file-saver";
import * as moment from "moment";
import { LoadingContext } from "../../LoadingContext";

const styles = () => ({
  message: { margin: 0, paddingBottom: "10px" },
  formContainer: { padding: "30px", margin: "auto", width: "500px", height: "100%" }
});

class CreditSale extends Component {
  state = {
    error: "",
    showMessage: false,
    showConfirmDeleteDialog: false,
    isLoading: false,
    errors: {},
    data: { balanceToPay: "0.00", amountPaid: "", creditDate: null }
  };

  async componentDidMount() {
    try {
      if (this.props.transaction.id) {
        if (
          this.props.transaction.status === "Anulada" &&
          (!this.props.transaction.idOld || this.props.transaction.idOld === this.props.transaction.id)
        ) {
          const oldTransaction = this.props.transaction.id;
          const oldTransactionState = this.props.transaction.status;
          const res = await api.transaction.getTransactionId();
          const newTransaction = res.data;
          const dataEdit = { id: newTransaction, status: oldTransactionState, idOld: oldTransaction };
          this.props.editAnnulledTransaction(dataEdit);
        }
        return;
      }
      this.setState({ isLoading: true });
      const res = await api.transaction.getTransactionId();
      const transId = res.data;
      this.props.initTransaction(transId);
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ error: error.message, showMessage: true, isLoading: false });
    }
  }

  onChange = e => {
    if (e.target.name === "creditDate") {
      const creditDate = e.target.value;
      this.setState({ errors: {}, data: { ...this.state.data, creditDate: creditDate } });
    } else {
      const amountPaid = e.target.value;
      const { totalPay } = this.props.cart.summary;
      const balance = currency(totalPay).subtract(amountPaid).value;
      if (Number(balance) <= 0) {
        alert("ESTA ES UNA VENTA DE CONTADO, POR FAVOR CIERRE LA VENTANA Y ELIJA DICHA OPCION.");
        return;
      } else {
        this.setState({ errors: {}, data: { ...this.state.data, amountPaid, balanceToPay: balance.toString() } });
      }
    }
  };

  onCreditSaleFormSubmit = setIsLoading => async e => {
    e.preventDefault();
    const { amountPaid, creditDate } = this.state.data;
    const { cart, cartArray } = this.props;
    const { summary, boxId, options, taxes, serials } = cart;
    const { netTotal, totalPay } = summary;
    if (
      summary.customer.creditType.toString() === "Limitado" &&
      Number(netTotal) > Number(summary.customer.availableAmount)
    ) {
      alert("EL MONTO DEL CREDITO SUPERA EL LIMITE PERMITIDO, MODIFIQUE EL CLIENTE PARA SEGUIR CON EL CREDITO.");
      return;
    }
    if (!boxId || !boxId.value) {
      this.setState({
        errors: { boxId: "Seleccione una caja para la venta. Si no hay ninguna, recuerde abrir la caja." }
      });
      return;
    }
    const sale = {};
    sale.items = [];
    sale.serials = [];
    sale.transactionTax = [];
    sale.transaction = {};
    sale.creditSale = {};
    sale.creditDetails = {};
    Object.assign(sale.items, cartArray);
    sale.items = sale.items.map(item => ({
      ...item,
      id: Number(this.props.transaction.id),
      productId: item.id,
      price: currency(item.price).divide(1 + item.iva * 0.01).value
    }));
    sale.serials = Object.values(serials).map(serial => serial.serialId);
    sale.transaction.total = currency(summary.total).value;
    sale.transaction.taxAmount = summary.taxAmount;
    sale.transaction.totalDiscount = currency(summary.discountOnItems).add(summary.discountOnTotal).value;
    sale.transaction.netTotal = currency(netTotal).value;
    sale.transaction.totalPay = currency(totalPay).value;
    sale.transaction.tax = currency(summary.tax, { precision: 3 }).value;
    const taxPercentageString = currency(summary.tax, { precision: 3 }).value + "%";
    sale.transaction.taxPercentageString = taxPercentageString.toString();
    sale.transaction.customerId = summary.customerId;
    sale.transaction.amountPaid = currency(summary.amountPaid).value;
    sale.transaction.saleType = currency(summary.saleType).value;
    sale.transaction.totalDetailsIva = currency(summary.totalDetailsIva).value;
    sale.transaction.comments = summary.comments;
    sale.transaction.transactionId = Number(this.props.transaction.id);
    sale.creditSale.id = Number(this.props.transaction.id);
    sale.creditSale.customerId = summary.customerId;
    sale.creditSale.creditAmout = currency(totalPay).value;
    sale.creditSale.paidAmount = currency(amountPaid).value;
    if (creditDate) {
      sale.creditSale.limitDate = moment(creditDate).format("YYYY-MM-DD HH:mm:ss");
    }
    sale.creditDetails.creditId = Number(this.props.transaction.id);
    sale.creditDetails.paidAmount = currency(amountPaid).value;
    if (boxId) {
      sale.transaction.boxId = Number(boxId.value);
    }
    sale.transaction.options = options;
    const transactionId = Number(this.props.transaction.id);
    setIsLoading(true);
    try {
      let apiCall = "";
      switch (this.props.transaction.status) {
        case "Anulada":
          sale.OldTransaction = this.props.transaction.idOld;
          sale.transaction.transactionId = Number(this.props.transaction.id);
          apiCall = api.transaction.saveAnnulledCreditSale;
          break;
        case "Cotizada":
          apiCall = api.transaction.saveQuoteCreditSale;
          break;
        default:
          apiCall = api.transaction.saveCreditSale;
          break;
      }
      taxes.forEach(tax => {
        const obj = {
          transactionId: sale.transaction.transactionId,
          taxesId: tax.id,
          amount: tax.action === "-" ? tax.amount * -1 : tax.amount,
          percent: tax.percent
        };
        sale.transactionTax.push(obj);
      });
      await apiCall(sale, transactionId);
      this.props.handleClose();
      this.props.emptyCart();
      this.props.cancelTransaction();
    } catch (err) {
      console.error(err);
      alert("NO SE PUDO CONCRETAR LA VENTA.");
      setIsLoading(false);
      return;
    }
    try {
      const res = await api.transaction.getInvoice(transactionId, "recibo");
      saveAs(res.data, "Recibo_Transaccion_" + transactionId + ".pdf");
      print(URL.createObjectURL(res.data));
    } catch (err) {
      console.error(err);
      alert("LA VENTA O COTIZACIÓN FUE CONCRETADA PERO SUCEDIÓ UN ERROR AL GENERAR EL RECIBO");
    } finally {
      setIsLoading(false);
    }
  };

  onCreditSaleFormCancel = () => {
    this.setState({ showConfirmDeleteDialog: true });
  };

  onMessageCloseClick = () => {
    this.setState({ showMessage: false });
  };
  // Clear the transaction. If user clicks confirm yes for canceling transaction.
  onYesNoPopYesClick = () => {
    this.props.cancelTransaction();
    this.props.emptyCart();
  };

  onYesNoPopNoClick = () => {
    this.setState({ showConfirmDeleteDialog: false });
  };

  renderForm = () => {
    const { transaction, cart } = this.props;
    const { isLoading, errors, data } = this.state;
    if (isLoading === true) {
      return null;
    }

    return (
      <LoadingContext.Consumer>
        {({ isLoading, changeIsLoading }) => (
          <CreditSaleForm
            cart={cart}
            errors={errors}
            data={data}
            onSubmit={this.onCreditSaleFormSubmit(changeIsLoading)}
            onCancel={this.onCreditSaleFormCancel}
            onChange={this.onChange}
            transactionId={transaction.id}
          />
        )}
      </LoadingContext.Consumer>
    );
  };

  render() {
    const { handleClose, open, classes } = this.props;
    const { error, showMessage, isLoading, showConfirmDeleteDialog } = this.state;

    return (
      <FullPageDialog open={open} handleClose={handleClose} title="Venta a Credito">
        <CircularLoader isLoading={isLoading} />
        <YesNo
          open={showConfirmDeleteDialog}
          message="¿Esta seguro de eliminar la venta y limpiar el carrito?"
          onOk={() => this.onYesNoPopYesClick()}
          onCancel={() => this.onYesNoPopNoClick()}
        />
        <div className={classes.formContainer}>
          <Message
            className={classes.message}
            title="Exite un error"
            message={error}
            show={showMessage}
            isError={true}
            onCloseClick={this.onMessageCloseClick}
          />
          {this.renderForm()}
        </div>
      </FullPageDialog>
    );
  }
}

function mapStateToProps(state) {
  return { transaction: state.transaction, cart: state.cart, cartArray: getCartItemsArraySelector(state) };
}

const mapDispatchToProps = {
  initTransaction,
  cancelTransaction,
  editAnnulledTransaction,
  emptyCart
};

const component = withStyles(styles)(CreditSale);
export default connect(mapStateToProps, mapDispatchToProps)(component);
