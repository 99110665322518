import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { COP as currency } from "../../utils";
import moment from "moment";
import "moment/locale/es";
import CircularLoader from "../controls/loader/CircularLoader";
import api from "../../api";

const toDataset = dataset => {
  const labels = dataset.map(row =>
    moment(row.date, "YYYY-MM-DD")
      .locale("es")
      .format("MMMM DD")
  );
  const sales = dataset.map(row => row.data);
  const average = sales.reduce((a, b) => a + b, 0) / sales.length;
  const averages = Array(sales.length).fill(average);
  return {
    labels,
    datasets: [
      {
        label: "Valor diario de venta",
        backgroundColor: "rgb(54, 162, 235)",
        borderColor: "rgb(54, 162, 235)",
        fill: false,
        data: sales
      },
      {
        label: "Promedio",
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgb(255, 99, 132)",
        fill: false,
        data: averages
      }
    ]
  };
};

export default function DailySales() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const getStats = async () => {
    try {
      const data = await api.statistics.getDailySales();
      setData(toDataset(data.data));
    } catch (err) {
      console.log(err);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getStats();
  }, []);
  const options = {
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          let label = data.datasets[tooltipItem.datasetIndex].label || "";
          if (label) {
            label += ": ";
          }
          label += currency(tooltipItem.yLabel, {
            formatWithSymbol: true
          }).format();
          return label;
        }
      }
    },
    scales: {
      yAxes: [
        {
          ticks: {
            callback: function(value) {
              return "$" + currency(value).format();
            }
          }
        }
      ]
    }
  };
  return (
    <React.Fragment>
      {isLoading ? <CircularLoader isLoading={isLoading} /> : null}
      {data ? <Line data={data} options={options} /> : null}
      {error ? <div>No se pudo cargar la estadistica</div> : null}
    </React.Fragment>
  );
}
