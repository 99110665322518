import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";

const YesNo = props => (
  <Dialog open={props.open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
    <DialogTitle id="alert-dialog-title">{props.title ? props.title : "Mensaje"}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">{props.message}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.onCancel} color="primary" autoFocus>
        Cancelar
      </Button>
      <Button onClick={props.onOk} color="secondary" autoFocus>
        Aceptar
      </Button>
    </DialogActions>
  </Dialog>
);

export default YesNo;
