import React, { lazy, Suspense } from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router";
import Sale from "../sale";
import NotFound from "../notFound/NotFound";
import Statistics from "../statistics/Statistics";
import CircularLoader from "../controls/loader/CircularLoader";

const NewCustomer = lazy(() => import("../customers/AddNew"));
const Customers = lazy(() => import("../customers/Customers"));
const CustomerDetail = lazy(() => import("../customers/DetailsCustomer"));
const Vendors = lazy(() => import("../vendor/Vendors"));
const AddNewReceivings = lazy(() => import("../vendor/Receiving/AddNewReceivings"));
const AddNewVendor = lazy(() => import("../vendor/AddNewVendors"));
const AddNewOrders = lazy(() => import("../vendor/OrderReceiving/AddNewOrders"));
const VendorDetail = lazy(() => import("../vendor/DetailsVendors"));
const AddNewRequirements = lazy(() => import("../vendor/Requirement/AddNewRequirements"));
const RequirementsDetail = lazy(() => import("../vendor/Requirement/DetailsRequirements"));
const Stock = lazy(() => import("../stock/Stock"));
const Expense = lazy(() => import("../expense/Expense"));
const AddNewExpense = lazy(() => import("../expense/AddNewExpense"));
const Products = lazy(() => import("../products/Products"));
const AddNewProduct = lazy(() => import("../products/AddNewProduct"));
const ProductDetail = lazy(() => import("../products/DetailsProduct"));
const AddNewProductType = lazy(() => import("../products/AddNewProductType"));
const AddNewShelves = lazy(() => import("../products/AddNewShelves"));
const AddNewSection = lazy(() => import("../products/AddNewSection"));
const Users = lazy(() => import("../users/Users"));
const AddNewUser = lazy(() => import("../users/AddNewUser"));
const Box = lazy(() => import("../box/Box"));
const AddNewCashRegister = lazy(() => import("../box/AddNewCashRegister"));
const OpenCloseBox = lazy(() => import("../box/OpenCloseBox"));
const DetailsBalanceBox = lazy(() => import("../box/DetailsBalanceBox"));
const Invoice = lazy(() => import("../invoice/Invoice"));
const Remissions = lazy(() => import("../remissions/Remissions"));
const AddNewRemission = lazy(() => import("../remissions/AddNew"));
const Profile = lazy(() => import("../profile/Profile"));
const AddNewTax = lazy(() => import("../taxes/AddNewTax"));
const AddNewResolution = lazy(() => import("../profile/AddNewResolution"));
const Budgets = lazy(() => import("../budget/Budgets"));
const AddNewBudget = lazy(() => import("../budget/AddNewBudget"));
const AddNewAPU = lazy(() => import("../budget/apu/AddNewAPU"));
const Maintenance = lazy(() => import("../maintenance/Maintenance"));
const addNewMaintenance = lazy(() => import("../maintenance/addNewMaintenance"));
const Embroidery = lazy(() => import("../embroidery/Embroidery"));
const NewEmbroidery = lazy(() => import("../embroidery/NewEmbroidery"));
const AddNewLogo = lazy(() => import("../embroidery/AddNewLogo"));
const EmbroideryDetails = lazy(() => import("../embroidery/EmbroideryDetails"));
const LogoDetails = lazy(() => import("../embroidery/LogoDetails"));

const Routes = () => (
  <Suspense fallback={<CircularLoader isLoading={true} />}>
    <Switch style={{ overflow: "auto" }}>
      <Route exact path="/" component={() => <div />} />

      {/* Sale */}
      <Route exact path="/sale" component={() => <Sale />} />

      {/* Receivings */}
      <ProtectedRoute roles={["admin", "storer"]} exact path="/receivings" component={Vendors} />
      <ProtectedRoute roles={["admin", "storer"]} exact path="/receivings/:id/new" component={AddNewReceivings} />
      <ProtectedRoute roles={["admin", "storer"]} exact path="/receivings/:id/edit" component={AddNewReceivings} />
      <ProtectedRoute roles={["admin", "storer"]} exact path="/receivings/:id/details" component={AddNewReceivings} />

      {/* Requirements */}
      <Route exact path="/requirements" component={Vendors} />
      <Route exact path="/requirements/new" component={AddNewRequirements} />
      <Route exact path="/requirements/:id/edit" component={AddNewRequirements} />
      <Route exact path="/requirements/:id/details" component={RequirementsDetail} />

      {/*orderReceiving*/}
      <ProtectedRoute roles={["admin", "storer"]} exact path="/orderReceivings" component={Vendors} />
      <ProtectedRoute roles={["admin", "storer"]} exact path="/orderReceivings/:vendor/new" component={AddNewOrders} />
      <ProtectedRoute roles={["admin", "storer"]} exact path="/orderReceivings/:id/edit" component={AddNewOrders} />
      <ProtectedRoute roles={["admin", "storer"]} exact path="/orderReceivings/:id/details" component={AddNewOrders} />
      <ProtectedRoute
        roles={["admin", "storer"]}
        exact
        path="/orderReceivings/:requirementId/:vendor/order"
        component={AddNewOrders}
      />

      {/* stock */}
      <ProtectedRoute roles={["admin", "storer"]} exact path="/stock" component={Stock} />

      {/* Vendor */}
      <ProtectedRoute roles={["admin", "storer"]} exact path="/vendors" component={Vendors} />
      <ProtectedRoute roles={["admin", "storer"]} exact path="/vendors/new" component={AddNewVendor} />
      <ProtectedRoute roles={["admin", "storer"]} exact path="/vendors/edit/:id" component={AddNewVendor} />
      <ProtectedRoute roles={["admin", "storer"]} exact path="/vendors/details/:id" component={VendorDetail} />

      {/* Customer */}
      <Route exact path="/customers" component={Customers} />
      <Route exact path="/customers/new" component={NewCustomer} />
      <Route exact path="/customers/edit/:id" component={NewCustomer} />
      <Route exact path="/customers/details/:id" component={CustomerDetail} />

      {/* Expense */}
      <Route exact path="/expense" component={Expense} />
      <Route exact path="/expense/new" component={AddNewExpense} />
      <Route exact path="/expense/edit/:id" component={AddNewExpense} />

      {/* Product */}
      <Route exact path="/products" component={Products} />
      <Route exact path="/products/new" component={AddNewProduct} />
      <ProtectedRoute roles={["admin", "storer"]} exact path="/products/edit/:id" component={AddNewProduct} />
      <Route exact path="/products/details/:id" component={ProductDetail} />

      {/* Product Type */}
      <Route exact path="/producttypes" component={Products} />
      <ProtectedRoute roles={["admin", "storer"]} exact path="/producttypes/new" component={AddNewProductType} />
      <ProtectedRoute roles={["admin", "storer"]} exact path="/producttypes/edit/:id" component={AddNewProductType} />

      {/* Shelves */}
      <Route exact path="/shelves" component={Products} />
      <ProtectedRoute roles={["admin", "storer"]} exact path="/shelves/new" component={AddNewShelves} />
      <ProtectedRoute roles={["admin", "storer"]} exact path="/shelves/edit/:id" component={AddNewShelves} />

      {/* Section */}
      <Route exact path="/section" component={Products} />
      <ProtectedRoute roles={["admin", "storer"]} exact path="/section/new" component={AddNewSection} />
      <ProtectedRoute roles={["admin", "storer"]} exact path="/section/edit/:id" component={AddNewSection} />

      {/* users */}
      <ProtectedRoute roles={["admin"]} exact path="/users" component={Users} />
      <ProtectedRoute roles={["admin"]} exact path="/users/new" component={AddNewUser} />
      <ProtectedRoute roles={["admin"]} exact path="/users/edit/:id" component={AddNewUser} />

      {/* maintenance */}
      <ProtectedRoute roles={["admin", "seller"]} exact path="/maintenance" component={Maintenance} />
      <ProtectedRoute roles={["admin", "seller"]} exact path="/maintenance/new" component={addNewMaintenance} />
      <ProtectedRoute roles={["admin", "seller"]} exact path="/maintenance/edit/:id" component={addNewMaintenance} />

      {/* Roles */}
      <ProtectedRoute roles={["admin"]} exact path="/roles" component={Users} />

      <Route exact path="/home" component={Statistics} />

      {/* Box */}
      <Route exact path="/cashRegister" component={Box} />
      <Route exact path="/cashRegister/open" component={OpenCloseBox} />
      <ProtectedRoute roles={["admin"]} exact path="/cashRegister/new" component={AddNewCashRegister} />
      <ProtectedRoute roles={["admin"]} exact path="/cashRegister/edit/:id" component={AddNewCashRegister} />
      <Route exact path="/balanceBox" component={Box} />
      <Route exact path="/balanceBox/details/:id" component={DetailsBalanceBox} />

      {/* Invoice */}
      <Route exact path="/invoice" component={Invoice} />
      <Route exact path="/stock/remissions" component={Stock} />
      <Route exact path="/stock/remissions/new" component={AddNewRemission} />
      <Route exact path="/remissions/:id" component={Remissions} />
      <Route exact path="/remissions/:id/new" component={AddNewRemission} />

      {/* Taxes */}
      <ProtectedRoute roles={["admin"]} exact path="/taxes" component={Profile} />
      <Route exact path="/taxes/new" component={AddNewTax} />
      <ProtectedRoute roles={["admin"]} exact path="/taxes/edit/:id" component={AddNewTax} />

      {/** Mi perfil */}
      <Route exact path="/my-profile" component={Profile} />
      <ProtectedRoute roles={["admin"]} exact path="/my-company" component={Profile} />
      <Route exact path="/resolution" component={Profile} />
      <ProtectedRoute roles={["admin"]} exact path="/resolution/new" component={AddNewResolution} />
      <ProtectedRoute roles={["admin"]} exact path="/resolution/edit/:id" component={AddNewResolution} />

      {/**SOLO APU  */}
      <ProtectedRoute
        exact
        path="/budget/apu"
        forCompanies={["900303701-10", "900303701-0", "900303701", "900199755-1", "900688231-2", "24243669-7"]}
        component={Budgets}
      />
      <ProtectedRoute
        exact
        path="/budget/apu/new"
        forCompanies={["900303701-10", "900303701-0", "900303701", "900199755-1", "900688231-2", "24243669-7"]}
        component={AddNewAPU}
      />
      <ProtectedRoute
        exact
        path="/budget/apu/edit/:id"
        forCompanies={["900303701-10", "900303701-0", "900303701", "900199755-1", "900688231-2", "24243669-7"]}
        component={AddNewAPU}
      />

      {/*Solo presupuesto */}
      <ProtectedRoute
        exact
        path="/budget"
        forCompanies={["900303701-10", "900303701-0", "900303701", "900199755-1", "900688231-2", "24243669-7"]}
        component={Budgets}
      />
      <ProtectedRoute
        exact
        path="/budget/new"
        forCompanies={["900303701-10", "900303701-0", "900303701", "900199755-1", "900688231-2", "24243669-7"]}
        component={AddNewBudget}
      />
      <ProtectedRoute
        exact
        path="/budget/edit/:id"
        forCompanies={["900303701-10", "900303701-0", "900303701", "900199755-1", "900688231-2", "24243669-7"]}
        component={AddNewBudget}
      />

      {/**logos bordados  */}
      <ProtectedRoute
        exact
        path="/embroidery/logo"
        forCompanies={["900303701-10", "900303701-0", "900303701", "900199755-1", "900688231-2", "24243669-7"]}
        component={Embroidery}
      />
      <ProtectedRoute
        exact
        path="/embroidery/logo/new"
        forCompanies={["900303701-10", "900303701-0", "900303701", "900199755-1", "900688231-2", "24243669-7"]}
        component={AddNewLogo}
      />
      <ProtectedRoute
        exact
        path="/embroidery/logo/edit/:id"
        forCompanies={["900303701-10", "900303701-0", "900303701", "900199755-1", "900688231-2", "24243669-7"]}
        component={AddNewLogo}
      />

      <ProtectedRoute
        exact
        path="/embroidery/logo/details/:id"
        forCompanies={["900303701-10", "900303701-0", "900303701", "900199755-1", "900688231-2", "24243669-7"]}
        component={LogoDetails}
      />

      {/* bordados */}
      <ProtectedRoute
        exact
        path="/embroidery"
        forCompanies={["900303701-10", "900303701-0", "900303701", "900199755-1", "900688231-2", "24243669-7"]}
        component={Embroidery}
      />

      <ProtectedRoute
        exact
        path="/embroidery/new"
        forCompanies={["900303701-10", "900303701-0", "900303701", "900199755-1", "900688231-2", "24243669-7"]}
        component={NewEmbroidery}
      />
      <ProtectedRoute
        exact
        path="/embroidery/edit/:id"
        forCompanies={["900303701-10", "900303701-0", "900303701", "900199755-1", "900688231-2", "24243669-7"]}
        component={NewEmbroidery}
      />

      <ProtectedRoute
        exact
        path="/embroidery/details/:id"
        forCompanies={["900303701-10", "900303701-0", "900303701", "900199755-1", "900688231-2", "24243669-7"]}
        component={EmbroideryDetails}
      />

      {/* Catch : Not found */}
      <Route path="/" component={NotFound} />
    </Switch>
  </Suspense>
);

function PrivateRoute({ component: Component, user, roles, forCompanies, ...rest }) {
  if (forCompanies) {
    return (
      <Route
        {...rest}
        render={(props) =>
          forCompanies.includes(user.nit) && (!roles || roles.includes(user.role)) ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          )
        }
      />
    );
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        roles.includes(user.role) ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
}

const ProtectedRoute = connect(mapStateToProps, null)(PrivateRoute);

function mapStateToProps(state) {
  return { user: state.auth.user };
}

export default Routes;
