import React from "react";
import { Box, Typography, ButtonGroup, Button, Grid, InputAdornment } from "@material-ui/core";
import NumberTextField from "../../controls/textfields/NumberTextField";
import { COP as currency } from "../../../utils";
import CustomTextField from "../../controls/textfields/CustomTextField";

const KmForm = props => {
  const { name, weight, distance, kmPrice, errors, totalPrice, onChange } = props;
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <CustomTextField
          label="Nombre del Item"
          id="name"
          name="name"
          maxLength={errors.name ? null : 191}
          error={!!errors.name}
          helperText={errors.name ? errors.name : null}
          required
          fillWidth
          value={name}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={6}>
        <NumberTextField
          fillWidth
          label="Peso Total"
          id="weight"
          name="weight"
          value={weight}
          onChange={onChange}
          required
          error={!!errors.weight}
          helperText={errors.weight ? errors.weight : null}
          InputProps={{
            endAdornment: <InputAdornment position="end">Kg</InputAdornment>
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <NumberTextField
          fillWidth
          label="Distancia"
          required
          id="distance"
          name="distance"
          value={distance}
          error={!!errors.distance}
          helperText={errors.distance ? errors.distance : null}
          onChange={onChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">Km</InputAdornment>
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <NumberTextField
          fillWidth
          label="Precio por Kilogramo por Kilometro"
          id="kmPrice"
          name="kmPrice"
          value={kmPrice}
          error={!!errors.kmPrice}
          helperText={errors.kmPrice ? errors.kmPrice : null}
          onChange={onChange}
          required
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>
          }}
        />
      </Grid>
      <Grid container item xs={6} alignContent="flex-end" justifyContent="flex-end">
        <Typography align="right" variant="h6">
          <strong>Total</strong> {currency(totalPrice).format(true)}
        </Typography>
      </Grid>
    </Grid>
  );
};

const PercentForm = props => {
  const { name, price, percentOfPrice, errors, totalPrice, onChange } = props;
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <CustomTextField
          label="Nombre del Item"
          id="name"
          name="name"
          maxLength={errors.name ? null : 191}
          error={!!errors.name}
          helperText={errors.name ? errors.name : null}
          required
          fillWidth
          value={name}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={6}>
        <NumberTextField
          fillWidth
          label="Precio Base"
          id="price"
          name="price"
          value={price}
          onChange={onChange}
          required
          error={!!errors.price}
          helperText={errors.price ? errors.price : null}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <NumberTextField
          fillWidth
          label="Porcentaje"
          required
          id="percentOfPrice"
          name="percentOfPrice"
          value={percentOfPrice}
          error={!!errors.percentOfPrice}
          helperText={errors.percentOfPrice ? errors.percentOfPrice : null}
          onChange={onChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>
          }}
        />
      </Grid>
      <Grid item xs={6}></Grid>
      <Grid container item xs={6} alignContent="flex-end" justifyContent="flex-end">
        <Typography align="right" variant="h6">
          <strong>Total</strong> {currency(totalPrice).format(true)}
        </Typography>
      </Grid>
    </Grid>
  );
};

function TransportItemForm(props) {
  const { onSubmit, mode, setMode, errors, state, total, onChange } = props;
  return (
    <form id="transport-item" onSubmit={onSubmit}>
      <Box justifyContent="space-between" display="flex" marginBottom={2}>
        <Typography variant="h5" style={{ marginRight: "2rem" }}>
          Registrar Transporte
        </Typography>
        <ButtonGroup aria-label="Selecciona el modo del item">
          <Button
            size="small"
            aria-label="Kilogramo por Kilometro recorrido"
            color={mode === "km" ? "secondary" : null}
            onClick={() => setMode("km")}
          >
            Kg/Km
          </Button>
          <Button
            size="small"
            aria-label="Porcentaje del Total"
            color={mode === "percent" ? "secondary" : null}
            onClick={() => setMode("percent")}
          >
            % Total
          </Button>
        </ButtonGroup>
      </Box>
      {mode === "km" && <KmForm {...state} errors={errors} totalPrice={total} onChange={onChange} />}
      {mode === "percent" && <PercentForm {...state} errors={errors} totalPrice={total} onChange={onChange} />}
    </form>
  );
}

TransportItemForm.propTypes = {};

export default TransportItemForm;
