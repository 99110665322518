import axios from "axios";
import "./axiosClient";

const getDailySales = () => axios.get("/api/statistics/dailySale");

const getDailySalesByItem = productId => axios.get(`/api/statistics/dailySaleByItem?productId=${productId}`);

const getDailySalesByBox = boxId => axios.get(`/api/statistics/dailySaleByBox?boxId=${boxId}`);

const getTopProducts = () => axios.get("/api/statistics/topItems");

const getTopSellers = () => axios.get("/api/statistics/topSellers");

const getMontlyExpenses = year => axios.get(`/api/statistics/monthlyExpenses?year=${year}`);

const getEarningsVsExpenses = () => axios.get("/api/statistics/earnings");

export default {
  getDailySales,
  getDailySalesByItem,
  getDailySalesByBox,
  getTopProducts,
  getTopSellers,
  getEarningsVsExpenses,
  getMontlyExpenses
};
