import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { TextField, Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import ErrorMessage from "../controls/messages/ErrorMessage";
import { loginUser } from "../../actions/auth";
import CardMedia from "@material-ui/core/CardMedia";
import styles from "./styles";

class LoginPage extends Component {
  state = {
    data: {
      username: "",
      password: "",
      nit: "",
    },
    loading: false,
    errors: {},
  };

  componentDidMount() {
    if (this.props.isLoggedIn) {
      this.props.history.push("/home");
    } else {
      this.props.history.push("/");
    }
  }

  hasErrors = () => this.state.errors.global && this.state.errors.global.length > 0;

  onSubmit = async (e) => {
    e.preventDefault();

    const errors = this.validate(this.state.data);
    this.setState({ errors });

    if (Object.keys(errors).length !== 0) {
      return;
    }

    this.setState({ loading: true });

    try {
      await this.props.loginUser({ ...this.state.data });
      this.props.history.push("/home");
    } catch (error) {
      this.setState({
        errors: { global: error.message },
        data: { username: "", password: "", nit: "" },
        loading: false,
      });

      if (this.usernameRef) this.usernameRef.focus();
    }
  };

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  validate = (data) => {
    const errors = {};

    if (!data.username || data.username.length === 0) errors.username = "Ingrese Nombre de Usuario";

    if (!data.password || data.password.length === 0) errors.password = "Ingrese la contraseña";

    if (!data.nit || data.nit.length === 0) errors.nit = "Ingrese el NIT de su empresa";

    return errors;
  };

  render() {
    const { classes } = this.props;
    const { loading, data, errors } = this.state;
    const styles = {
      media: {
        height: 40,
        paddingTop: "70%", // 16:9,
        marginTop: "10",
        alignContent: "center",
        width: "10",
      },
    };
    return (
      <div className="row">
        <div className={classes.root}>
          <div className={classes.loginContainer}>
            <Paper className={classes.paper} elevation={10}>
              <Grid align="center">
                <form onSubmit={this.onSubmit}>
                  <div>
                    {/* <Avatar style={avatarStyle}>  
                      <ShoppingCart />
                    </Avatar> */}

                    <h3>
                      <strong>Bienvenido a su Punto de Venta </strong>
                    </h3>

                    <CardMedia className={classes.media} image={"assets/images/logoErgy.png"} style={styles.media} />
                  </div>

                  <ErrorMessage show={this.hasErrors()} className={classes.errorMessage} message={errors.global} />

                  <TextField
                    inputRef={(input) => {
                      this.usernameRef = input;
                    }}
                    error={!!errors.username}
                    name="username"
                    value={data.username}
                    fullWidth
                    label="Usuario"
                    placeholder="Username"
                    margin="normal"
                    onChange={this.onChange}
                  />
                  <TextField
                    error={!!errors.password}
                    name="password"
                    value={data.password}
                    fullWidth
                    label="Contraseña"
                    placeholder="Password"
                    type="password"
                    margin="normal"
                    onChange={this.onChange}
                  />
                  <TextField
                    error={!!errors.nit}
                    name="nit"
                    value={data.nit}
                    fullWidth
                    label="NIT"
                    placeholder="NIT"
                    margin="normal"
                    onChange={this.onChange}
                  />

                  <div className={classes.wrapper}>
                    <Button type="submit" variant="contained" color="primary" disabled={loading}>
                      Entrar
                    </Button>
                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </div>
                </form>
              </Grid>
            </Paper>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const isLoggedIn = state.auth !== undefined ? !!state.auth.tokens : false;

  return {
    isLoggedIn,
  };
}

const component = withStyles(styles, { withTheme: true })(LoginPage);

export default withRouter(connect(mapStateToProps, { loginUser })(component));
