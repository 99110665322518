import axios from "axios";
import apiBuilder from "./apiBuilder";
import "./axiosClient";
import transaction from "./transaction";
import statistics from "./statistics";
import productTransformer from "../transformers/productTransformer";
import productSerialApi from "./productSerialApi";
import fileUploadApi from "./fileUploadApi";
import budgetApi from "./budgetApi";
import expenseApi from "./expenseApi";
import stockApi from "./stockApi";
import cashRegisterApi from "./cashRegisterApi";
import receivingsApi from "./receivingsApi";
import maintenanceApi from "./maintenanceApi";
import embroideryApi from "./embroideryApi";
import logoApi from "./logoApi";

const auth = {
  login: async ({ username, password, nit }) => {
    try {
      const res = await axios.post("/api/login", {
        userid: username,
        password,
        nit,
      });
      const { authToken } = res.data;
      if (!authToken) throw new { error: "Credenciales Invalidas." }();
      else return res.data;
    } catch (error) {
      if (error.response && error.response.status === 401) throw new Error("Credenciales Invalidas.");
      if (error.response && error.response.status > 200)
        throw new Error("Error del Servidor, por favor intente en un momento.");
      else throw error;
    }
  },
};

const productType = apiBuilder("producttypes");
const expense = { ...apiBuilder("expenses"), ...expenseApi };
const receiving = { ...apiBuilder("receivings"), ...receivingsApi };
const product = apiBuilder("products", productTransformer);
const productSerial = { ...apiBuilder("productSerial"), ...productSerialApi };
const attached = apiBuilder("attached");
const customer = apiBuilder("customers");
const creditType = apiBuilder("creditType");
const credit = apiBuilder("credit");
const vendor = apiBuilder("vendors");
const requirement = apiBuilder("requirement");
const stock = { ...apiBuilder("stocks"), ...stockApi };
const users = apiBuilder("users");
const roles = apiBuilder("roles");
const state = apiBuilder("boxState");
const cashRegister = { ...apiBuilder("cashRegister"), ...cashRegisterApi };
const balanceBox = apiBuilder("balanceBox");
const balanceBoxEntry = apiBuilder("balance-entry");
const invoices = apiBuilder("invoices");
const resolution = apiBuilder("resolutions");
const section = apiBuilder("section");
const taxes = apiBuilder("taxes");
const shelves = apiBuilder("shelves");
const company = apiBuilder("company");
const apu = apiBuilder("apu");
const budget = { ...apiBuilder("budget"), ...budgetApi };
const bankAccount = apiBuilder("bank-account");
const order = apiBuilder("orders");
const logo = { ...apiBuilder("logo"), ...logoApi };
const maintenance = { ...apiBuilder("maintenance"), ...maintenanceApi };
const embroidery = { ...apiBuilder("embroidery"), ...embroideryApi };
const groupcontract = apiBuilder("groupcontract");

export default {
  auth,
  productType,
  product,
  productSerial,
  attached,
  customer,
  creditType,
  credit,
  expense,
  vendor,
  receiving,
  requirement,
  transaction,
  statistics,
  stock,
  users,
  roles,
  state,
  cashRegister,
  balanceBox,
  balanceBoxEntry,
  invoices,
  resolution,
  section,
  taxes,
  shelves,
  company,
  apu,
  fileUploadApi,
  budget,
  bankAccount,
  order,
  maintenance,
  embroidery,
  logo,
  groupcontract,
};
