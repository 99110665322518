import React from "react";
import PropTypes from "prop-types";
import FullPageDialog from "./FullPageDialog";
import { Grid, Box, Paper, useMediaQuery, useTheme } from "@material-ui/core";

function TwoColsDialog(props) {
  const { open, onClose, title, RenderLeft, RenderRight } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <FullPageDialog title={title} open={open} handleClose={onClose}>
      <Box margin={1.5}>
        <Grid container spacing={3} direction={matches ? "row-reverse" : "row"}>
          <Grid item xs={12} md={4}>
            <Paper>
              <RenderRight />
            </Paper>
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper>
              <Box padding={2}>
                <RenderLeft />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </FullPageDialog>
  );
}

TwoColsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  RenderLeft: PropTypes.elementType.isRequired,
  RenderRight: PropTypes.elementType.isRequired
};

export default TwoColsDialog;
