// eslint-disable-next-line
const styles = theme => ({
  root: {
    // padding: 20,
    minHeight: "80vh",
    flexGrow: 1
  },
  container: {
    minHeight: "inherit"
  },
  item: {
    padding: theme.spacing(4)
  },
  flexContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexFlow: "row",
    width: "100%",
    height: "auto",
    minHeight: "100%",
    [theme.breakpoints.down("sm")]: {
      flexFlow: "column"
    }
  },
  pos: {
    margin: "20px",
    minHeight: "500px",
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "90vw"
    }
  },
  posContent: {
    minHeight: "100%",
    margin: "0 auto -130px;"
  },
  items: {
    order: 1,
    margin: "20px",
    minHeight: "500px",
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "90vw"
    }
  }
});

export default styles;
