import React from "react";
import { TableBody, TableRow } from "@material-ui/core/";
import CustomTableCell from "../controls/CustomTableCell";
import LightButton from "../controls/LightButton";
import DeleteButton from "../controls/DeleteButton";
import CartBodyNoItems from "./CartBodyNoItems";
import { COP as currency } from "../../../../../utils";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AlertIcon from "@material-ui/icons/ReportProblem";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import NumberTextField from "../../../../controls/textfields/NumberTextField";

const CartBody = ({ cartArray, onDeleteCartItem, onProductItemSelect, incrementItem, decrementItem,  onChangeQuantity}) => {
  if (cartArray.length === 0) {
    return <CartBodyNoItems />;
  }



  return (
    <TableBody>
      {cartArray.map((n) => (
        <TableRow key={n.id}>
          <CustomTableCell style={{ width: "25%" }}>
            {n.costPrice > n.totalPrice ? (
              <Grid container>
                <Grid container item xs={3}>
                  <Tooltip title="Producto con Precio menor al Costo">
                    <IconButton aria-label="Producto con Precio menor al Costo" color="secondary">
                      <AlertIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid container item xs={9}>
                  <LightButton text={n.name} onClick={() => onProductItemSelect(n)} />
                </Grid>
              </Grid>
            ) : (
              <LightButton text={n.name} onClick={() => onProductItemSelect(n)} />
            )}
          </CustomTableCell>
          <CustomTableCell align="right">$ {currency(n.sellingPrice).format()}</CustomTableCell>
          <CustomTableCell style={{ width: "25%" }} align="right">
            <Tooltip title="Restar Cantidad">
              <IconButton aria-label="Restar Cantidad" style={{ paddingRight: "5px" }} color="primary" onClick={() => decrementItem(n)}>
                <RemoveCircleIcon align="right" style={{ fontSize: "1rem" }} />
              </IconButton>
            </Tooltip>
            <NumberTextField            
            style={{ width: "50%", margin: "0px", textAlign: "right" }}
            name="initAmount"
            value={n.qty}            
            onKeyUp={(e) => onChangeQuantity(n, e.target.value)}
          />
            <Tooltip title="Sumar Cantidad">
              <IconButton aria-label="Sumar Cantidad" style={{ paddingLeft: "5px" }} color="primary" onClick={() => incrementItem(n)}>
                <AddIcon align="center" style={{ fontSize: "1rem" }} />
              </IconButton>
            </Tooltip>
          </CustomTableCell>
          <CustomTableCell align="center">
            {n.stock ? Number(n.stock.qty) : <span style={{ fontSize: "2rem" }}>∞</span>}
          </CustomTableCell>
          <CustomTableCell align="right">$ {currency(n.totalPrice).format()}</CustomTableCell>
          <CustomTableCell align="right" style={{ width: 30, paddingRight: "5px" }}>
            <DeleteButton onDelete={() => onDeleteCartItem(n)} />
          </CustomTableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

export default CartBody;
