import React from "react";
import PropTypes from "prop-types";
import {
  Typography,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Badge,
  withStyles,
  ListItemSecondaryAction,
  IconButton
} from "@material-ui/core";
import { COP as currency, getFirstLetters } from "../../../utils";
import DeleteIcon from "@material-ui/icons/Delete";

const styles = theme => ({
  avatar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    textTransform: "uppercase"
  },
  item: {
    flex: "1 1 auto",
    padding: "0 36px",
    minWidth: 0,
    textTransform: "uppercase"
  }
});
function APUListItem(props) {
  const { classes, name, qty, unit, totalPrice, weight, performance, product, onClick, onDelete } = props;
  const avatarName = getFirstLetters(name, 2);
  return (
    <ListItem button alignItems="center" onClick={onClick(product)}>
      <ListItemAvatar>
        <Badge
          overlap="circle"
          badgeContent={
            <div style={{ fontSize: "xx-small", textAlign: "center" }}>
              {qty} {unit.replace(/ /g, "")}
            </div>
          }
          color="secondary"
        >
          <Avatar className={classes.avatar}>{avatarName}</Avatar>
        </Badge>
      </ListItemAvatar>
      <ListItemText
        primary={name}
        className={classes.item}
        secondary={
          <>
            <Typography style={{ display: "block" }} variant="caption" color="textSecondary">
              {currency(totalPrice).format(true)} TOTAL
            </Typography>
            {performance && (
              <Typography style={{ display: "block" }} variant="caption" color="textSecondary">
                x{performance.toFixed(2)} RENDIMIENTO.
              </Typography>
            )}
            {weight && (
              <Typography style={{ display: "block" }} variant="caption" color="textSecondary">
                {weight.toFixed(2)} KG TOTAL
              </Typography>
            )}
          </>
        }
      />
      <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="eliminar" onClick={() => onDelete(product)}>
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

APUListItem.propTypes = {
  name: PropTypes.string.isRequired,
  qty: PropTypes.number.isRequired,
  unit: PropTypes.string,
  product: PropTypes.shape({
    id: PropTypes.string.isRequired
  }),
  totalPrice: PropTypes.number.isRequired,
  performance: PropTypes.number,
  weight: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default withStyles(styles, { withTheme: true })(APUListItem);
