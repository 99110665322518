import {
  EMPTY_APU,
  REMOVE_ITEM_FROM_APU,
  ADD_ITEM_TO_APU,
  SET_APU_ATTRIBUTES,
  SET_SELLING_PRICE,
  HYDRATE_APU_FROM_REMOTE
} from "../types";
import update from "immutability-helper";
import { TRANSPORT } from "../components/budget/apu/AddNewAPU";

export const MINOR_TOOL_ID = "MINOR_TOOL_a15db3da-301c-4034";

const initialState = {
  totalAPUPrice: 0,
  totalWeight: 0,
  name: "",
  description: "",
  sections: {
    tools: {
      items: {
        [MINOR_TOOL_ID]: {
          product: {
            id: MINOR_TOOL_ID,
            name: "HERRAMIENTA MENOR",
            unit: "UND",
            sellingPrice: 0
          },
          qty: 1,
          totalPrice: 0,
          performance: 1,
          benefits: null,
          weight: null,
          unitWeight: null,
          name: "HERRAMIENTA MENOR",
          unit: "UND"
        }
      },
      totalSectionPrice: 0,
      totalSectionWeight: 0
    },
    materials: {
      items: {},
      totalSectionPrice: 0,
      totalSectionWeight: 0
    },
    workforce: {
      items: {},
      totalSectionPrice: 0,
      totalSectionWeight: 0
    },
    transport: {
      items: {},
      totalSectionPrice: 0,
      totalSectionWeight: 0
    }
  }
};

function addItem(state, item, section) {
  const itemInSection = state.sections[section].items[item.product.id];
  return update(state, {
    totalAPUPrice: {
      $set: state.totalAPUPrice - (itemInSection ? itemInSection.totalPrice : 0) + item.totalPrice
    },
    totalWeight: {
      $set:
        section === TRANSPORT
          ? state.totalWeight
          : +(state.totalWeight - (itemInSection ? itemInSection.weight : 0) + item.weight).toFixed(4)
    },
    sections: {
      [section]: {
        items: {
          [item.product.id]: {
            $set: item
          }
        },
        totalSectionWeight: {
          $set: +(
            state.sections[section].totalSectionWeight -
            (itemInSection ? itemInSection.weight : 0) +
            item.weight
          ).toFixed(4)
        },
        totalSectionPrice: {
          $set:
            state.sections[section].totalSectionPrice - (itemInSection ? itemInSection.totalPrice : 0) + item.totalPrice
        }
      }
    }
  });
}

function removeItem(state, item, section) {
  if (item.product.id === MINOR_TOOL_ID) {
    return state;
  }
  return update(state, {
    totalAPUPrice: {
      $set: state.totalAPUPrice - item.totalPrice
    },
    totalWeight: {
      $set: section === TRANSPORT ? state.totalWeight : +(state.totalWeight - item.weight).toFixed(4)
    },
    sections: {
      [section]: {
        items: {
          $unset: [item.product.id]
        },
        totalSectionWeight: {
          $set: +(state.sections[section].totalSectionWeight - item.weight).toFixed(4)
        },
        totalSectionPrice: {
          $set: state.sections[section].totalSectionPrice - item.totalPrice
        }
      }
    }
  });
}

function setAttributes(state, data) {
  return update(state, {
    name: {
      $set: data.name
    },
    description: {
      $set: data.description
    }
  });
}

function setMinorToolSellingPrice(state, newPrice) {
  newPrice = +newPrice;
  if (Number.isNaN(newPrice)) {
    return state;
  }
  const itemInSection = state.sections.tools.items[MINOR_TOOL_ID];
  const newTotalPrice = (itemInSection.qty * newPrice) / itemInSection.performance;
  return update(state, {
    totalAPUPrice: {
      $set: state.totalAPUPrice - itemInSection.totalPrice + newTotalPrice
    },
    sections: {
      tools: {
        items: {
          [MINOR_TOOL_ID]: {
            product: {
              sellingPrice: {
                $set: newPrice
              }
            },
            totalPrice: {
              $set: newTotalPrice
            }
          }
        },
        totalSectionPrice: {
          $set: state.sections.tools.totalSectionPrice - itemInSection.totalPrice + newTotalPrice
        }
      }
    }
  });
}

export default function apu(state = initialState, action = { data: {}, section: "" }) {
  switch (action.type) {
    case ADD_ITEM_TO_APU:
      return addItem(state, action.data, action.section);

    case REMOVE_ITEM_FROM_APU:
      return removeItem(state, action.data, action.section);

    case SET_APU_ATTRIBUTES:
      return setAttributes(state, action.data);

    case SET_SELLING_PRICE:
      return setMinorToolSellingPrice(state, action.data);

    case HYDRATE_APU_FROM_REMOTE:
      return action.data;

    case EMPTY_APU:
      return initialState;

    default:
      return state;
  }
}
