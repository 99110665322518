import React from "react";
import Container from "../controls/Container";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import DailySales from "./DailySales";
import TopProducts from "./TopProducts";
import EarningsVsExpenses from "./EarningsVsExpenses";
import TopSellers from "./TopSellers";
import MontlyExpenses from "./MontlyExpenses";
import DailySalesByBox from "./DailySalesByBox";

const styles = (theme) => ({
  card: { width: 450, margin: "5px", overflow: "visible" },
  temp: { height: "40vh" },
  cardContainer: { display: "flex", flexWrap: "wrap", justifyContent: "center" },
  cardTitle: { color: theme.palette.primary.main, fontWeight: "bold", textTransform: "uppercase" },
});

const STATS_REGISTRY = [
  { title: "Ventas diarias", content: <DailySales />, forAdmin: false },
  { title: "Venta actual por Caja", content: <DailySalesByBox />, forAdmin: true },
  { title: "Productos más vendidos en el trimestre", content: <TopProducts />, forAdmin: false },
  {
    title: "Ganancias vs Gastos",
    content: <EarningsVsExpenses />,
    forAdmin: true,
    subheader: "Los gastos son tomados como la suma del precio de costo con iva de los productos.",
  },
  { title: "Mejores Vendedores mes actual", content: <TopSellers />, forAdmin: true },
  {
    title: "Relación mensual de gastos, costos y ventas",
    content: <MontlyExpenses />,
    forAdmin: true,
    subheader: "Relación de los gastos realizados, los costos de los productos vendidos y el total vendido, por mes.",
  },
];

function Statistics(props) {
  const { classes, user } = props;
  const stats = user.role !== "admin" ? STATS_REGISTRY.filter((stat) => !stat.forAdmin) : STATS_REGISTRY;
  const content = stats.map((stat) => (
    <Card className={classes.card} key={stat.title}>
      <CardHeader classes={{ title: classes.cardTitle }} title={stat.title} subheader={stat.subheader} />
      <CardContent>{stat.content}</CardContent>
    </Card>
  ));
  return (
    <Container title="Inicio">
      <div className={classes.cardContainer}>{content}</div>
    </Container>
  );
}

function mapStateToProps(state) {
  return { user: state.auth.user };
}

export default withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, null)(Statistics)));
