import { COP as currency } from "../utils";
import {
  ADD_ITEM_TO_CART,
  REMOVE_ITEM_FROM_CART,
  UPDATE_CART_ITEM,
  UPDATE_CART_SERIAL,
  REMOVE_SERIAL_ITEM,
  EMPTY_CART,
  UPDATE_DISCOUNT_ON_TOTAL,
  UPDATE_DISCOUNT_ON_ITEMS,
  UPDATE_TAX,
  ON_TAX_CHANGE,
  HANDLE_CHANGE,
  ON_TAXES,
  HYDRATE_CART_FROM_REMOTE,
  SET_CUSTOMER,
  SET_COMMENTS,
  ON_CHANGE_BOX,
  TYPE_DISCOUNT,
  SET_TRANSACTION_OPTIONS
} from "../types";

const initialState = {
  items: {},
  serials: {},
  options: { forRemission: false, skipResolution: false },
  summary: {
    noOfItems: 0,
    noOfInividualItems: 0,
    tax: "0", //porcentaje de impuestos
    taxAmount: "0.00", // valor en moneda del porcentaje de impuestos
    taxDetails: [],
    discountOnTotal: "0.00",
    discountOnItems: "0.00",
    discountPercent: "0%",
    totalDetailsIva: "0.00",
    totalDetailsImpoconsumo: "0.00",
    total: "0.00", // With discount on items
    netTotal: "0.00", // Net - (tax(positivo) + totalIva + discount on total)
    totalPay: "0.00", // Net - (tax(positivo y negativo) + totalIva + discount on total)
    customerId: null,
    customer: null,
    comments: "",
    maxAmountDiscountTotal: 0,
    maxPercentDiscountTotal: 100
  },
  taxes: [],
  allTaxes: [],
  boxId: null,
  typeDiscount: { type: "$", value: 0 },
  extra: { state: "", discountForItem: {}, discountForInvoice: 0 }
};

const cloneObj = state => {
  const clone = {
    serials: {},
    items: {},
    summary: {},
    taxes: [],
    allTaxes: [],
    boxId: {},
    typeDiscount: {},
    extra: {},
    options: {}
  };

  Object.assign(clone.serials, state.serials);
  Object.assign(clone.items, state.items);
  Object.assign(clone.summary, state.summary);
  Object.assign(clone.taxes, state.taxes);
  Object.assign(clone.allTaxes, state.allTaxes);
  Object.assign(clone.boxId, state.boxId);
  Object.assign(clone.typeDiscount, state.typeDiscount);
  Object.assign(clone.options, state.options);
  Object.assign(clone.extra, state.extra);
  return clone;
};
/*modifica los valores del item dentro del arreglo de items 
  general asi como los datos extra para manejar el descuento*/
const setCartItem = (state, item) => {
  // eslint-disable-next-line no-param-reassign
  state.items[item.id] = {};
  const { extra } = state;
  const newItem = state.items[item.id];  
  newItem.id = item.id;
  newItem.sku = item.sku;
  newItem.name = item.name;
  newItem.description = item.description;
  newItem.qty = item.qty;
  newItem.price = currency(item.price).value;
  newItem.attributes = item.attributes;
  /*if (item.attributes.profit !== 0) {
    let costTemp = item.qty * item.price;
    let costCalc = costTemp * (item.attributes.profit / 100);
    item.costPrice = costTemp - costCalc;
  }*/
  newItem.costPrice = currency(item.costPrice).value;
  newItem.discount = currency(item.discount).value;
  newItem.discountTotal = currency(newItem.discount).multiply(item.qty).value;
  newItem.freeItems = 0; //toca verificar que si tiene algun dato lo ponga
  newItem.freeItemsCost = 0; //toca verificar que si tiene algun dato lo ponga
  newItem.stock = item.stock; //toca verificar que si tiene algun dato lo ponga
  newItem.discountRule = item.discountRule[0] ? item.discountRule[0] : item.discountRule; //toca verificar que si tiene algun dato lo ponga
  let totalPrice = currency(newItem.price).subtract(newItem.discount).value;
  totalPrice = currency(totalPrice).multiply(newItem.qty).value;
  
  const sellingPrice = currency(totalPrice).divide(1 + Number(item.iva+item.impoconsumo) / 100).value; //(100 + Number(item.iva)) / 100).value;
  const ivaPrice = currency(sellingPrice).multiply(Number(item.iva)  / 100).value;  
  const impoconsumoPrice = currency(sellingPrice).multiply(Number(item.impoconsumo)  / 100).value;

  // This will be the price shown in the cart gridview.
  newItem.sellingPrice = currency(totalPrice).divide(1 + Number(item.iva+item.impoconsumo) / 100).value;
  // Se calcula el IVA
  newItem.iva = item.iva;
  newItem.ivaPrice = currency(ivaPrice).value;
  newItem.ivaPercentageString = `${item.iva}%`;
  newItem.impoconsumo = item.impoconsumo;
  newItem.impoconsumoPrice = currency(impoconsumoPrice).value;
  newItem.impoconsumoPercentageString = `${item.impoconsumo}%`;

  newItem.totalPrice = currency(totalPrice).value;
  newItem.hasSerial = item.hasSerial;
  const discountMax = valuesDiscount(newItem, newItem.discountRule);
  newItem.maxAmountDiscount = currency(discountMax.amountRule).divide(newItem.qty).value;
  newItem.maxPercentDiscount = discountMax.percentRule;
  const lengthExtra = extra.discountForItem.length;
  let itemList = true;
  if (!lengthExtra) {
    extra.discountForItem = [{ id: newItem.id, discount: newItem.discountTotal }];
  } else {
    extra.discountForItem.forEach(discountItem => {
      if (discountItem.id === newItem.id) {
        discountItem.discount = newItem.discountTotal;
        itemList = false;
      }
    });
    if (itemList) {
      extra.discountForItem.push({ id: newItem.id, discount: newItem.discountTotal });
    }
  }
};

const setCartSerial = (state, serial) => {
  // eslint-disable-next-line no-param-reassign
  const key = serial.serialId;
  state.serials[key] = {};
  const newSerial = state.serials[key];
  newSerial.id = serial.id;
  newSerial.productId = serial.productId;
  newSerial.serial = serial.serial;
  newSerial.serialId = serial.serialId;
  newSerial.description = serial.extra ? serial.extra.description : "";
};

const updateCartSerial = (oldState, serial) => {
  const state = cloneObj(oldState);
  setCartSerial(state, serial);
  return state;
};

/*realiza los calculos de summary.tax y summary.taxAmount y modifica los datos dentro de taxes*/
const updateSummaryTax = (oldState, newTaxes) => {
  const state = cloneObj(oldState);
  const { summary } = state;
  state.taxes = [];
  summary.tax = 0;
  summary.taxAmount = 0;
  newTaxes.forEach(tax => {
    let amountTax = 0;
    if (tax.checked) {
      if (tax.type === "Porciento") {
        summary.tax += Number(tax.percent);
        amountTax = currency(summary.total)
          .subtract(summary.discountOnItems)
          .multiply(Number(tax.percent) * 0.01).value;
      } else {
        summary.tax += (Number(tax.amount) * 100) / summary.total;
        amountTax = Number(tax.amount);
      }
      summary.taxAmount =
        tax.action === "-"
          ? currency(summary.taxAmount).subtract(amountTax).value
          : currency(summary.taxAmount).add(amountTax).value;
      /**
       * guarda el valor de cada impuesto en la lista de impuestos general de la venta
       */
      setTax(state, tax, amountTax, summary.total);
    }
  });
  return { percent: summary.tax, amount: summary.taxAmount };
};

/*modifica valores de un item de la lista de items y modifica los valores de summary*/
const updateCartItem = (oldState, item) => {  
  const state = cloneObj(oldState);  
  setCartItem(state, item);
  // Subtract the old stuff from state to nullify.
  const { summary, taxes, serials } = state;
  const oldItem = oldState.items[item.id];
  const newItem = state.items[item.id];
  const itemsValues = Object.values(state.items);
  if (newItem.hasSerial) {
    const oldSerials = Object.values(serials).filter(serial => serial.productId === newItem.id);
    for (let j = 0; j < oldSerials.length; j++) {
      if (j >= newItem.qty) {
        delete state.serials[oldSerials[j].serialId];
      }
    }
  }
  summary.noOfInividualItems = currency(summary.noOfInividualItems)
    .add(newItem.qty)
    .subtract(oldItem.qty).value;
  summary.discountOnItems = currency(summary.discountOnItems)
    .add(newItem.discountTotal)
    .subtract(oldItem.discountTotal).value;
  let maxDiscount = 0;
  const total = itemsValues.reduce((prev, curr) => {
    maxDiscount += curr.maxAmountDiscount * curr.qty;
    return prev + currency(curr.price).multiply(curr.qty).value;
  }, 0);
  summary.discountPercent = Number((summary.discountOnItems * 100) / total);
  summary.maxAmountDiscountTotal = maxDiscount;
  summary.maxPercentDiscountTotal =
    summary.maxPercentDiscountTotal > newItem.maxPercentDiscount
      ? newItem.maxPercentDiscount
      : summary.maxPercentDiscountTotal;
  summary.total = currency(summary.total)
    .add(
      currency(newItem.totalPrice)
        .subtract(newItem.ivaPrice)
        .subtract(newItem.impoconsumoPrice)
        .add(newItem.discountTotal)
    )
    .subtract(
      currency(oldItem.totalPrice)
        .subtract(oldItem.ivaPrice)
        .subtract(oldItem.impoconsumoPrice)
        .add(oldItem.discountTotal)
    ).value;
  summary.totalDetailsIva = currency(summary.totalDetailsIva)
    .add(newItem.ivaPrice)
    .subtract(oldItem.ivaPrice).value;
  summary.totalDetailsImpoconsumo = currency(summary.totalDetailsImpoconsumo)
    .add(newItem.impoconsumoPrice)
    .subtract(oldItem.impoconsumoPrice).value;
  const tax = updateSummaryTax(state, taxes);
  summary.tax = tax.percent;
  summary.taxAmount = tax.amount;
  summary.totalPay = currency(summary.total)
    .add(summary.totalDetailsIva)
    .add(summary.totalDetailsImpoconsumo)
    .subtract(summary.discountOnItems)
    .add(summary.taxAmount).value;
  summary.netTotal = currency(summary.total)
    .add(summary.totalDetailsIva)
    .add(summary.totalDetailsImpoconsumo)
    .subtract(summary.discountOnItems).value;
  taxes.forEach(tax => {
    if (tax.action === "+") {
      summary.netTotal = currency(summary.netTotal).add(tax.amount).value;
    }
  });
  return state;
};
/*agrega un item nuevo al arreglo de items 
  general y modifica los valores de summary*/
const addItemToCart = (oldState, item) => {
  const state = cloneObj(oldState);  
  setCartItem(state, item);
  // setCartItem adds the new item to the state. So the assignment below holds the new added item.
  const newItem = state.items[item.id];
  // Summary updates
  const { summary, taxes } = state;
  summary.noOfItems++;
  summary.noOfInividualItems += newItem.qty;
  summary.discountOnItems = currency(summary.discountOnItems).add(newItem.discountTotal).value;
  const itemsValues = Object.values(state.items); /////
  const total = itemsValues.reduce((prev, curr) => {
    return prev + currency(curr.price).multiply(curr.qty).value;
  }, 0);
  summary.discountPercent = Number((summary.discountOnItems * 100) / total);
  summary.maxAmountDiscountTotal = currency(summary.maxAmountDiscountTotal).add(newItem.maxAmountDiscount).value;
  summary.maxPercentDiscountTotal =
    summary.maxPercentDiscountTotal > newItem.maxPercentDiscount
      ? newItem.maxPercentDiscount
      : summary.maxPercentDiscountTotal;
  summary.totalDetailsIva = currency(summary.totalDetailsIva).add(newItem.ivaPrice).value;
  summary.totalDetailsImpoconsumo = currency(summary.totalDetailsImpoconsumo).add(newItem.impoconsumoPrice).value;
  
  // This is the total price after applying discount on items.
  summary.total = currency(summary.total).add(currency(newItem.totalPrice).subtract(newItem.ivaPrice).subtract(newItem.impoconsumoPrice)).value;
  const tax = updateSummaryTax(state, taxes);
  summary.tax = tax.percent;
  summary.taxAmount = tax.amount;
  summary.totalPay = currency(summary.total)
    .add(summary.totalDetailsIva)
    .add(summary.totalDetailsImpoconsumo)
    .subtract(summary.discountOnItems)
    .add(summary.taxAmount).value;
  summary.netTotal = currency(summary.total)
    .add(summary.totalDetailsIva)
    .add(summary.totalDetailsImpoconsumo)
    .subtract(summary.discountOnItems).value;
  taxes.forEach(tax => {
    if (tax.action === "+") {
      summary.netTotal = currency(summary.netTotal).add(tax.amount).value;
    }
  });
  return state;
};

const removeSerialItem = (oldState, serialItem) => {
  const state = cloneObj(oldState);
  Object.values(state.serials).forEach(serial => {
    if (serial.serialId === serialItem.serialId) {
      delete state.serials[serial.serialId];
    }
  });
  return state;
};

/*elimina un item dentro del arreglo de items 
  general y resta la informacion de este al summary*/
const removeItemFromCart = (oldState, item) => {
  const state = cloneObj(oldState);
  delete state.items[item.id];
  const isCartEmpty = state.items.length === 0;
  if (isCartEmpty) {
    return initialState;
  }
  Object.values(state.serials).forEach(serial => {
    if (serial.productId === item.id) {
      delete state.serials[serial.serialId];
    }
  });
  const { summary, taxes } = state;
  summary.noOfItems--;
  summary.noOfInividualItems -= item.qty;
  summary.discountOnItems = currency(summary.discountOnItems).subtract(item.discountTotal).value;
  const itemsValues = Object.values(state.items);
  let minPercent = 100;
  const total = itemsValues.reduce((prev, curr) => {
    minPercent = minPercent > curr.maxPercentDiscount ? curr.maxPercentDiscount : minPercent;
    return prev + currency(curr.price).multiply(curr.qty).value;
  }, 0);
  summary.discountPercent = Number((summary.discountOnItems * 100) / total);
  summary.maxAmountDiscountTotal = currency(summary.maxAmountDiscountTotal).subtract(
    item.maxAmountDiscount * item.qty
  ).value;
  summary.maxPercentDiscountTotal = minPercent;
  summary.total = currency(summary.total).subtract(
    currency(item.totalPrice)
      .subtract(item.ivaPrice)
      .subtract(item.impoconsumoPrice)
      .add(item.discountTotal)
  ).value;
  summary.totalDetailsIva = currency(summary.totalDetailsIva).subtract(item.ivaPrice).value;
  summary.totalDetailsImpoconsumo = currency(summary.totalDetailsImpoconsumo).subtract(item.impoconsumoPrice).value;
  const tax = updateSummaryTax(state, taxes);
  summary.tax = tax.percent;
  summary.taxAmount = tax.amount;
  summary.totalPay = currency(summary.total)
    .add(summary.totalDetailsIva)
    .add(summary.totalDetailsImpoconsumo)
    .subtract(summary.discountOnItems)
    .add(summary.taxAmount).value;
  summary.netTotal = currency(summary.total)
    .add(summary.totalDetailsIva)
    .add(summary.totalDetailsImpoconsumo)
    .subtract(summary.discountOnItems).value;
  taxes.forEach(tax => {
    if (tax.action === "+") {
      summary.netTotal = currency(summary.netTotal).add(tax.amount).value;
    }
  });
  return state;
};

const typeDiscount = (oldState, type) => {
  const state = cloneObj(oldState);
  return state;
};

/*modifica el descuento partiendo del valor total del item*/
const updateDiscountOnItems = (oldState, discount) => {
  const state = cloneObj(oldState);
  const { summary, items, taxes } = state;
  const keys = Object.keys(items);
  const length = keys.length;
  let discountTotal = 0;
  let newDiscount = 0;
  let totalPrice = 0;
  for (let idx = 0; idx < length; idx++) {
    const tmp = items[keys[idx]];
    const total = currency(tmp.price).multiply(tmp.qty).value;
    if (discount === 0) {
      totalPrice = currency(total).subtract(newDiscount).value;
    } else if (discount < 0) {
      newDiscount = (total * (discount * -1)) / 100;
      totalPrice = total + newDiscount;
      newDiscount = currency(newDiscount).multiply(-1).value;
      discountTotal += newDiscount;
    } else {
      newDiscount = (total * discount) / 100;
      discountTotal += newDiscount;
      totalPrice = total - newDiscount;
    }
    let sellingPrice = currency(totalPrice).divide(1 + Number(tmp.iva+tmp.impoconsumo) / 100).value;
    tmp.discount = newDiscount / tmp.qty;
    tmp.discountTotal = newDiscount;
    tmp.sellingPrice = currency(sellingPrice).value;
    tmp.totalPrice = currency(totalPrice).value;
    tmp.ivaPrice = currency(tmp.totalPrice).subtract(tmp.sellingPrice).value;
    tmp.impoconsumoPrice = currency(tmp.totalPrice).subtract(tmp.sellingPrice).value;
  }
  const itemsValues = Object.values(items);
  summary.discountPercent = Number(discount);
  summary.discountOnTotal = discountTotal;
  summary.discountOnItems = itemsValues.reduce((prev, curr) => {
    return prev + curr.discountTotal;
  }, 0);
  summary.totalDetailsIva = itemsValues.reduce((prev, curr) => {
    return prev + curr.ivaPrice;
  }, 0);
  summary.totalDetailsImpoconsumo = itemsValues.reduce((prev, curr) => {
    return prev + curr.impoconsumoPrice;
  }, 0);
  summary.total = itemsValues.reduce((prev, curr) => {
    return (
      prev +
      currency(curr.totalPrice)
        .subtract(curr.ivaPrice)
        .subtract(curr.impoconsumoPrice)
        .add(curr.discountTotal).value
    );
  }, 0);
  summary.netTotal = itemsValues.reduce((prev, curr) => {
    return prev + curr.totalPrice;
  }, 0);
  const tax = updateSummaryTax(state, taxes);
  summary.tax = tax.percent;
  summary.taxAmount = tax.amount;
  summary.totalPay = currency(summary.netTotal).add(summary.taxAmount).value;
  taxes.forEach(tax => {
    if (tax.action === "+") {
      summary.netTotal = currency(summary.netTotal).add(tax.amount).value;
    }
  });
  return state;
};

const updateDiscountOnTotal = (oldState, discount) => {
  const state = cloneObj(oldState);
  const { summary, taxes } = state;
  summary.discountOnTotal = currency(discount).value;
  const itemsValues = Object.values(state.items);
  const total = itemsValues.reduce((prev, curr) => {
    return prev + currency(curr.price).multiply(curr.qty).value;
  }, 0);
  summary.discountPercent = Number((summary.discountOnItems * 100) / total);
  const tax = updateSummaryTax(state, taxes);
  summary.tax = tax.percent;
  summary.taxAmount = tax.amount;
  summary.netTotal = currency(summary.total).value;
  summary.totalPay = currency(summary.total).add(summary.taxAmount).value;
  taxes.forEach(tax => {
    if (tax.action === "+") {
      summary.netTotal = currency(summary.netTotal).add(tax.amount).value;
    }
  });
  return state;
};

const setTax = (state, taxes, amountTax, total) => {
  const newTaxes = {};
  newTaxes.id = taxes.id;
  newTaxes.amount = amountTax;
  newTaxes.percent =
    taxes.type === "Porciento" ? Number(taxes.value) : ((Number(taxes.value) * 100) / total).toFixed(3);
  newTaxes.action = taxes.action;
  newTaxes.type = taxes.type;
  newTaxes.checked = taxes.checked;
  state.taxes.push(newTaxes);
};

const updateTax = (oldState, newTaxes) => {
  const state = cloneObj(oldState);
  const { summary } = state;
  state.allTaxes = newTaxes;
  state.taxes = [];
  summary.tax = 0;
  summary.taxAmount = 0;
  newTaxes.forEach(tax => {
    let amountTax = 0;
    if (tax.checked) {
      if (tax.type === "Porciento") {
        summary.tax += Number(tax.value);
        amountTax = currency(summary.total)
          .subtract(summary.discountOnItems)
          .multiply(Number(tax.value) * 0.01).value;
      } else {
        summary.tax += (Number(tax.value) * 100) / summary.total;
        amountTax = Number(tax.value);
      }
      summary.taxAmount =
        tax.action === "-"
          ? currency(summary.taxAmount).subtract(amountTax).value
          : currency(summary.taxAmount).add(amountTax).value;
      /**
       * guarda el valor de cada impuesto en la lista de impuestos general de la venta
       */
      setTax(state, tax, amountTax, summary.total);
    }
  });
  const { taxes } = state;
  summary.totalPay = currency(summary.total)
    .add(summary.totalDetailsIva)
    .add(summary.totalDetailsImpoconsumo)    
    .subtract(summary.discountOnItems)
    .add(summary.taxAmount).value;
  summary.netTotal = currency(summary.total)
    .add(summary.totalDetailsIva)
    .add(summary.totalDetailsImpoconsumo)    
    .subtract(summary.discountOnItems).value;
  taxes.forEach(tax => {
    if (tax.action === "+") {
      summary.netTotal = currency(summary.netTotal).add(tax.amount).value;
    }
  });
  return state;
};

const onTaxes = (oldState, e) => {
  const state = cloneObj(oldState);
  state.allTaxes = e;
  return state;
};

const onTaxChange = (oldState, e) => {
  const state = cloneObj(oldState);
  const { allTaxes } = state;
  allTaxes.forEach(n => {
    if (n.id === e.target.name) {
      n.value = e.target.value;
    }
  });
  return state;
};

const handleChange = (oldState, row) => {
  const state = cloneObj(oldState);
  const { allTaxes } = state;
  allTaxes.forEach(n => {
    if (n.id === row.id) {
      n.checked = !n.checked;
    }
  });
  return state;
};

const hydrateCart = (state, data) => {
  const extra = {
    state: data.state,
    discountForItem: data.details.map(detail => ({ id: detail.productId, discount: detail.discount })),
    discountForInvoice: 0
  };
  const items = data.details
    .map(detail => ({
      id: detail.productId,
      sku: detail.product.sku,
      name: detail.product.name,
      description: detail.product.description,
      attributes: detail.product.extra ? detail.product.extra.attributes : { profit: 0, weight: 0, color: "", wide: 0, high: 0, long: 0 },
      qty: Number(detail.qty),
      price: currency(detail.price).multiply(1 + Number(detail.ivaPercentageString.replace("%", "")) / 100).value,
      costPrice: detail.costPrice,
      discountTotal: detail.discount,
      discount: detail.discount / detail.qty,
      freeItems: detail.freeItems,
      freeItemsCost: detail.freeItemsCost,
      stock: detail.product.stock,
      discountRule: detail.product.discountRule[0], //toca verificar que si tiene algun dato lo ponga
      maxAmountDiscount: valuesDiscount(detail, detail.product.discountRule[0]).amountRule,
      maxPercentDiscount: valuesDiscount(detail, detail.product.discountRule[0]).percentRule,
      sellingPrice: currency(detail.sellingPrice).value,
      iva: detail.ivaPercentageString ? detail.ivaPercentageString.replace("%", "") : detail.product.iva,
      ivaPrice: detail.ivaPrice,
      ivaPercentageString: detail.ivaPercentageString ? detail.ivaPercentageString : `${detail.product.iva}%`,
      impoconsumo: detail.impoconsumo ? detail.impoconsumo.replace("%", "") : detail.product.impoconsumo,
      impoconsumoPrice: detail.impoconsumo,
      impoconsumoPercentageString: detail.impoconsumo ? detail.impoconsumo : `${detail.product.impoconsumo}%`,
      totalPrice: detail.totalPrice,
      hasSerial: detail.product.hasSerial
    }))
    .reduce((a, b) => ({ ...a, ...{ [b.id]: b } }), {});    
  const itemsValues = Object.values(items);
  let minPercent = 100;
  let maxDiscount = 0;
  const total = itemsValues.reduce((prev, curr) => {
    maxDiscount += curr.maxAmountDiscount;
    minPercent = minPercent > curr.maxPercentDiscount ? curr.maxPercentDiscount : minPercent;
    return prev + currency(curr.price).multiply(curr.qty).value;
  }, 0);
  const summary = {
    noOfItems: data.details.length,
    noOfInividualItems: data.details.reduce((a, b) => a + Number(b.qty), 0),
    tax: data.taxPercentageString.replace("%", ""),
    taxAmount: data.tax,
    taxDetails: [],
    discountOnTotal: data.discountOnTotal,
    discountOnItems: data.discountOnItems,
    discountPercent: Number((data.discountOnItems * 100) / total),
    totalDetailsIva: data.totalDetailsIva,
    totalDetailsImpoconsumo: data.totalDetailsImpoconsumo,
    total: currency(data.netAmount).add(data.discountOnItems).value,
    netTotal: data.billAmount,
    totalPay: data.totalPay,
    customerId: data.customerId,
    comments: data.comments,
    customer: data.customer,
    maxAmountDiscountTotal: maxDiscount,
    maxPercentDiscountTotal: minPercent
  };
  const typeDiscount = { type: data.type, value: data.value };
  let taxes = [];
  let allTaxes = [];
  data.transactionTax.forEach(tax => {
    const obj = {
      id: tax.taxesId,
      amount: tax.taxes.action === "-" ? Number(tax.amount) * -1 : Number(tax.amount),
      percent: tax.percent,
      action: tax.taxes.action,
      type: tax.taxes.type,
      checked: true
    };
    const obj1 = {
      id: tax.taxesId,
      type: tax.taxes.type,
      value: tax.taxes.type === "Porciento" ? Number(tax.percent) : Number(tax.amount) * -1,
      action: tax.taxes.action,
      extra: null,
      checked: true
    };
    taxes.push(obj);
    allTaxes.push(obj1);
  });
  return { ...state, items, summary, typeDiscount, extra, taxes, allTaxes };
};

const valuesDiscount = (item, discountRule) => {
  let amountRule = Number(item.totalPrice) - Number(item.costPrice);
  amountRule = amountRule > 0 ? amountRule : 0;
  let percentRule = (amountRule * 100) / item.totalPrice;
  switch (discountRule.typeRule) {
    case "Porciento":
      percentRule = discountRule.value;
      amountRule = (item.totalPrice * percentRule) / 100;
      break;
    case "Monto":
      amountRule = item.totalPrice - discountRule.value > 0 ? discountRule.value : 0;
      percentRule = (amountRule * 100) / item.totalPrice;
      break;
    default:
      break;
  }
  return { percentRule: percentRule, amountRule: amountRule };
};

export default function cart(state = initialState, action = {}) {
  switch (action.type) {
    case ADD_ITEM_TO_CART:
      return addItemToCart(state, action.data);

    case UPDATE_CART_ITEM:
      return updateCartItem(state, action.data);

    case UPDATE_CART_SERIAL:
      return updateCartSerial(state, action.serial);

    case REMOVE_SERIAL_ITEM:
      return removeSerialItem(state, action.serial);

    case TYPE_DISCOUNT:
      return typeDiscount(state, action.data);

    case REMOVE_ITEM_FROM_CART:
      return removeItemFromCart(state, action.data);

    case UPDATE_DISCOUNT_ON_ITEMS:
      return updateDiscountOnItems(state, action.data);

    case UPDATE_DISCOUNT_ON_TOTAL:
      return updateDiscountOnTotal(state, action.data);

    case UPDATE_TAX:
      return updateTax(state, action.data);

    case ON_TAX_CHANGE:
      return onTaxChange(state, action.data);

    case HANDLE_CHANGE:
      return handleChange(state, action.data);

    case ON_TAXES:
      return onTaxes(state, action.data);

    case HYDRATE_CART_FROM_REMOTE:
      return hydrateCart(state, action.data);

    case ON_CHANGE_BOX:
      return { ...state, boxId: action.data };

    case SET_CUSTOMER:
      return { ...state, summary: { ...state.summary, customerId: action.data.id, customer: action.data } };

    case SET_COMMENTS:
      return { ...state, summary: { ...state.summary, comments: action.data } };

    case EMPTY_CART:
      return initialState;

    case SET_TRANSACTION_OPTIONS:
      return { ...state, options: { ...action.data } };

    default:
      return state;
  }
}
