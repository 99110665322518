import React, { Component } from "react";
import { Paper } from "@material-ui/core/";
import DetailsForm from "./detailsForm";
import Footer from "./footer";

export default class SaleDetails extends Component {
  state = {};

  render() {
    return (
      <Paper
        style={{
          overflow: "auto",
          display: "block",
          width: "100%",
          height: "100%",
        }}
      >
        <div style={{ padding: "20px" }}>
          <DetailsForm />
          <Footer />
        </div>
      </Paper>
    );
  }
}
