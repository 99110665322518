import React from "react";
import { Avatar } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  container: {
    display: "inline-flex",
    flexFlow: "row",
    alignItems: "baseline"
  },
  purpleAvatar: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main
  }
});
function UserAvatar(props) {
  const { classes, user } = props;
  return (
    <div className={classes.container}>
      <Avatar className={classes.purpleAvatar}>{user.userid.toUpperCase().charAt(0)}</Avatar>
      <span style={{ padding: 5 }}>{user.userid.toUpperCase()}</span>
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(UserAvatar);
