import React from "react";
import PropTypes from "prop-types";
import ProductPicker from "../../products/ProductPicker/ProductPicker";
import ItemForm from "./ItemForm";
import { connect } from "react-redux";
import { updateSelectedProduct } from "../../../actions/productList";
import TwoColsDialog from "../../controls/dialog/TwoColsDialog";

function mapStateToProps(state) {
  return { productList: state.productList };
}

const mapDispatchToProps = { updateSelected: updateSelectedProduct };

const ConnectedItemForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemForm);

function AddItemModal(props) {
  const { open, onClose, onSubmit } = props;
  function onFormSubmit(formData) {
    onSubmit(formData);
    onClose();
  }
  return (
    <TwoColsDialog
      title="Registrar Item"
      open={open}
      onClose={onClose}
      RenderLeft={ProductPicker}
      RenderRight={() => <ConnectedItemForm onSubmit={onFormSubmit} />}
    />
  );
}

AddItemModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func.isRequired
};

export default AddItemModal;
