import { INITIALIZE_SELECTED, UPDATE_SELECTED_PRODUCT, REMOVE_SELECTED, EMPTY_SELECTED } from "../types";

const initialState = {};

function initialize(state, key) {
  return {
    ...state,
    [key]: {
      selected: {}
    }
  };
}

function updateSelectedProduct(state, { key, product, qty }) {
  if (!state[key]) {
    console.warn(
      `Se intentó actualizar un producto de la lista ${key} pero la lista no existe. Intenta inicializarla primero con la acción ${INITIALIZE_SELECTED}.`
    );
    return state;
  }
  if (qty <= 0) {
    delete state[key].selected[product.id];
    return { ...state };
  }
  const newSelected = { ...state[key].selected, [product.id]: { qty: qty, product } };
  return { ...state, [key]: { selected: newSelected } };
}

function removeSelected(state, key) {
  delete state[key];
  return { ...state };
}

export default function productList(state = initialState, action = {}) {
  switch (action.type) {
    case INITIALIZE_SELECTED:
      return initialize(state, action.data);

    case UPDATE_SELECTED_PRODUCT:
      return updateSelectedProduct(state, action.data);

    case REMOVE_SELECTED:
      return removeSelected(state, action.data);

    case EMPTY_SELECTED:
      return initialState;

    default:
      return state;
  }
}
