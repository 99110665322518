import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles, Button } from "@material-ui/core/";
import NormalSale from "../sale/NormalSale";
import CreditSale from "../sale/CreditSale";
import api from "../../../../api";
import { getCartItemsArraySelector } from "../../../../selectors";
import { COP as currency } from "../../../../utils";
import { emptyCart } from "../../../../actions/cart";
import print from "print-js";
import { saveAs } from "file-saver";
import { LoadingContext } from "../../LoadingContext";

const styles = () => ({
  root: { marginTop: "20px" },
  button: { marginBottom: "1px", boxShadow: "none" }
});

class Footer extends Component {
  state = { showNormalPopup: false, showCreditPopup: false };

  normalSaleClick = () => {
    const { cartArray, serials } = this.props;
    let next = true;
    Object.values(cartArray).forEach(item => {
      if (item.hasSerial) {
        const serialItem = Object.values(serials).filter(serial => serial.productId === item.id);
        if (item.qty !== serialItem.length) {
          next = false;
          alert(`Hace falta registrar ${item.qty - serialItem.length} serial(es) del producto ${item.name}.`);
        }
      }
    });
    if (next) {
      this.setState({ showNormalPopup: true });
    }
  };

  creditSaleClick = () => {
    const { cartArray, serials } = this.props;
    let next = true;
    Object.values(cartArray).forEach(item => {
      if (item.hasSerial) {
        const serialItem = Object.values(serials).filter(serial => serial.productId === item.id);
        if (item.qty !== serialItem.length) {
          next = false;
          alert(`Hace falta registrar ${item.qty - serialItem.length} serial(es) del producto ${item.name}.`);
        }
      }
    });
    if (next) {
      this.setState({ showCreditPopup: true });
    }
  };

  createQuote = changeIsLoading => async () => {
    const { summary, cartArray, boxId, options, taxes } = this.props;
    const { netTotal, totalPay } = summary;
    const sale = {};
    sale.transactionTax = [];
    sale.items = [];
    sale.transaction = {};
    Object.assign(sale.items, cartArray);
    sale.items = sale.items.map(item => ({
      ...item,
      productId: item.id,
      price: currency(item.price).divide(1 + item.iva * 0.01).value
    }));
    sale.transaction.total = currency(summary.total).value;
    sale.transaction.taxAmount = currency(summary.taxAmount).value;
    sale.transaction.totalDiscount = currency(summary.discountOnItems).add(summary.discountOnTotal).value;
    sale.transaction.netTotal = currency(netTotal).value;
    sale.transaction.totalPay = currency(totalPay).value;
    sale.transaction.tax = currency(summary.tax, { precision: 3 }).value;
    const taxPercentageString = currency(summary.tax, { precision: 3 }).value + "%";
    sale.transaction.taxPercentageString = taxPercentageString.toString();
    sale.transaction.customerId = summary.customerId;
    sale.transaction.amountPaid = currency(summary.amountPaid).value;
    sale.transaction.saleType = currency(summary.saleType).value;
    sale.transaction.totalDetailsIva = currency(summary.totalDetailsIva).value;
    sale.transaction.comments = summary.comments;
    if (boxId) {
      sale.transaction.boxId = Number(boxId.value);
    }
    sale.transaction.options = options;
    try {
      changeIsLoading(true);
      const queryTransaction = await api.transaction.getTransactionId();
      const transId = Number(queryTransaction.data);
      sale.transaction.transactionId = transId;
      taxes.forEach(tax => {
        const obj = {
          transactionId: transId,
          taxesId: tax.id,
          amount: tax.action === "-" ? tax.amount * -1 : tax.amount,
          percent: tax.percent
        };
        sale.transactionTax.push(obj);
      });
      await api.transaction.saveQuote(sale, transId);
      const report = await api.transaction.getInvoice(transId, "quote");
      saveAs(report.data, `Cotizacion_${transId}.pdf`);
      print(URL.createObjectURL(report.data));
      this.props.emptyCart();
    } catch (err) {
      console.error(err);
      alert("NO SE PUDO CREAR LA COTIZACIÓN.");
      return;
    } finally {
      changeIsLoading(false);
    }
  };

  handleNormalSaleClose = () => {
    this.setState({ showNormalPopup: false });
  };

  handleCreditSaleClose = () => {
    this.setState({ showCreditPopup: false });
  };

  render() {
    const { showNormalPopup, showCreditPopup } = this.state;
    const { classes, summary, extra } = this.props;
    let credit = true;
    let quote = false;
    if (summary.noOfItems === 0) {
      return null;
    }
    if (summary.customer) {
      credit = summary.customer.creditType.toString() === "No permitido" ? true : false;
    }
    if (extra.state === "Anulada") {
      quote = true;
    }

    return (
      <div className={classes.root}>
        {showNormalPopup && <NormalSale open={showNormalPopup} handleClose={this.handleNormalSaleClose} />}
        <Button className={classes.button} variant="contained" color="default" fullWidth onClick={this.normalSaleClick}>
          Venta de Contado
        </Button>
        {showCreditPopup && <CreditSale open={showCreditPopup} handleClose={this.handleCreditSaleClose} />}
        <Button
          className={classes.button}
          variant="contained"
          color="default"
          fullWidth
          onClick={this.creditSaleClick}
          disabled={credit}
        >
          Venta a Credito
        </Button>
        <LoadingContext.Consumer>
          {({ isLoading, changeIsLoading }) => (
            <Button
              className={classes.button}
              variant="contained"
              color="default"
              fullWidth
              onClick={this.createQuote(changeIsLoading)}
              disabled={quote}
            >
              Crear Cotizacion
            </Button>
          )}
        </LoadingContext.Consumer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    summary: state.cart.summary,
    items: state.cart.items,
    taxes: state.cart.taxes,
    cartArray: getCartItemsArraySelector(state),
    options: state.cart.options,
    extra: state.cart.extra,
    serials: state.cart.serials
  };
}
const mapDispatchToProps = { emptyCart };
const component = withStyles(styles)(Footer);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
