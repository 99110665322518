import React from "react";
import PropTypes from "prop-types";
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  makeStyles,
  ListItemText,
  Checkbox,
  Badge,
  Typography
} from "@material-ui/core";
import { Storage, RemoveShoppingCart } from "@material-ui/icons";
import { connect } from "react-redux";
import { updateSelectedProduct } from "../../../actions/productList";
import classNames from "classnames";
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import { COP as currency } from "../../../utils";

const useStyles = makeStyles(theme => ({
  avatar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main
  }
}));

function ProductListView(props) {
  const { productList, keyId, updateSelected, mode, hasNextPage, isNextPageLoading, loadNextPage } = props;
  const selected = productList[keyId].selected;
  const classes = useStyles();
  const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;
  const itemCount = hasNextPage ? props.products.length + 1 : props.products.length;
  const isItemLoaded = index => !hasNextPage || index < props.products.length;

  function unselectOthers(keepId) {
    for (const key in selected) {
      if (key !== keepId) {
        updateSelected(keyId, { id: key }, 0);
      }
    }
  }
  function onClick(product, type) {
    return () => {
      if (type === "check") {
        updateSelected(keyId, product, 0);
        return;
      }
      if (mode === "single") {
        unselectOthers(product.id);
      }
      const selectedProduct = selected[product.id];
      const updatedQty = !selectedProduct ? 1 : selectedProduct.qty + 1;
      updateSelected(keyId, product, updatedQty);
    };
  }

  const Item = ({ index, style }) => {
    if (!isItemLoaded(index)) {
      return (
        <ListItem style={style} button onClick={loadMoreItems}>
          <ListItemText>
            <span style={{ fontSize: "0.95rem" }}>Presiona para cargar más...</span>
          </ListItemText>
        </ListItem>
      );
    }
    const product = props.products[index];
    const selectedProduct = selected[product.id];
    /**
     * Null coalesing no puede llegar lo suficientemente  rapido.
     * Esto valida que el producto tenga stock y que el stock sea mayor a la
     * cantidad seleccionada.
     * product?.stock?.qty > 0 && ((product?.stock?.qty > selected?.qty)||true)
     */
    const hasStock =
      product.stock &&
      product.stock.qty &&
      product.stock.qty > 0 &&
      ((selectedProduct && selectedProduct.qty && product.stock.qty > selectedProduct.qty) || true);
    return (
      <ListItem key={product.id} style={style} button onClick={onClick(product, "btn")}>
        <ListItemAvatar>
          <Badge overlap="circle" badgeContent={selectedProduct ? selectedProduct.qty : 0} color="secondary">
            <Avatar
              className={classNames({
                [classes.avatar]: hasStock
              })}
            >
              {hasStock ? <Storage /> : <RemoveShoppingCart />}
            </Avatar>
          </Badge>
        </ListItemAvatar>
        <ListItemText
          primary={product.name}
          secondary={
            <>
              <Typography component="span">
                {!product.stock || product.stock.qty <= 0 ? "Agotado" : `${product.stock.qty} ${product.unit}`}
              </Typography>
              <br />
              <Typography component="span">{currency(product.sellingPrice).format(true)}</Typography>
            </>
          }
          primaryTypographyProps={{
            color: selectedProduct ? "secondary" : hasStock ? "initial" : "textSecondary"
          }}
        />
        {!!selectedProduct && (
          <Checkbox
            checked={true}
            onChange={onClick(product, "check")}
            inputProps={{
              "aria-label": "quitar producto " + product.name
            }}
          />
        )}
      </ListItem>
    );
  };
  return (
    <AutoSizer>
      {({ width, height }) => (
        <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems}>
          {({ onItemsRendered, ref }) => (
            <List
              height={height}
              itemCount={itemCount}
              itemSize={90}
              onItemsRendered={onItemsRendered}
              ref={ref}
              width={width}
            >
              {Item}
            </List>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
}

ProductListView.propTypes = {
  keyId: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(["single", "multiple"]),
  loadNextPage: PropTypes.func.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  isNextPageLoading: PropTypes.bool.isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      availableStock: PropTypes.string
    })
  ).isRequired
};

function mapStateToProps(state) {
  return { productList: state.productList };
}

const mapDispatchToProps = { updateSelected: updateSelectedProduct };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductListView);
