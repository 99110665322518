import React, { useContext } from "react";
import VirtualSelectContainer from "../../controls/virtual-select/VirtualSelectContainer";
import api from "../../../api";
import { connect } from "react-redux";
import { updateSelectedProduct } from "../../../actions/productList";
import { ProductSelectionContext } from "./ProductPicker";
import { Box, Tooltip, IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddCircle";
import { withRouter } from "react-router";

function ProductSearchView(props) {
  const { key: keyId, mode } = useContext(ProductSelectionContext);
  const selected = props.productList[keyId].selected;
  function unselectOthers(keepId) {
    for (const key in selected) {
      if (key !== keepId) {
        props.updateSelected(keyId, { id: key }, 0);
      }
    }
  }
  const datasource = (val, page, per_page) =>
    api.product.searchByParamsAndGetByPages(
      { q: val, isRental: props.options.isRental ? 1 : undefined },
      page,
      per_page
    );
  const onSelected = val => {
    if (mode === "single") {
      unselectOthers(val.id);
    }
    props.updateSelected(keyId, val, 1);
  };
  const onAddProduct = () => {
    props.history.push("/products/new");
  };
  return (
    <Box display="flex">
      <VirtualSelectContainer
        datasource={datasource}
        onSelected={onSelected}
        itemKey1={"sku"}
        placeholder="Buscar producto por id o nombre..."
      />
      <Tooltip title="Registrar Producto">
        <IconButton aria-label="Registrar Producto" color="primary" onClick={onAddProduct}>
          <AddIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

function mapStateToProps(state) {
  return { productList: state.productList };
}

const mapDispatchToProps = { updateSelected: updateSelectedProduct };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductSearchView));
