import React, { Fragment } from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

// eslint-disable-next-line
const styles = theme => ({
  textField: {
    [theme.breakpoints.up("xs")]: {
      width: 250
    },
    [theme.breakpoints.up("sm")]: {
      width: 300
    },
    [theme.breakpoints.up("md")]: {
      width: 500
    },
    marginRight: 10
  },
  textFieldFormLabel: {
    fontSize: "1.05rem"
  },
  rigthAlign: {
    textAlign: "right"
  },
  fillWidth: {
    width: "100% !important",
    margin: "0 !important"
  },
  alert: {
    color: theme.palette.error.main,
    fontWeight: "bold"
  }
});

const CustomTextField = props => {
  let {
    classes,
    autoComplete,
    className,
    value,
    maxLength,
    FormHelperTextProps,
    helperText,
    fillWidth,
    ...rest
  } = props;
  // para evitar warnings solo se incluye el value si está definido
  if (!value) {
    value = "";
  }
  if (value === 0 || (value && value.value !== "") || value === "") {
    rest = { ...rest, value: value };
  }
  if (value.label) {
    rest = { ...rest, placeholder: value.label, value: "" };
  }
  let ac = "off";
  if (autoComplete !== undefined) ac = autoComplete;

  let clsName = classNames(classes.textField, {
    [classes.fillWidth]: fillWidth
  });
  if (className) {
    clsName = classNames(classes.textField, className);
  }
  let textHelperProp = FormHelperTextProps || {};
  if (maxLength && typeof value.length === "number") {
    const helperClasses = value.length > maxLength ? classNames(classes.rigthAlign, classes.alert) : classes.rigthAlign;
    textHelperProp = { ...textHelperProp, className: helperClasses };
    helperText = `${value.length}/${maxLength}`;
  }
  rest = { ...rest, helperText };

  return (
    <Fragment>
      <TextField
        {...rest}
        InputLabelProps={{
          shrink: true,
          className: classes.textFieldFormLabel
        }}
        FormHelperTextProps={textHelperProp}
        className={clsName}
        margin="normal"
        autoComplete={ac}
      />
    </Fragment>
  );
};

export default withStyles(styles, { withTheme: true })(CustomTextField);
