import { combineReducers } from "redux";
import { USER_LOGGED_OUT } from "../types";

import auth from "./auth";
import productType from "./productType";
import cart from "./cart";
import transaction from "./transaction";
import receiving from "./receiving";
import order from "./orderReceiving";
import productList from "./productList";
import apu from "./apu";
import budget from "./budget";
const appReducer = combineReducers({
  auth,
  productType,
  cart,
  transaction,
  receiving,
  productList,
  apu,
  order,
  budget
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGGED_OUT) {
    return {};
  }
  return appReducer(state, action);
};

export default rootReducer;
