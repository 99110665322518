import React, { useState, useEffect } from "react";
import { COP as currency } from "../../utils";
import { HorizontalBar } from "react-chartjs-2";
import CircularLoader from "../controls/loader/CircularLoader";
import api from "../../api";

const toDataset = dataset => {
  const labels = ["Vendedores"];
  const datasets = dataset.map((row, index) => {
    return {
      label: row.user_name,
      backgroundColor: `rgb(${4 + index * 15},${161 - index * 15},103)`,
      borderColor: `rgb(${4 + index * 15},${161 - index * 15},103)`,
      fill: false,
      data: [row.amount]
    };
  });
  return {
    labels,
    datasets
  };
};

export default function TopSellers() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const getStats = async () => {
    try {
      const data = await api.statistics.getTopSellers();
      setData(toDataset(data.data));
    } catch (err) {
      console.log(err);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getStats();
  }, []);
  const options = {
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          let label = data.datasets[tooltipItem.datasetIndex].label || "";
          if (label) {
            label += ": ";
          }
          label += currency(tooltipItem.xLabel, {
            formatWithSymbol: true
          }).format();
          return label;
        }
      }
    },
    scales: {
      xAxes: [
        {
          ticks: {
            callback: function(value) {
              return "$" + currency(value).format();
            }
          }
        }
      ]
    }
  };
  return (
    <React.Fragment>
      {isLoading ? <CircularLoader isLoading={isLoading} /> : null}
      {data ? <HorizontalBar data={data} options={options} /> : null}
      {error ? <div>No se pudo cargar la estadistica</div> : null}
    </React.Fragment>
  );
}
