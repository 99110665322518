import React, { useState } from "react";
import Container from "./Container";
import { withRouter } from "react-router";
import Prompt from "./dialog/Prompt";
import CircularLoader from "./loader/CircularLoader";
import Message from "./Message";
import * as equal from "fast-deep-equal";

/**
 * Evitamos re renderizaciones al comparar si el objeto actual es igual al anterior
 * @param {*} initialState
 */
function useComparedState(initialState) {
  const [state, setState] = useState(initialState);
  const setComparedPrev = curr => setState(prev => (equal(prev, curr) ? prev : curr));
  return [state, setComparedPrev];
}

function BasicContainer(props) {
  const { id } = props.match.params;
  const [isEdit, setIsEdit] = useComparedState(id ? true : false);

  const [title, setTitle] = useComparedState({
    edit: "Editar",
    register: "Registrar"
  });
  const [showMessageDialog, setShowMessageDialog] = useComparedState(false);
  const [isLoading, setIsLoading] = useComparedState(false);
  const [message, setMessage] = useComparedState({
    message: null,
    isError: false,
    showMessage: false
  });
  const onMessageCloseClick = () => {
    setMessage({ showMessage: false, message: "", isError: false });
  };
  const onMessageDialogCloseClick = () => {
    setShowMessageDialog(false);
    props.history.goBack();
  };
  const showError = error => {
    try {
      const errors = error.errors
        .map(err => {
          return Object.values(err.constraints).join("\n");
        })
        .join("\n");
      setMessage({ showMessage: true, message: errors, isError: true });
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setMessage({ showMessage: true, message: error.message, isError: true });
      setIsLoading(false);
    }
  };

  const createOnSubmit = (createNew, update, validate, form, setErrors) => async e => {
    e.preventDefault();
    const errors = validate();
    if (Object.keys(errors).length > 0) {
      alert("Hemos encontrado algunos errores en el formulario.");
      console.log(errors);
      setErrors(errors);
      return;
    }
    try {
      if (isEdit === false) {
        await createNew(form);
      } else {
        await update(form);
      }
    } catch (error) {
      console.log(error);
      showError(error);
    }
  };

  const passingProps = {
    id,
    isEdit,
    setIsEdit,
    setTitle,
    setShowMessageDialog,
    setIsLoading,
    setMessage,
    showError,
    createOnSubmit,
    match: props.match,
    location: props.location,
    history: props.history
  };
  return (
    <Container title={isEdit ? title.edit : title.register}>
      <Prompt
        message="El elemento fue registrado Exitosamente."
        open={showMessageDialog}
        handleClose={onMessageDialogCloseClick}
      />
      <CircularLoader isLoading={isLoading} />
      <Message
        title="Mensaje"
        message={message.message}
        show={message.showMessage}
        isError={message.isError}
        onCloseClick={onMessageCloseClick}
        autoClose={!message.isError}
      />
      {<props.children {...passingProps} />}
    </Container>
  );
}
const ContainerWithRouter = withRouter(BasicContainer);

export default Component => props => {
  return <ContainerWithRouter {...props}>{Component}</ContainerWithRouter>;
};
