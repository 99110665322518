import { INITIALIZE_SELECTED, REMOVE_SELECTED, UPDATE_SELECTED_PRODUCT, EMPTY_SELECTED } from "../types";

export const initializeSelected = key => ({
  type: INITIALIZE_SELECTED,
  data: key
});

export const removeSelected = key => ({
  type: REMOVE_SELECTED,
  data: key
});

export const updateSelectedProduct = (key, product, newQty = 1) => ({
  type: UPDATE_SELECTED_PRODUCT,
  data: {
    key,
    product,
    qty: newQty
  }
});

export const emptySelected = () => ({ type: EMPTY_SELECTED });
