import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CustomTextField from "../textfields/CustomTextField";
import SelectWrapped from "./SelectWrapped";
import styles from "./styles";
import classNames from "classnames";

const Dropdown = props => {
  const { classes, datasource, fitWidth, className, ...rest } = props;
  return (
    <CustomTextField
      style={{ margin: "10px 0px 20px  0px" }}
      className={classNames(fitWidth ? classes.fitWidth : null, className)}
      {...rest}
      fullWidth
      // value={this.state.selectedValue}
      // onChange={this.handleChange}
      name="react-select-chip-label"
      InputLabelProps={{
        shrink: true
      }}
      InputProps={{
        inputComponent: SelectWrapped,
        disableUnderline: true,
        inputProps: {
          classes,
          instanceId: "react-select-chip-label",
          id: "react-select-chip-label",
          simpleValue: true,
          options: datasource
        }
      }}
    />
  );
};

Dropdown.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Dropdown);
