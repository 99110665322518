import React from "react";
import { withStyles } from "@material-ui/core/styles";
import FooterTableCell from "./FooterTableCell";
import FooterTableRow from "./FooterTableRow";
import { COP as currency } from "../../../../../utils";

const styles = () => ({
  root: { backgroundColor: "#e5e5e5" },
});

const TotalBillRow = ({ classes, netTotal, totalPay }) => (
  <FooterTableRow className={classes.root}>
    <FooterTableCell style={{ paddingLeft: "15px" }}>Total a Facturar</FooterTableCell>
    <FooterTableCell align="right">$ {currency(netTotal).format()}</FooterTableCell>
    <FooterTableCell />
    <FooterTableCell style={{ paddingLeft: "15px", fontSize: "18px" }}>Total Pagar</FooterTableCell>
    <FooterTableCell align="right" style={{ fontSize: "18px" }}>
      $ {currency(totalPay).format()}
    </FooterTableCell>
  </FooterTableRow>
);

export default withStyles(styles)(TotalBillRow);
