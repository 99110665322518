import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ProductSelectionContext } from "../../products/ProductPicker/ProductPicker";
import { Dialog, DialogContent, DialogTitle, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import ItemForm from "./ItemForm";

function EditItemModal(props) {
  const { item, open, onClose, onSubmit } = props;
  const { key } = useContext(ProductSelectionContext);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [productList, setProductList] = useState({
    [key]: {
      selected: {
        [item.product.id]: {
          product: item.product,
          qty: item.qty
        }
      }
    }
  });
  useEffect(() => {
    setProductList({
      [key]: {
        selected: {
          [item.product.id]: {
            product: item.product,
            qty: item.qty
          }
        }
      }
    });
  }, [item, setProductList, key]);
  function updateSelected(_, __, qty) {
    setProductList({
      [key]: {
        selected: {
          [item.product.id]: {
            product: item.product,
            qty: qty
          }
        }
      }
    });
  }
  const startWith = {
    performance: item.performance,
    benefits: item.benefits,
    weight: item.unitWeight
  };
  const formSubmit = data => {
    onSubmit(data);
    onClose();
  };
  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={onClose} aria-labelledby="edit-item-title">
      <DialogTitle id="edit-item-title">{"Editar " + item.name}</DialogTitle>
      <DialogContent>
        <ItemForm
          productList={productList}
          updateSelected={updateSelected}
          onSubmit={formSubmit}
          startWithState={startWith}
          onCancel={onClose}
        />
      </DialogContent>
    </Dialog>
  );
}

EditItemModal.propTypes = {
  item: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default EditItemModal;
