import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  price: { float: "right", marginLeft: 20, color: "#20295a", fontWeight: "bold", fontSize: "15px" },
  title: { fontSize: "15px" }
});

const FooterPriceLabel = ({ price, title, classes }) => (
  <Fragment>
    <span className={classes.title}>{title}</span>
    <span className={classes.price}>
      {"$"}
      {price}
    </span>
  </Fragment>
);

export default withStyles(styles)(FooterPriceLabel);
