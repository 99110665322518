import React, { useState, useEffect } from "react";
import { HorizontalBar } from "react-chartjs-2";
import CircularLoader from "../controls/loader/CircularLoader";
import api from "../../api";

const toDataset = dataset => {
  const labels = ["Productos"];
  let background = 1;
  const datasets = dataset.map((row, index) => {
    switch (index) {
      case 0:
        background = "rgb(0,153,255)";
        break;
      case 1:
        background = "rgb(102,204,204)";
        break;
      case 2:
        background = "rgb(204,102,204)";
        break;
      case 3:
        background = "rgb(255,153,0)";
        break;
      case 4:
        background = "rgb(0,204,102)";
        break;
      default:
        background = "rgb(204,204,204)";
    }    
    return {      
      label: `${row.product_id} - Cant. ${row.amount}` ,
      backgroundColor: `${background}`,
      borderColor: `${background}`,
      fill: false,
      data: [row.amount]
    }; 
  });
  return {
    labels,
    datasets
  };
};

export default function TopProducts() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const getStats = async () => {
    try {
      const data = await api.statistics.getTopProducts();
      setData(toDataset(data.data));
    } catch (err) {
      console.log(err);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getStats();
  }, []);
  return (
    <React.Fragment>
      {isLoading ? <CircularLoader isLoading={isLoading} /> : null}
      {data ? <HorizontalBar data={data} /> : null}
      {error ? <div>No se pudo cargar la estadistica</div> : null}
    </React.Fragment>
  );
}
