import React, { Fragment } from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import NumberFormatCustom from "./NumberFormatCustom";
import { Button } from "@material-ui/core";

// eslint-disable-next-line
//  TODO remove this and make it common if textfield and this has same styles.
const styles = theme => ({
  textField: {
    [theme.breakpoints.up("xs")]: {
      width: 250
    },
    [theme.breakpoints.up("sm")]: {
      width: 300
    },
    [theme.breakpoints.up("md")]: {
      width: 500
    },
    marginRight: 10
  },
  textFieldFormLabel: {
    fontSize: "1.05rem"
  },
  Button: {
    marginTop: 11,
    height: "2.2rem",
    fontSize: "1.05rem",
    [theme.breakpoints.up("xs")]: {
      width: 0.5
    },
    [theme.breakpoints.up("sm")]: {
      width: 1
    },
    [theme.breakpoints.up("md")]: {
      width: 1.5
    }
  }
});

const DiscountTextField = props => {
  let { classes, autoComplete, className, typeDiscount, ...rest } = props;
  let ac = "off";
  if (autoComplete !== undefined) ac = autoComplete;
  if (typeDiscount === undefined) typeDiscount = "$";

  let clsName = classes.textField;
  if (className) {
    clsName = classNames(classes.textField, className);
  }
  const falsed = false;

  return (
    <Fragment>
      <div>
        <br />
        <Button onClick={props.onClick} className={classes.Button} color="primary">
          {typeDiscount}
        </Button>
      </div>
      <TextField
        {...rest}
        InputLabelProps={{
          shrink: true,
          className: classes.textFieldFormLabel
        }}
        InputProps={{
          inputComponent: NumberFormatCustom
        }}
        className={clsName}
        margin="normal"
        autoComplete={ac}
        onClick={() => falsed}
      />
      <br />
    </Fragment>
  );
};

export default withStyles(styles, { withTheme: true })(DiscountTextField);
