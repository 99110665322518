import React, { Component } from "react";
import { sleep, COP as currency } from "../../../../utils";
import FormDialog from "../../../controls/dialog/FormDialog";
import CustomTextField from "../../../controls/textfields/CustomTextField";
import NumberTextField from "../../../controls/textfields/NumberTextField";
import DiscountTextField from "../../../controls/textfields/DiscountTextField";
import VirtualSelect from "../../../controls/virtual-select/VirtualSelect";
import Footer from "./Footer";
import api from "../../../../api";

class EditCartItem extends Component {
  state = {
    errors: {},
    searchText: "",
    nameSearchText: "",
    serialsOptions: [],
    showMessage: false,
    hasNextPage: false,
    isNextPageLoading: false,
    nextPageUrl: null,
    loadNextPage: this.onNextPage,
    reset: true
  };

  onChange = e => {
    this.setState({ errors: { ...this.state.errors, [e.target.name]: "" } });
    this.props.onChange(e);
  };

  onChangeSellingPrice = (e, val) => {
    this.props.onChangeSellingPriceEditCartItem(e, val);
  };

  onClick = () => {
    this.props.onClick();
  };

  onSave = () => {
    const { item } = this.props;
    const { serials } = this.props.cartObj;
    if (currency(item.discount).value > currency(item.price).value) {
      this.setState({
        errors: { ...this.state.errors, discount: "El precio del descuento es menor al precio de costo del producto." }
      });
      return;
    }
    if (!item.qty || item.qty <= 0) {
      this.setState({ errors: { ...this.state.errors, qty: "La cantidad debe ser mayor que 0." } });
      return;
    }
    this.setState({ errors: {} });
    this.props.onSave(serials);
  };

  onCancel = () => {
    this.setState({ errors: {} });
    this.props.onCancel();
  };

  handleKeyDown = event => {
    if (event.key === "F11") {
      console.log(event.key);
    }
  };

  isExactMatch = (searchText, apiResults) => {
    if (apiResults.length === 0 || apiResults.length > 1) {
      return false;
    }
    const i = apiResults[0];
    return i.id === searchText || i.name === searchText;
  };

  onMessageCloseClick = () => {
    this.setState({ showMessage: false });
  };

  onSelected = selectedSerial => {
    const { serials } = this.props.cartObj;
    const { item } = this.props;
    this.setState({ searchText: "", nameSearchText: "", errors: { ...this.state.errors, serial: "" } });
    const oldSerials = Object.values(serials).filter(serial => serial.productId === item.id);
    if (oldSerials.length < item.qty) {
      this.props.onChangeSerials(selectedSerial);
    }
  };

  onDeleteSerialItem = row => {
    this.props.onDeleteSerialItem(row);
  };



  onChangeSerial = async e => {
    const { item } = this.props;
    const searchText = e.target.value;
    if (!searchText || searchText.length < 3) {
      this.setState({ serialsOptions: [], searchText });
      return;
    }
    this.setState({ searchText });
    try {
      const res = await api.productSerial.searchByParamsAndGetByPages(
        item.id,
        { serial$like: `%${searchText}%`, productId$eq: item.id, state$eq: "Stock" },
        this.page,
        this.perPage
      );
      if (this.state.showMessage && res.data) {
        this.onMessageCloseClick();
      }
      if (this.isExactMatch(searchText, res.data)) {
        await sleep(300);
        res.data[0].serialId = res.data[0].id;
        res.data[0].name = res.data[0].serial;
        this.onSelected(res.data[0]);
      } else {
        for (const key in res.data) {
          res.data[key].serialId = res.data[key].id;
          res.data[key].id = res.data[key].serial;
          res.data[key].name = res.data[key].extra ? res.data[key].extra.description : "";
        }
        this.setState({ serialsOptions: res.data });
      }
    } catch (error) {
      console.log(error);
      this.setState({ showMessage: true });
    }
  };

  render() {
    const { errors, searchText, serialsOptions, hasNextPage, isNextPageLoading, loadNextPage } = this.state;
    const { open, item, typeDiscount } = this.props;
    const { serials } = this.props.cartObj;
    typeDiscount.value = typeDiscount.type === "%" && typeDiscount.value > 100 ? 100 : typeDiscount.value;

    return (
      <FormDialog onSave={this.onSave} onCancel={this.onCancel} open={open}>
        <CustomTextField
          error={!!errors.sku}
          name="sku"
          value={item.sku}
          label="Identificador del producto"
          onChange={this.onChange}
          disabled={true}
        />
        <CustomTextField
          error={!!errors.name}
          name="name"
          value={item.name}
          label="Nombre"
          onChange={this.onChange}
          disabled={true}
        />
        <CustomTextField
          error={!!errors.description}
          name="description"
          value={item.description}
          label="Descripción"
          maxRows="4"
          multiline
          onChange={this.onChange}
          disabled={true}
        />
        <div style={{ display: "flex" }}>
          <NumberTextField
            error={!!errors.qty}
            name="qty"
            value={item.qty}
            label="Cantidad"
            onChange={this.onChange}
            helperText={errors.qty}
          />
          <NumberTextField
            error={!!errors.price}
            name="price"
            value={item.price}
            label="Precio Unidad"
            helperText={errors.price}
            onKeyUp={(e) => this.onChangeSellingPrice(item, e.target.value)}
          />
          <DiscountTextField
            error={!!errors.typeDiscount}
            name="discount"
            value={typeDiscount.value}
            typeDiscount={typeDiscount.type}
            label="Descuento Unidad"
            onChange={this.onChange}
            onClick={this.onClick}
            helperText={errors.typeDiscount}
          />
        </div>
        {item.hasSerial ? (
          <VirtualSelect
            searchText={searchText}
            width="100%"
            placeholder={"Busque por el serial del producto"}
            onKeyDown={this.handleKeyDown}
            onSelected={this.onSelected}
            items={serialsOptions}
            onChange={this.onChangeSerial}
            hasNextPage={hasNextPage}
            isNextPageLoading={isNextPageLoading}
            loadNextPage={loadNextPage}
            helperText={"Los seriales deben ser la misma cantidad vendida."}
          />
        ) : (
          ""
        )}
        <Footer item={item} serials={serials} onDeleteSerialItem={this.onDeleteSerialItem} />
      </FormDialog>
    );
  }
}

export default EditCartItem;
