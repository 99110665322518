import React, { useState, useEffect, useCallback } from "react";
import { Bar } from "react-chartjs-2";
import { COP as currency } from "../../utils";
import moment from "moment";
import "moment/locale/es";
import Dropdown from "../controls/dropdown/Dropdown";
import CircularLoader from "../controls/loader/CircularLoader";
import api from "../../api";

const toDataset = dataset => {
  const labels = dataset.map(row =>
    moment(row.openDate, "YYYY-MM-DD")
      .locale("es")
      .format("MMMM DD")
  );
  const normalAmount = dataset.map(row => row.normalAmount);
  const creditAmount = dataset.map(row => row.creditAmount);
  const expenseAmount = dataset.map(row => row.expenseAmount);
  const annulledAmount = dataset.map(row => row.annulledAmount);
  return {
    labels,
    datasets: [
      {
        label: "Valor venta normal",
        backgroundColor: "rgb(77, 209, 142)",
        borderColor: "rgb(77, 209, 142)",
        fill: false,
        data: normalAmount
      },
      {
        label: "Valor venta credito",
        backgroundColor: "rgb(59, 94, 189)",
        borderColor: "rgb(59, 94, 189)",
        fill: false,
        data: creditAmount
      },
      {
        label: "Valor venta anulada",
        backgroundColor: "rgb(255, 70, 11)",
        borderColor: "rgb(255, 70, 11)",
        fill: false,
        data: annulledAmount
      },
      {
        label: "Valor gasto",
        backgroundColor: "rgb(255, 151, 58)",
        borderColor: "rgb(255, 151, 58)",
        fill: false,
        data: expenseAmount
      }
    ]
  };
};

export default function DailySalesByBox() {
  const [boxId, setBoxId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const [boxIds, setBoxIds] = useState([]);
  useEffect(() => {
    async function getBoxes() {
      const res = await api.cashRegister.fetchAll();
      const boxes = res.data.map(d => ({ value: d.id, label: d.name }));
      setBoxIds(boxes);
      setBoxId(boxes[0]);
    }
    getBoxes();
  }, []);

  const getStats = useCallback(async () => {
    try {
      if (boxId.value) {
        const data = await api.statistics.getDailySalesByBox(boxId.value);
        setData(toDataset([data.data]));
      }
    } catch (err) {
      console.log(err);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  }, [boxId]);
  useEffect(() => {
    getStats();
  }, [boxId, getStats]);
  const options = {
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          let label = data.datasets[tooltipItem.datasetIndex].label || "";
          if (label) {
            label += ": ";
          }
          label += currency(tooltipItem.yLabel, { formatWithSymbol: true }).format();
          return label;
        }
      }
    },
    scales: {
      yAxes: [
        {
          ticks: {
            callback: function(value) {
              return "$" + currency(value).format();
            }
          }
        }
      ]
    }
  };

  return (
    <React.Fragment>
      {isLoading ? <CircularLoader isLoading={isLoading} /> : null}
      {data ? <Bar data={data} options={options} /> : null}
      {error ? <div>No se pudo cargar la estadistica</div> : null}
      <Dropdown
        fitWidth={true}
        name="poduct"
        value={boxId}
        datasource={boxIds}
        onChange={val => setBoxId(val)}
        placeholder=""
        label="Caja"
      />
    </React.Fragment>
  );
}
