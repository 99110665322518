/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from "react";
import FooterTableCell from "./FooterTableCell";
import FooterTableRow from "./FooterTableRow";
import LinkButton from "../controls/LinkButton";
import DiscountPopup from "./DiscountPopup";
import TaxPopup from "./TaxPopup";
import { COP as currency } from "../../../../../utils";

class TaxDiscountRow extends Component {
  state = { showDiscount: false, showTax: false };

  toggleDiscount = () => {
    this.setState({ showDiscount: !this.state.showDiscount });
  };

  toggleTax = () => {
    this.setState({ showTax: !this.state.showTax });
  };

  render() {
    const { cartArray, summary } = this.props;
    const discountText = `${currency(summary.discountOnItems).format("$")}`;
    const { taxAmount, tax, totalDetailsIva, totalDetailsImpoconsumo } = summary;
    const { showDiscount, showTax } = this.state;
    /* const ivaPrice = `${currency(taxAmount)
      .add(totalDetailsIva)
      .format("$")}`; */
    const taxes = totalDetailsIva + totalDetailsImpoconsumo;
    const totaltaxes = `${currency(taxAmount)
      .add(taxes)
      .format("$")}`;
    return (
      <Fragment>
        <DiscountPopup summary={summary} cartArray={cartArray} close={this.toggleDiscount} open={showDiscount} />
        {<TaxPopup close={this.toggleTax} open={showTax} />}
        <FooterTableRow>
          <FooterTableCell align="right" style={{ width: "150px" }}>
            <LinkButton text="Descuento" onClick={this.toggleDiscount} />
          </FooterTableCell>
          <FooterTableCell align="right">
            {discountText}
            {` (${summary.discountPercent.toFixed(3)}%)`}
          </FooterTableCell>
          <FooterTableCell align="right" />
          <FooterTableCell style={{ paddingLeft: "15px" }}>
            <LinkButton
              text={`Impuestos (${Number(tax) === Number(0) ? tax : Number(tax).toFixed(3)}%)`}
              onClick={this.toggleTax}
            />
          </FooterTableCell>
          <FooterTableCell align="right">{ totaltaxes }</FooterTableCell>
        </FooterTableRow>
      </Fragment>
    );
  }
}

export default TaxDiscountRow;
