import React, { useState, useEffect } from "react";
import { COP as currency } from "../../utils";
import { Bar } from "react-chartjs-2";
import CircularLoader from "../controls/loader/CircularLoader";
import api from "../../api";
import moment from "moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import "moment/locale/es";
import MomentUtils from "@date-io/moment";

const orderedExpenseColors = [
  "#FF0000",
  "#F9630A",
  "#F6800F",
  "#F4A514",
  "#F1C819",
  "#EEE91E",
  "#D0EB23",
  "#AFE927",
  "#90E62C",
  "#74E431",
  "#5AE135",
  "#42DF3A"
];

const orderedEarningColors = [
  "#14CF00",
  "#38D004",
  "#5CD007",
  "#7ED00B",
  "#A0D10E",
  "#C0D112",
  "#D2C415",
  "#D2A719",
  "#D38B1C",
  "#D45524",
  "#D43B27",
  "#D52B33"
];

const orderedCostColors = [
  "#1A16FC",
  "#1A3BFA",
  "#1F61F7",
  "#27A8F3",
  "#2CC8F0",
  "#30E6EE",
  "#3097EE",
  "#34A9EC",
  "#38B9EA",
  "#3CC9E8",
  "#40D7E5",
  "#44E3E2",
  "#48E1D2"
];

/**
 *
 * @param {[string, {[key:string]:number}][]} data Debe estar ordenada por mes
 * @param {string} key
 * @param {string[]} colorList
 * @param {string} label
 */
function dataToDataset(data, key, colorList, label) {
  const colorMap = new Map();
  [...data] // Copia para evitar mutación del orden original
    .sort(([_, value1], [_1, value2]) => (value1[key] < value2[key] ? 1 : -1))
    .forEach(([month], idx) => {
      colorMap.set(month, colorList[idx]);
    });
  return {
    label: label,
    backgroundColor: data.map(([month]) => colorMap.get(month)),
    data: data.map(([_, value]) => value[key])
  };
}

const toDataset = dataset => {
  const orderedByMonth = Object.entries(dataset).sort(([month1], [month2]) => (+month1 > +month2 ? 1 : -1));
  const labels = orderedByMonth.map(([month]) =>
    moment()
      .set("month", month - 1)
      .locale("es")
      .format("MMMM")
  );
  const datasets = [
    dataToDataset(orderedByMonth, "expenses", orderedExpenseColors, "Gastos"),
    dataToDataset(orderedByMonth, "costs", orderedCostColors, "Costos"),
    dataToDataset(orderedByMonth, "earnings", orderedEarningColors, "Ventas")
  ];
  return {
    labels,
    datasets
  };
};

export default function MontlyExpenses() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const [selectedDate, handleDateChange] = useState(moment(new Date()));

  useEffect(() => {
    const getStats = async () => {
      try {
        const data = await api.statistics.getMontlyExpenses(selectedDate.get("year"));
        setData(toDataset(data.data));
      } catch (err) {
        console.log(err);
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };
    getStats();
  }, [selectedDate]);
  const options = {
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          let label = data.datasets[tooltipItem.datasetIndex].label || "";
          if (label) {
            label += ": ";
          }
          label += currency(tooltipItem.yLabel, {
            formatWithSymbol: true
          }).format(true);
          return label;
        }
      }
    },
    scales: {
      yAxes: [
        {
          ticks: {
            callback: function(value) {
              return "$" + currency(value).format();
            }
          }
        }
      ]
    }
  };
  return (
    <React.Fragment>
      {isLoading ? <CircularLoader isLoading={isLoading} /> : null}
      {data ? <Bar data={data} options={options} /> : null}
      {error ? <div>No se pudo cargar la estadistica</div> : null}
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker fullWidth views={["year"]} label="Año" value={selectedDate} onChange={handleDateChange} />
      </MuiPickersUtilsProvider>
    </React.Fragment>
  );
}
