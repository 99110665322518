import React, { Fragment } from "react";
import { withStyles, Button } from "@material-ui/core";

// eslint-disable-next-line
const styles = theme => ({
  button: {
    margin: "30px 10px 30px 0px"
  }
});

const SubmitCancel = ({ classes, onCancelClick }) => (
  <Fragment>
    <Button
      type="submit"
      size="small"
      className={classes.button}
      variant="contained"
      color="primary"
    >
      Enviar
    </Button>

    <Button
      size="small"
      className={classes.button}
      variant="contained"
      color="default"
      onClick={onCancelClick}
    >
      Cancelar
    </Button>
  </Fragment>
);

export default withStyles(styles, { withTheme: true })(SubmitCancel);
