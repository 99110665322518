export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";

export const SET_CONVEYOR = "SET_CONVEYOR";

export const LOAD_RESOLUTION = "LOAD_RESOLUTION";

export const LOAD_CASH_REGISTER = "LOAD_CASH_REGISTER";

export const LOAD_ROLES = "LOAD_ROLES";

export const LOAD_PRODUCT_TYPE = "LOAD_PRODUCT_TYPE";

export const ADD_ITEM_TO_CART = "ADD_ITEM_TO_CART";
export const UPDATE_CART_ITEM = "UPDATE_CART_ITEM";
export const UPDATE_CART_SERIAL = "UPDATE_CART_SERIAL";
export const REMOVE_SERIAL_ITEM = "REMOVE_SERIAL_ITEM";
export const REMOVE_ITEM_FROM_CART = "REMOVE_ITEM_FROM_CART";
export const TYPE_DISCOUNT = "TYPE_DISCOUNT";
export const EMPTY_CART = "EMPTY_CART";

export const UPDATE_DISCOUNT_ON_TOTAL = "UPDATE_DISCOUNT_ON_TOTAL";
export const UPDATE_DISCOUNT_ON_ITEMS = "UPDATE_DISCOUNT_ON_ITEMS";
export const UPDATE_TAX = "UPDATE_TAX";
export const ON_TAX_CHANGE = "ON_TAX_CHANGE";
export const HANDLE_CHANGE = "HANDLE_CHANGE";
export const ON_TAXES = "ON_TAXES";

export const HYDRATE_CART_FROM_REMOTE = "HYDRATE_CART_FROM_REMOTE";
export const SET_CUSTOMER = "SET_CUSTOMER";
export const SET_TRANSACTION_OPTIONS = "SET_TRANSACTION_OPTIONS";
export const ON_CHANGE_BOX = "ON_CHANGE_BOX";
export const SET_COMMENTS = "SET_COMMENTS";

export const INIT_TRANSACTION = "INIT_TRANSACTION";
export const CANCEL_TRANSACTION = "CANCEL_TRANSACTION";
export const SET_TRANSACTION_EDIT_ANNULLED = "SET_TRANSACTION_EDIT_ANNULLED";
export const SET_TRANSACTION = "SET_TRANSACTION";

export const SET_HEADER_ORDER = "SET_HEADER_ORDER";
export const UPDATE_HEADER_ORDER = "UPDATE_HEADER_ORDER";

export const SET_HEADER = "SET_HEADER";
export const UPDATE_HEADER = "UPDATE_HEADER";
export const ADD_ITEM_TO_LIST = "ADD_ITEM_TO_LIST";
export const ADD_ITEM_SERIALS = "ADD_ITEM_SERIALS";
export const EMPTY_LIST = "EMPTY_LIST";
export const REMOVE_ITEM_FROM_LIST = "REMOVE_ITEM_FROM_LIST";
export const CLEAR_LIST = "CLEAR_LIST";
export const HYDRATE_LIST = "HYDRATE_LIST";
export const HYDRATE_ORDER = "HYDRATE_ORDER";

export const INITIALIZE_SELECTED = "INITIALIZE_SELECTED";
export const UPDATE_SELECTED_PRODUCT = "UPDATE_SELECTED_PRODUCT";
export const REMOVE_SELECTED = "REMOVE_SELECTED";
export const EMPTY_SELECTED = "EMPTY_SELECTED";

export const ADD_ITEM_TO_APU = "ADD_ITEM_TO_APU";
export const REMOVE_ITEM_FROM_APU = "REMOVE_ITEM_FROM_APU";
export const EMPTY_APU = "EMPTY_APU";
export const SET_APU_ATTRIBUTES = "SET_APU_ATTRIBUTES";
export const SET_SELLING_PRICE = "SET_SELLING_PRICE";
export const HYDRATE_APU_FROM_REMOTE = "HYDRATE_APU_FROM_REMOTE";

export const ADD_TOP_LEVEL_SECTION_BUDGET = "ADD_TOP_LEVEL_SECTION_BUDGET";
export const ADD_ITEM_TO_BUDGET = "ADD_ITEM_TO_BUDGET";
export const REMOVE_ITEM_FROM_BUDGET = "REMOVE_ITEM_FROM_BUDGET";
export const EMPTY_BUDGET = "EMPTY_BUDGET";
export const SET_BUDGET_ATTRIBUTES = "SET_BUDGET_ATTRIBUTES";
export const UPDATE_ITEM_BUDGET = "UPDATE_ITEM_BUDGET";
export const HYDRATE_BUDGET_FROM_REMOTE = "HYDRATE_BUDGET_FROM_REMOTE";
export const ADD_EXTRA_COST_TO_BUDGET = "ADD_EXTRA_COST_TO_BUDGET";
export const UPDATE_EXTRA_COST_FROM_BUDGET = "UPDATE_EXTRA_COST_FROM_BUDGET";
export const DELETE_EXTRA_COST_FROM_BUDGET = "DELETE_EXTRA_COST_FROM_BUDGET";
