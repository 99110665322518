import { useState, useEffect } from "react";

export function useError(errorCheckerCb, deps = []) {
  const [errors, setErrors] = useState(errorCheckerCb());
  useEffect(() => {
    function updateErrors() {
      const errors = errorCheckerCb(deps);
      const hasErrors = Object.keys(errors).length > 0;
      if (hasErrors) {
        setErrors(errors);
      } else {
        setErrors({});
      }
      return hasErrors;
    }
    updateErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
  return [errors, setErrors, Object.keys(errors).length > 0];
}
