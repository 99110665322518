
const productTransformer = {
    fetchByPages: (response) => {
        const data = response.data.items.map(item => {
            const costAttrib = item.custom_attributes.find(attribute => attribute.attribute_code === "costo")
            const cost = (costAttrib && costAttrib.value) ? Number(costAttrib.value) : 0
            return {
                id: item.sku,
                name: item.name,
                description: item.custom_attributes.find(attribute =>
                    attribute.attribute_code === "description"
                ).value || " - ",
                costPrice: cost,
                sellingPrice: item.price,
                productTypeId: "stat"
            }
        })
        return { ...response, data };
    },
    fetchById: (response) => {
        const costAttrib = response.data.custom_attributes.find(attribute => attribute.attribute_code === "costo")
        const cost = (costAttrib && costAttrib.value) ? Number(costAttrib.value) : 0
        const data = {
            id: response.data.sku,
            name: response.data.name,
            description: response.data.custom_attributes.find(attribute =>
                attribute.attribute_code === "description"
            ).value || " - ",
            costPrice: cost,
            sellingPrice: response.data.price,
            productTypeId: "stat"
        }
        return { ...response, data };
    },
    fetchAll: (response) => {
        const data = response.data.items.map(item => {
            const costAttrib = item.custom_attributes.find(attribute => attribute.attribute_code === "costo")
            const cost = (costAttrib && costAttrib.value) ? Number(costAttrib.value) : 0
            return {
                id: item.sku,
                name: item.name,
                description: item.custom_attributes.find(attribute =>
                    attribute.attribute_code === "description"
                ).value || " - ",
                costPrice: cost,
                sellingPrice: item.price,
                productTypeId: "stat"
            }
        })
        return { ...response, data };
    },
    searchByIdAndGetByPages: (response) => {
        const data = response.data.items.map(item => {
            const costAttrib = item.custom_attributes.find(attribute => attribute.attribute_code === "costo")
            const cost = (costAttrib && costAttrib.value) ? Number(costAttrib.value) : 0
            return {
                id: item.sku,
                name: item.name,
                description: item.custom_attributes.find(attribute =>
                    attribute.attribute_code === "description"
                ).value || " - ",
                costPrice: cost,
                sellingPrice: item.price,
                productTypeId: "stat"
            }
        })
        return { ...response, data };
    },

}


export default productTransformer