import React, { Component } from "react";
import { VariableSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";
import { Paper, List as NoDataList, ListItem, ListItemText, withStyles } from "@material-ui/core";
import CustomTextField from "../textfields/CustomTextField";

// eslint-disable-next-line
const styles = theme => ({
  root: { position: "relative", width: "100%" },
  ddItem: { position: "absolute" }
});

class VirtualSelect extends Component {
  state = { hideSuggestions: true };
  optionsFocused = {};
  textFieldFocused = false;

  shouldHideSuggestions() {
    this.wait();
    if (this.textFieldFocused) {
      return;
    }
    for (const key in this.optionsFocused) {
      if (this.optionsFocused[key]) {
        return;
      }
    }
    this.setState({ hideSuggestions: true });
  }

  renderOptions = () => {
    const { hasNextPage, items, isNextPageLoading, loadNextPage } = this.props;
    const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;
    const onSelected = val => {
      this.props.onSelected(val);
      this.setState({ hideSuggestions: true });
    };
    const itemCount = hasNextPage ? items.length + 1 : items.length;
    const isItemLoaded = index => !hasNextPage || index < items.length;
    const onFocus = item => {
      this.optionsFocused = { ...this.optionsFocused, [item.id]: true };
      this.shouldHideSuggestions();
    };
    const onBlur = item => {
      this.optionsFocused = { ...this.optionsFocused, [item.id]: false };
      this.shouldHideSuggestions();
    };
    const getItemSize = index => {
      if (!items[index]) return 50;
      const totalLenght = (items[index][this.props.itemKey1] + items[index][this.props.itemKey2]).length;
      return 50 * Math.ceil(totalLenght / 120) || 50;
    };
    const Item = ({ index, style }) => {
      if (!isItemLoaded(index)) {
        return (
          <ListItem
            style={style}
            button
            onClick={loadMoreItems}
            onFocus={() => onFocus({ id: "noItem" })}
            onBlur={() => onBlur({ id: "noItem" })}
          >
            <ListItemText>
              <span style={{ fontSize: "0.95rem" }}>Presiona para cargar más...</span>
            </ListItemText>
          </ListItem>
        );
      }

      return (
        <ListItem
          style={style}
          button
          onClick={() => onSelected(items[index])}
          onFocus={() => onFocus(items[index])}
          onBlur={() => onBlur(items[index])}
        >
          <ListItemText>
            <span style={{ fontSize: "0.95rem", wordBreak: "break-all" }}>
              <strong>({items[index][this.props.itemKey1]})</strong> {items[index][this.props.itemKey2]}
            </span>
          </ListItemText>
        </ListItem>
      );
    };
    return (
      <AutoSizer disableHeight>
        {({ width }) => (
          <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems}>
            {({ onItemsRendered, ref }) => (
              <List
                height={150}
                itemCount={itemCount}
                itemSize={getItemSize}
                onItemsRendered={onItemsRendered}
                ref={ref}
                width={width}
              >
                {Item}
              </List>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    );
  };

  renderNoData = () => {
    const { classes, width } = this.props;

    return (
      <Paper elevation={4} className={classes.ddItem} style={{ width, zIndex: 1000 }}>
        <NoDataList component="nav">
          <ListItem>
            <ListItemText>
              <span style={{ fontSize: "0.95rem" }}>No hay registros disponibles</span>
            </ListItemText>
          </ListItem>
        </NoDataList>
      </Paper>
    );
  };

  renderSuggestions = () => {
    const { hideSuggestions } = this.state;
    const { classes, width, items, searchText } = this.props;
    if (hideSuggestions === true || !searchText || searchText.length < 3) {
      return null;
    }
    if (!items || items.length === 0) {
      return this.renderNoData();
    }
    return (
      <Paper elevation={4} className={classes.ddItem} style={{ width, zIndex: 1000 }}>
        {this.renderOptions()}
      </Paper>
    );
  };

  onFocus = () => {
    this.textFieldFocused = true;
    this.setState({ hideSuggestions: false });
  };

  wait = async () => new Promise(resolve => setTimeout(resolve, 10));

  onBlur = async () => {
    this.textFieldFocused = false;
    await this.wait();
    this.shouldHideSuggestions();
  };

  render() {
    const { width, onChange, searchText, onKeyDown, placeholder, classes, disabled, error, helperText } = this.props;

    return (
      <div className={classes.root}>
        <CustomTextField
          onKeyDown={onKeyDown}
          value={searchText}
          onChange={onChange}
          placeholder={placeholder ? placeholder : "Busque por el nombre del producto"}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          style={{ width }}
          disabled={disabled}
          error={!!error}
          helperText={helperText}
        />
        {this.renderSuggestions()}
      </div>
    );
  }
}

VirtualSelect.defaultProps = {
  itemKey1: "id",
  itemKey2: "name"
};

export default withStyles(styles, { withTheme: true })(VirtualSelect);
