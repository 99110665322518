import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import api from "../../../../api";
import axios from "../../../../api/axiosClient";
import { sleep, getPaginationInfo } from "../../../../utils";
import { addItemToCart, updateCartItem } from "../../../../actions/cart";
import Message from "../../../controls/Message";
import AutoSuggestWithApiDatasource from "../../../controls/autoSuggest/AutoSuggestWithApiDatasource";
import VirtualSelect from "../../../controls/virtual-select/VirtualSelect";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/AddCircle";
import { debounce } from "lodash";
/**
 * TODO: Refactorizar los dos search en componentes separados.
 */
class SearchBox extends Component {
  page = 1;
  perPage = 15;
  constructor(props) {
    super(props);
    this.state = {
      showMessage: false,
      searchText: "",
      nameSearchText: "",
      products: [],
      items: [],
      hasNextPage: false,
      isNextPageLoading: false,
      nextPageUrl: null,
      loadNextPage: this.onNextPage,
    };
  }

  onNextPage = () => {
    if (this.state.isNextPageLoading || !this.state.nextPageUrl) {
      return;
    }
    this.setState({ isNextPageLoading: true }, async () => {
      try {
        const res = await axios.get(this.state.nextPageUrl);
        if (this.state.showMessage && res.data) {
          this.onMessageCloseClick();
        }
        const pageInfo = getPaginationInfo(res.headers.link);
        if (pageInfo.currentPage === pageInfo.next) {
          this.setState({
            items: [...this.state.items, ...res.data],
            hasNextPage: false,
            nextPageUrl: null,
            isNextPageLoading: false,
          });
        } else {
          this.setState({
            items: [...this.state.items, ...res.data],
            hasNextPage: true,
            nextPageUrl: pageInfo.next,
            isNextPageLoading: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.setState({ showMessage: true, isNextPageLoading: false });
      }
    });
  };

  onItemsChange = (e) => {
    const searchText = e.target.value;
    if (!searchText || searchText.length < 3) {
      this.setState({ items: [], nameSearchText: searchText });
      return;
    }
    this.setState({ nameSearchText: searchText, isNextPageLoading: true }, async () => {
      try {
        const res = await api.product.searchByParamsAndGetByPages({ name$like: searchText }, this.page, this.perPage);
        if (this.state.showMessage && res.data) {
          this.onMessageCloseClick();
        }
        if (this.isExactMatch(searchText, res.data)) {
          await sleep(300);
          this.onSelected(res.data[0]);
        } else {
          const pageInfo = getPaginationInfo(res.headers.link);
          if (pageInfo.currentPage === pageInfo.next) {
            this.setState({ items: res.data, hasNextPage: false, isNextPageLoading: false, nextPageUrl: null });
          } else {
            this.setState({ items: res.data, hasNextPage: true, isNextPageLoading: false, nextPageUrl: pageInfo.next });
          }
        }
      } catch (error) {
        console.log(error);
        this.setState({ showMessage: true, isNextPageLoading: false });
      }
    });
  };

  onCreateNewClick = () => {
    this.props.history.push("products/new");
  };

  onChange = async (e) => {
    const searchText = e.target.value;
    if (!searchText || searchText.length < 3) {
      this.setState({ products: [], searchText });
      return;
    }
    this.setState({ searchText });
    try {
      const res = await api.product.searchByIdAndGetByPages(searchText);
      if (this.state.showMessage && res.data) {
        this.onMessageCloseClick();
      }
      if (this.isExact(searchText, res.data)) {
        await sleep(300);
        this.onSelected(res.data[0]);
      } else {
        this.setState({ products: res.data });
      }
    } catch (error) {
      console.log(error);
      this.setState({ showMessage: true });
    }
  };

  isExactMatch = (searchText, apiResults) => {
    if (apiResults.length === 0 || apiResults.length > 1) {
      return false;
    }
    const i = apiResults[0];
    return i.id === searchText || i.name === searchText;
  };

  isExact = (searchText, apiResults) => {
    if (apiResults.length === 0 || apiResults.length > 1) {
      return false;
    }
    const i = apiResults[0];
    return i.id === searchText || apiResults.length === 1;
  };

  onSelected = debounce((selectedItem) => {
    this.setState({ searchText: "", nameSearchText: "" });
    this.updateCart(selectedItem);
  }, 100);

  updateCart = (item) => {
    const { cart } = this.props;
    const existingItem = cart[item.id];
    if (existingItem) {
      const obj = this.constructCartObjForUpdate(existingItem);
      this.props.updateCartItem(obj);
    } else {
      const obj = this.constructCartObjForAddNew(item);
      this.props.addItemToCart(obj);
    }
  };

  constructCartObjForUpdate = (obj) => {
    const clone = {};
    Object.assign(clone, obj);
    clone.qty += 1;
    return clone;
  };

  constructCartObjForAddNew = (obj) => {
    const clone = {};
    Object.assign(clone, obj);
    clone.qty = 1;
    clone.price = clone.sellingPrice;
    clone.discount = 0;
    clone.sellingPrice = clone.price;
    clone.totalPrice = clone.sellingPrice;
    return clone;
  };

  onMessageCloseClick = () => {
    this.setState({ showMessage: false });
  };

  handleKeyDown = (event) => {
    if (event.key === "F11") {
      console.log(event.key);
    }
  };

  render() {
    const {
      products,
      items,
      searchText,
      nameSearchText,
      showMessage,
      hasNextPage,
      isNextPageLoading,
      loadNextPage,
    } = this.state;

    return (
      <Fragment>
        <Message
          style={{ width: 430 }}
          title="Mensaje"
          message="Algo salio mal. Por favor Intente mas tarde"
          show={showMessage}
          isError={true}
          onCloseClick={this.onMessageCloseClick}
        />
        <div style={{ display: "flex", flexFlow: "row" }}>
          <AutoSuggestWithApiDatasource
            searchText={searchText}
            width="100%"
            onKeyDown={this.handleKeyDown}
            onLeave={this.onLeave}
            onChange={this.onChange}
            onSelected={this.onSelected}
            datasource={products}
          />
          <Tooltip title="Registrar Producto">
            <IconButton aria-label="Registrar Producto" color="primary" onClick={this.onCreateNewClick}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </div>
        <VirtualSelect
          searchText={nameSearchText}
          width="100%"
          onKeyDown={this.handleKeyDown}
          onLeave={this.onLeave}
          onSelected={this.onSelected}
          items={items}
          onChange={this.onItemsChange}
          hasNextPage={hasNextPage}
          isNextPageLoading={isNextPageLoading}
          loadNextPage={loadNextPage}
          itemKey1={"sku"}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ cart }) => ({
  cart: cart.items,
});

export default withRouter(connect(mapStateToProps, { updateCartItem, addItemToCart })(SearchBox));
