import React, { Component } from "react";
import CartTable from "./cartTable";
import { Paper } from "@material-ui/core";
import SearchBox from "./searchbox";

class PosSection extends Component {
  state = {};

  render() {
    return (
      <Paper
        style={{
          overflow: "auto",
          display: "block",
          width: "100%",
          height: "80vh"
        }}
      >
        <div style={{ padding: "10px" }}>
          <SearchBox />
          <CartTable />
        </div>
      </Paper>
    );
  }
}

export default PosSection;
