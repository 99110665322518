import update from "immutability-helper";
import { USER_LOGGED_IN } from "../types";

let b64DecodeUnicode = str =>
  decodeURIComponent(
    Array.prototype.map.call(atob(str), c => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)).join("")
  );

let parseJwt = token =>
  JSON.parse(
    b64DecodeUnicode(
      token
        .split(".")[1]
        .replace("-", "+")
        .replace("_", "/")
    )
  );

export default function user(state = {}, action = {}) {
  switch (action.type) {
    case USER_LOGGED_IN:
      return update(state, {
        tokens: { $set: action.authInfo },
        user: { $set: parseJwt(action.authInfo.authToken) },
        hasEcommerce: { $set: action.authInfo.hasEcommerce }
      });
    default:
      return state;
  }
}
