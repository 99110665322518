import React from "react";
import { withStyles } from "@material-ui/core/";
import PropTypes from "prop-types";
import classNames from "classnames";
import axios from "axios";
import * as moment from "moment";
import currency from "currency.js";
import * as Functional from "./functional";

const styled = Component => (style, options) => {
  function StyledComponent(props) {
    const { classes, className, ...other } = props;
    return <Component className={classNames(classes.root, className)} {...other} />;
  }
  StyledComponent.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    // eslint-disable-next-line react/require-default-props
    className: PropTypes.string
  };
  const styles = typeof style === "function" ? theme => ({ root: style(theme) }) : { root: style };
  return withStyles(styles, options)(StyledComponent);
};

const setAuthorizationHeader = (token = null) => {
  if (token) {
    sessionStorage.setItem("token", token);
    axios.defaults.headers.common.authorization = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common.authorization;
  }
};

const isValidDDMMYY = date => moment(date, "DD-MM-YY", true).isValid();

const invertShowHide = val => {
  if (val === "show") return "hide";
  return "show";
};

const isValidDateChange = idate => {
  let date = idate;
  if ((date.length === 3 && date[2] !== "-") || (date.length === 6 && date[5] !== "-") || date.length > 8) return false;
  date = date.replace(/-/g, "");
  // eslint-disable-next-line
  if (isNaN(date)) return false;
  if (idate.length === 8 && !isValidDDMMYY(idate)) return false;
  return true;
};

const isValueExists = (object, keysToIgnore = []) => {
  const keys = Object.keys(object);
  const errors = {};
  const fn = val => {
    if (keysToIgnore.length === 0) return false;
    for (let i = 0; i < keysToIgnore.length; i++) {
      const element = keysToIgnore[i];
      if (element === val) return true;
    }
    return false;
  };
  for (let idx = 0; idx < keys.length; idx++) {
    const key = keys[idx];
    const value = object[key];
    if ((!fn(key) && !value && value !== 0) || (!fn(key) && value === "")) {
      errors[key] = "Campo requerido";
    }
  }
  return errors;
};

const isValidEmail = mail => {
  // eslint-disable-next-line no-useless-escape
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  }
  return false;
};

const getPaginationInfo = linkHeaderString => {
  if (!linkHeaderString) {
    return {};
  }
  const links = linkHeaderString.split(",");
  if (links.length === 0) {
    return {};
  }
  const paginationInfo = links
    .map(link => {
      const section = link.split(";");
      if (section.length !== 2) {
        throw new Error("section could not be split on ';'");
      }
      const url = section[0].replace(/<(.*)>/, "$1").trim();
      const name = section[1].replace(/rel="(.*)"/, "$1").trim();
      return { [name]: url };
    })
    .reduce((a, b) => ({ ...a, ...b }), {});
  return paginationInfo;
};

const sleep = async ms => new Promise(resolve => setTimeout(resolve, ms));

const COP = value => currency(value, { precision: 0, symbol: "$", separator: "," });
const deepEqual = function(x, y) {
  if (x === y) {
    return true;
  } else if (typeof x == "object" && x != null && typeof y == "object" && y != null) {
    if (Object.keys(x).length !== Object.keys(y).length) return false;

    for (var prop in x) {
      if (y.hasOwnProperty(prop)) {
        if (!deepEqual(x[prop], y[prop])) return false;
      } else return false;
    }

    return true;
  } else return false;
};

/**
 * Obtiene las n primeras letras de un texto.
 * @param {string} text
 * @param {number} numberOfLetters Puede ser null para que tome todas las primeras letras
 */
function getFirstLetters(text, numberOfLetters = null) {
  return text
    .split(" ")
    .map(word => word.charAt(0))
    .reduce((prev, curr, index) => {
      if (numberOfLetters && index > numberOfLetters - 1) return prev;
      if (!curr) return prev;
      return prev + curr;
    });
}
function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
export {
  styled,
  setAuthorizationHeader,
  isValidDDMMYY,
  isValidDateChange,
  invertShowHide,
  isValueExists,
  isValidEmail,
  getPaginationInfo,
  sleep,
  COP,
  deepEqual,
  getFirstLetters,
  Functional,
  uuidv4
};
