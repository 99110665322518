import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { COP as currency } from "../../utils";
import moment from "moment";
import "moment/locale/es";
import CircularLoader from "../controls/loader/CircularLoader";
import api from "../../api";

const toDataset = dataset => {
  const labels = dataset.map(row =>
    moment(row.date, "YYYY-MM-DD")
      .locale("es")
      .format("MMMM DD")
  );
  const sales = dataset.map(row => row.earnings);
  const expenses = dataset.map(row => row.expenses);
  return {
    labels,
    datasets: [
      {
        label: "Ventas",
        backgroundColor: "rgb(54, 162, 235)",
        borderColor: "rgb(54, 162, 235)",
        fill: false,
        data: sales
      },
      {
        label: "Gastos",
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgb(255, 99, 132)",
        fill: false,
        data: expenses
      }
    ]
  };
};

export default function EarningsVsExpenses() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const getStats = async () => {
    try {
      const data = await api.statistics.getEarningsVsExpenses();
      setData(toDataset(data.data));
    } catch (err) {
      console.log(err);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getStats();
  }, []);
  const options = {
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          const label = data.datasets[tooltipItem.datasetIndex].label || "";
          const value = currency(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index], {
            formatWithSymbol: true
          }).format(true);
          const revenue = currency(data.datasets[0].data[tooltipItem.index])
            .subtract(currency(data.datasets[1].data[tooltipItem.index]))
            .format(true);
          return `${label ? label + ": " : ""}${value} \n
          Ganancia Neta: ${revenue}
          `;
        }
      }
    },
    scales: {
      yAxes: [
        {
          ticks: {
            callback: function(value) {
              return "$" + currency(value).format();
            }
          }
        }
      ]
    }
  };
  return (
    <React.Fragment>
      {isLoading ? <CircularLoader isLoading={isLoading} /> : null}
      {data ? <Line data={data} options={options} /> : null}
      {error ? <div>No se pudo cargar la estadistica</div> : null}
    </React.Fragment>
  );
}
