import React, { Fragment } from "react";
import { COP as currency } from "../../../../utils";
import FooterPriceLabel from "./FooterPriceLabel";
import { List, ListItem, ListItemText, ListItemSecondaryAction, withStyles, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

const styles = () => ({
  root: { margin: "20px 10px 10px 0px", float: "right" },
  serial: { margin: "0px 0px 0px 0px", float: "left", padding: 0 }
});

const Footer = ({ item, classes, serials, onDeleteSerialItem }) => {
  const serialsList = serials ? Object.values(serials).filter(serial => serial.productId === item.id) : [];

  const PrecioNeto = currency(item.totalPrice)
    .add(item.discountTotal)
    .subtract(item.ivaPrice)
    .subtract(item.impoconsumoPrice)
    .toString();
  const discountTotal = currency(item.discountTotal).toString();
  const ivaPrice = currency(item.ivaPrice).toString();
  const impoconsumoPrice = currency(item.impoconsumoPrice).toString();  
  const totalPrice = currency(item.totalPrice).toString();
  const discountPorcent = discountTotal !== "" ? (Number(discountTotal) * 100) / Number(totalPrice) : 0;

  return (
    <Grid container>
      <Grid container item xs={4} justifyContent="flex-start" className={classes.serial} direction="column">
        {item.hasSerial ? (
          <Fragment>
            <Typography
              variant="subtitle2"
              className={classes.serial}
            >{`Seriales (${serialsList.length}/${item.qty})`}</Typography>
            <List>
              {serialsList.map(n => (
                <ListItem button key={n.serialId}>
                  <ListItemText primary={n.serial} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={() => onDeleteSerialItem(n)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Fragment>
        ) : (
          ""
        )}
      </Grid>
      <Grid container item xs={8} justifyContent="flex-end">
        <div className={classes.root}>
          <FooterPriceLabel price={PrecioNeto} title="Precio Neto" />
          <br />
          <br />
          <FooterPriceLabel price={discountTotal} title={`Descuento Total (${discountPorcent.toFixed(3)}%)`} />
          <br />
          <br />
          <FooterPriceLabel price={ivaPrice} title={"IVA " + item.ivaPercentageString} />
          <br />
          <FooterPriceLabel price={impoconsumoPrice} title={"Impoconsumo " + item.impoconsumoPercentageString} />
          <br />
          <br />
          <FooterPriceLabel price={totalPrice} title="Precio de venta" />
        </div>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Footer);
