import React, { Component } from "react";
import { connect } from "react-redux";
import { Paper } from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import EditCartItem from "../editCartItem/EditCartItem";
import YesNo from "../../../controls/dialog/YesNo";
import { COP as currency } from "../../../../utils";
import * as cartActions from "../../../../actions/cart";
import * as transactionActions from "../../../../actions/transaction";
import { getCartItemsArraySelector } from "../../../../selectors";
import CartHeader from "./cartHeader";
import CartBody from "./cartBody";
import CartFooter from "./cartFooter";

const styles = (theme) => ({
  root: { width: "100%", marginTop: theme.spacing(3) },
});

class CartTable extends Component {
  initialCartItem = {
    id: "",
    name: "",
    description: "",
    qty: "",
    price: "",
    discount: "",
    discountTotal: "",
    costPrice: "",
    iva: "",
    ivaPrice: "",
    ivaPercentageString: "",
    impoconsumo: "",
    impoconsumoPrice: "",
    impoconsumoPercentageString: "",
    stock: {},
    discountRule: {},
  };

  typeDiscount = { type: "$", value: 0 };

  state = {
    showConfirmDeleteDialog: false,
    showEditDialog: false,
    itemToEdit: this.initialCartItem,
    typeDiscount: this.typeDiscount,
  };

  findMaxDicount = (clone, type, inputValue) => {
    const { user } = this.props;
    let value;
    let discount;
    if (type === "%") {
      value = Number(inputValue) > 100 ? 100 : inputValue;
      if (user.role !== "admin") {
        value = value > clone.maxPercentDiscount ? clone.maxPercentDiscount : value;
      }
      discount = (value * clone.price) / 100;
    } else {
      value = Number(inputValue) > Number(clone.price) ? Number(clone.price) : Number(inputValue);
      if (user.role !== "admin") {
        value = value > clone.maxAmountDiscount / clone.qty ? clone.maxAmountDiscount / clone.qty : value;
      }
      discount = Number(value);
    }
    return { value: value, discount: discount };
  };

  onChangeSerials = (e) => {
    this.props.updateCartSerial(e);
  };

  onDeleteSerialItem = (row) => {
    this.props.removeSerialItem(row);
  };
  // onChange event for edit cart item form.
  onChange = (e) => {
    const { itemToEdit } = this.state;
    const clone = {};
    Object.assign(clone, itemToEdit);
    const { typeDiscount } = this.state;
    let { qty, discount } = clone;
    if (e.target.name === "discount") {
      const maxDiscount = this.findMaxDicount(clone, typeDiscount.type, e.target.value);
      typeDiscount.value = maxDiscount.value;
      discount = maxDiscount.discount;
    } else {
      qty = e.target.value;
    }
    let totalPrice = currency(clone.price).subtract(discount).value;
    totalPrice = currency(totalPrice).multiply(qty).value;    
    const sellingPrice = currency(totalPrice).divide(1 + Number(clone.iva) / 100).value;
    const ivaPrice = currency(totalPrice).subtract(sellingPrice).value;
    const impoconsumoPrice = currency(sellingPrice).multiply(Number(clone.impoconsumo)  / 100).value;
    clone.qty = qty === "" ? "" : Number(qty);
    clone.discount = discount;
    clone.discountTotal = currency(clone.discount).multiply(clone.qty).value;
    clone.sellingPrice = currency(sellingPrice).value;
    clone.ivaPrice = currency(ivaPrice).value;
    clone.impoconsumoPrice =  currency(impoconsumoPrice).value;
    clone.totalPrice = currency(totalPrice).value;
    this.setState({ itemToEdit: clone });
  };
  // Empty cart dialog
  onConfirmDeleteClick = () => {
    this.props.emptyCart();
    this.props.cancelTransaction();
    this.setState({ showConfirmDeleteDialog: false });
  };

  onDeleteAllClick = () => {
    this.setState({ showConfirmDeleteDialog: true });
  };

  onDeleteCartItemClick = (row) => {
    this.props.removeItemFromCart(row);
  };

  
  onClick = () => {
    const { itemToEdit } = this.state;
    const clone = {};
    Object.assign(clone, itemToEdit);
    let { typeDiscount } = this.state;
    const total = currency(clone.price).add(currency(clone.price).multiply(clone.iva / 100)).value;
    if (typeDiscount.type === "$") {
      typeDiscount.type = "%";
      typeDiscount.value = (typeDiscount.value * 100) / total;
      typeDiscount.value = Number(typeDiscount.value) > 100 ? 100 : typeDiscount.value;
    } else {
      typeDiscount.type = "$";
      typeDiscount.value = typeDiscount.value > 100 ? 100 : typeDiscount.value;
      typeDiscount.value = (typeDiscount.value * total) / 100;
    }
    this.setState({ typeDiscount: typeDiscount, itemToEdit: clone });
  };

  onCancelConfirmDeleteClick = () => {
    this.setState({ showConfirmDeleteDialog: false });
  };
  // Edit cart item dialog
  onProductItemClick = (itemToEdit) => {
    const typeDiscount = {};
    typeDiscount.type = "$";
    typeDiscount.value = itemToEdit.discount;
    this.setState({ itemToEdit, typeDiscount, showEditDialog: true });
  };

  onCancelEditItemClick = () => {
    this.setState({ showEditDialog: false, itemToEdit: this.initialCartItem, typeDiscount: this.typeDiscount });
  };

  onSaveItemClick = () => {
    const { itemToEdit } = this.state;
    this.props.updateCartItem(itemToEdit);
    this.setState({ showEditDialog: false, itemToEdit: this.initialCartItem, typeDiscount: this.typeDiscount });
  };

  incrementItemClick = (row) => {
    const newObj = { ...row, qty: Number(row.qty) + 1 };
    this.setState({ itemToEdit: newObj });
    this.props.updateCartItem(newObj);
  };

  decrementItemClick = (row) => {
    if (row.qty - 1 > 0) {
      const newObj = { ...row, qty: row.qty - 1 };
      this.setState({ itemToEdit: newObj });
      this.props.updateCartItem(newObj);
    }
  };
  
  onChangeQuantity = (row, value) => {
    const newQty = Number(value.replace(/[^0-9.-]+/g,"")) ;
    const newObj = { ...row, qty: newQty };
    this.setState({ itemToEdit: newObj });
    this.props.updateCartItem(newObj);
  };

  onChangeSellingPriceEditCartItem = (row, value) => {
    const newPrice = Number(value.replace(/[^0-9.-]+/g,"")) ;
    const tax = 1 + Number(row.iva) / 100;    
    const quantity = row.qty;
    const newIvaPrice = currency(newPrice).subtract(newPrice/tax).value;
    const newSellPrice = currency(newPrice).divide(1 + Number(row.iva) / 100).value;
    const newObjs = { ...row, sellingPrice: newSellPrice, price: newPrice, ivaPrice : newIvaPrice * quantity, totalPrice:  newPrice *  quantity };
    this.setState({ itemToEdit: newObjs });
    this.props.updateCartItem(newObjs);
  };

  render() {
    const { showConfirmDeleteDialog, showEditDialog, itemToEdit, typeDiscount } = this.state;
    const { classes, cartObj, cartArray } = this.props;

    return (
      <Paper className={classes.root}>
        <CartFooter summary={cartObj.summary} cartArray={cartArray} extra={cartObj.extra} />
        <YesNo
          open={showConfirmDeleteDialog}
          message="¿Esta seguro de vaciar el carrito?"
          onOk={this.onConfirmDeleteClick}
          onCancel={this.onCancelConfirmDeleteClick}
        />
        <EditCartItem
          cartObj={cartObj}
          open={showEditDialog}
          item={itemToEdit}
          onSave={this.onSaveItemClick}
          onCancel={this.onCancelEditItemClick}
          onChange={this.onChange}
          onChangeSerials={this.onChangeSerials}
          onDeleteSerialItem={this.onDeleteSerialItem}
          onChangeSellingPriceEditCartItem={this.onChangeSellingPriceEditCartItem}
          typeDiscount={typeDiscount}
          onClick={this.onClick}          
        />
        <Table>
          <CartHeader isCartEmpty={cartArray.length === 0} onDeleteAll={this.onDeleteAllClick} />
          <CartBody
            cartArray={cartArray}
            onDeleteCartItem={this.onDeleteCartItemClick}
            onProductItemSelect={this.onProductItemClick}
            incrementItem={this.incrementItemClick}
            decrementItem={this.decrementItemClick}
            onChangeQuantity={this.onChangeQuantity}
          />
        </Table>
      </Paper>
    );
  }
}

function mapStateToProps(state) {
  return { cartArray: getCartItemsArraySelector(state), cartObj: state.cart, user: state.auth.user };
}

const mapDispatchToProps = {
  emptyCart: cartActions.emptyCart,
  cancelTransaction: transactionActions.cancelTransaction,
  removeItemFromCart: cartActions.removeItemFromCart,
  updateCartItem: cartActions.updateCartItem,
  updateCartSerial: cartActions.updateCartSerial,
  removeSerialItem: cartActions.removeSerialItem,
};

const component = withStyles(styles, { withTheme: true })(CartTable);

export default connect(mapStateToProps, mapDispatchToProps)(component);
