import React from "react";
import Container from "../controls/Container";
import SuccessMessage from "../controls/messages/SuccessMessage";

const NotFound = () => (
  <Container>
    <SuccessMessage
      title="Pagina no encontrada"
      message="No responde al llamado de la pagina."
    />
  </Container>
);

export default NotFound;
