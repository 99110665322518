import { INIT_TRANSACTION, CANCEL_TRANSACTION, SET_TRANSACTION, SET_TRANSACTION_EDIT_ANNULLED } from "../types";

const initialState = {
  id: null,
  status: "Inicial"
};
export default function transaction(state = initialState, action = {}) {
  switch (action.type) {
    case INIT_TRANSACTION:
      return { ...state, id: action.data, status: "Inicial" };

    case SET_TRANSACTION:
      return { ...state, id: action.data.id, status: action.data.status };

    case SET_TRANSACTION_EDIT_ANNULLED:
      return { ...state, id: action.data.id, status: action.data.status, idOld: action.data.idOld };

    case CANCEL_TRANSACTION:
      return {};

    default:
      return state;
  }
}
