import React from "react";
import { Button } from "@material-ui/core";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";

const FormDialog = props => (
  <Dialog open={props.open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
    <DialogTitle id="alert-dialog-title">
      {props.title ? props.title : "Editar Producto"}
      <br />
      <span style={{ fontSize: "12px", color: "#3f50b5" }}>{props.subtitle ? `(${props.subtitle})` : ""}</span>
    </DialogTitle>
    <DialogContent>{props.children}</DialogContent>
    <DialogActions>
      {props.onRegister ? (
        <Button
          onClick={props.onRegister}
          style={{ display: "flex", flexFlow: "row", alignItems: "left", color: "#363636" }}
          autoFocus
        >
          Registrar
        </Button>
      ) : (
          ""
        )}
      <Button onClick={props.onCancel} color="secondary" autoFocus>
        Cancelar
      </Button>
      <Button onClick={props.onSave} disabled={props.saveDisabled} color="primary" autoFocus>
        Guardar
      </Button>
    </DialogActions>
  </Dialog>
);

export default FormDialog;
