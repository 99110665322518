import React from "react";
import { KeyboardDatePicker, MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import DateRange from "@material-ui/icons/DateRange";
import { withStyles } from "@material-ui/core/";
import classNames from "classnames";

import MomentUtils from "@date-io/moment";

// eslint-disable-next-line
const styles = theme => ({
  textField: {
    [theme.breakpoints.up("xs")]: {
      width: 250
    },
    [theme.breakpoints.up("sm")]: {
      width: 300
    },
    [theme.breakpoints.up("md")]: {
      width: 500
    },
    marginRight: 10,
    marginTop: 20
  },
  fillWidth: {
    marginRight: 10,
    marginTop: 20,
    width: "100%"
  },
  textFieldFormLabel: {
    fontSize: "1.05rem"
  }
});

const DatePicker = props => <KeyboardDatePicker placeholder="dd/mm/yyyy" format="DD/MM/YYYY" {...props} />;

const DateTimePicker = props => (
  <KeyboardDateTimePicker placeholder="dd/mm/yyyy hh:mm" format="DD/MM/YYYY HH:mm" {...props} />
);

const CustomDatePicker = props => {
  const { handleDateChange, classes, name, fillWidth, withTime, ...rest } = props;
  const onDateChange = date => {
    const target = {};
    target.name = name;
    target.value = date && date._d ? date._d : null;
    handleDateChange({ target });
  };

  const Picker = withTime ? DateTimePicker : DatePicker;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Picker
        //mask={value => (value ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/] : [])}
        invalidDateMessage="La fecha no es valida"
        leftArrowIcon={<KeyboardArrowLeft />}
        rightArrowIcon={<KeyboardArrowRight />}
        InputLabelProps={{
          shrink: true,
          className: classes.textFieldFormLabel
        }}
        keyboardIcon={<DateRange />}
        {...rest}
        className={classNames({ [classes.textField]: !fillWidth, [classes.fillWidth]: fillWidth }, props.className)}
        onChange={onDateChange}
        animateYearScrolling={false}
      />
    </MuiPickersUtilsProvider>
  );
};

export default withStyles(styles, { withTheme: true })(CustomDatePicker);
