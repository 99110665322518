import {
  INIT_TRANSACTION,
  CANCEL_TRANSACTION,
  SET_TRANSACTION,
  SET_TRANSACTION_EDIT_ANNULLED,
  UPDATE_CART_SERIAL
} from "../types";

export const initTransaction = data => ({
  type: INIT_TRANSACTION,
  data
});

export const updateCartSerial = serial => ({
  type: UPDATE_CART_SERIAL,
  serial
});

export const editAnnulledTransaction = data => ({
  type: SET_TRANSACTION_EDIT_ANNULLED,
  data
});

export const setTransaction = data => ({
  type: SET_TRANSACTION,
  data
});

export const cancelTransaction = data => ({
  type: CANCEL_TRANSACTION,
  data
});
