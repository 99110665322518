import {
  ADD_ITEM_TO_CART,
  REMOVE_ITEM_FROM_CART,
  UPDATE_CART_ITEM,
  TYPE_DISCOUNT,
  EMPTY_CART,
  UPDATE_DISCOUNT_ON_TOTAL,
  UPDATE_DISCOUNT_ON_ITEMS,
  UPDATE_TAX,
  ON_TAX_CHANGE,
  HANDLE_CHANGE,
  ON_TAXES,
  HYDRATE_CART_FROM_REMOTE,
  SET_CUSTOMER,
  ON_CHANGE_BOX,
  SET_COMMENTS,
  SET_TRANSACTION_OPTIONS,
  UPDATE_CART_SERIAL,
  REMOVE_SERIAL_ITEM,
} from "../types";
// eslint-disable-next-line
export const emptyCart = () => ({
  type: EMPTY_CART,
});

export const addItemToCart = (data) => ({
  type: ADD_ITEM_TO_CART,
  data,
});

export const updateCartItem = (data) => ({
  type: UPDATE_CART_ITEM,
  data,
});

export const updateCartSerial = (serial) => ({
  type: UPDATE_CART_SERIAL,
  serial,
});

export const removeSerialItem = (serial) => ({
  type: REMOVE_SERIAL_ITEM,
  serial,
});

export const typeDiscount = (data) => ({
  type: TYPE_DISCOUNT,
  data,
});

export const removeItemFromCart = (data) => ({
  type: REMOVE_ITEM_FROM_CART,
  data,
});

export const updateDiscountOnItems = (data) => ({
  type: UPDATE_DISCOUNT_ON_ITEMS,
  data,
});

export const updateDiscountOnTotal = (data) => ({
  type: UPDATE_DISCOUNT_ON_TOTAL,
  data,
});

export const updateTax = (data) => ({
  type: UPDATE_TAX,
  data,
});

export const onTaxChange = (data) => ({
  type: ON_TAX_CHANGE,
  data,
});

export const handleChange = (data) => ({
  type: HANDLE_CHANGE,
  data,
});

export const onTaxes = (data) => ({
  type: ON_TAXES,
  data,
});

export const hydrateCart = (data) => ({
  type: HYDRATE_CART_FROM_REMOTE,
  data,
});

export const setCustomer = (data) => ({
  type: SET_CUSTOMER,
  data,
});

export const onChangeBox = (data) => ({
  type: ON_CHANGE_BOX,
  data,
});

export const setTransactionOptions = (data) => ({
  type: SET_TRANSACTION_OPTIONS,
  data,
});

export const setComment = (data) => ({
  type: SET_COMMENTS,
  data,
});
