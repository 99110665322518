import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import CircularLoader from "../../../../controls/loader/CircularLoader";
import FormDialog from "../../../../controls/dialog/FormDialog";
import NumberTextField from "../../../../controls/textfields/NumberTextField";
import { updateTax, onTaxChange, handleChange, onTaxes } from "../../../../../actions/cart";
import api from "../../../../../api";
import { Switch, withStyles } from "@material-ui/core/";
import Tooltip from "@material-ui/core/Tooltip";

const styles = () => ({
  switch: { marginLeft: "-20px" },
  label: { fontSize: "13px", fontWeight: 400 },
  root: { display: "flex", flexFlow: "row" }
});
class TaxPopup extends Component {
  initialState = { error: "", isLoading: false };

  state = { ...this.initialState };

  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      const res = await api.taxes.fetchAll();
      let { allTaxes } = this.props;
      const stateToUpdate = {};
      stateToUpdate.taxDetails = res.data.map(d => {
        const data = allTaxes.find(tax => tax.id === d.id);
        return { ...d, checked: data ? data.checked : false, value: data ? data.value : d.value };
      });
      this.setState({ isLoading: false });
      this.props.onTaxes(stateToUpdate.taxDetails);
    } catch (error) {
      this.showError(error);
    }
  }

  onCreateNewClick = async () => {
    this.props.history.push("taxes/new");
  };

  onTaxChange = e => {
    this.props.onTaxChange(e);
  };

  handleChange = row => {
    this.props.handleChange(row);
  };

  onSave = () => {
    const { allTaxes } = this.props;
    if (allTaxes === "") {
      this.setState({ error: "Ingrese un impuesto valido" });
      return;
    }
    this.props.updateTax(allTaxes);
    this.props.close();
  };

  onCancel = () => {
    this.props.close();
  };

  renderOptions = () => {
    const { classes } = this.props;
    let { allTaxes } = this.props;
    const { error } = this.state;
    if (!allTaxes) {
      allTaxes = [];
    }

    return allTaxes.map(n => (
      <div key={n.id} style={{ display: "flex", flexFlow: "row", alignItems: "center" }}>
        <NumberTextField
          style={{ width: "200px" }}
          error={!!error}
          name={n.id}
          value={Number(n.value)}
          label={n.name}
          onChange={this.onTaxChange}
          helperText={`${n.type} a ${n.action === "-" ? "restar" : "sumar"} al total de la factura`}
        />
        <div>
          <Tooltip title={n.checked ? "Aplicar" : "No aplicar"}>
            <Switch
              className={classes.switch}
              checked={n.checked}
              onChange={() => this.handleChange(n)}
              value="checkedB"
              color="primary"
            />
          </Tooltip>
        </div>
      </div>
    ));
  };

  render() {
    const { open } = this.props;
    const { isLoading } = this.state;
    return (
      <FormDialog
        onSave={this.onSave}
        onCancel={this.onCancel}
        onRegister={this.onCreateNewClick}
        open={open}
        title="Impuestos"
      >
        <CircularLoader isLoading={isLoading} />
        {this.renderOptions()}
      </FormDialog>
    );
  }
}

function mapStateToProps(state) {
  return { allTaxes: state.cart.allTaxes };
}

export default withRouter(
  connect(
    mapStateToProps,
    { updateTax, onTaxChange, handleChange, onTaxes }
  )(withStyles(styles)(TaxPopup))
);
