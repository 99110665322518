import React, { useState, useEffect } from "react";
import VirtualSelect from "./VirtualSelect";
import PropTypes from "prop-types";
import { sleep, getPaginationInfo } from "../../../utils";
import axios from "../../../api/axiosClient";

function VirtualSelectContainer(props) {
  const page = 1;
  const perPage = 15;
  const showMessage = props.showMessage ? props.showMessage : () => {};
  const { initialSearchText, onSelected, onEmpty = () => {}, width, datasource, hidden, ...rest } = props;
  const onSelectedItem = (val) => {
    props.onSelected(val);
    setState({ ...state, selected: true, searchText: val.name });
  };
  const [state, setState] = useState({
    selected: !!initialSearchText,
    width: width || "100%",
    searchText: initialSearchText || "",
    hidden: typeof hidden !== "undefined" ? hidden : false,
    apiCaller: null,
  });
  const [data, setData] = useState({
    hasNextPage: false,
    isNextPageLoading: false,
    nextPageUrl: null,
    items: [],
  });
  useEffect(() => {
    async function callServer() {
      if (!state.apiCaller) {
        return;
      }
      try {
        const res = await state.apiCaller();
        if (isExactMatch(state.searchText, res.data)) {
          await sleep(300);
          onSelectedItem(res.data[0]);
        } else {
          const pageInfo = getPaginationInfo(res.headers.link);
          if (pageInfo.currentPage === pageInfo.next) {
            setData({
              items: pageInfo.current === "1" ? res.data : [...data.items, ...res.data],
              hasNextPage: false,
              isNextPageLoading: false,
              nextPageUrl: null,
            });
          } else {
            setData({
              items: pageInfo.current === "1" ? res.data : [...data.items, ...res.data],
              hasNextPage: true,
              isNextPageLoading: false,
              nextPageUrl: pageInfo.next,
            });
          }
        }
      } catch (error) {
        console.log(error);
        showMessage();
        setData({ ...data, isNextPageLoading: false });
      }
    }
    callServer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.apiCaller, state.searchText]);

  const search = (searchText, apiCaller) => {
    setState({ ...state, searchText, apiCaller });
    setData({ ...data, isNextPageLoading: true });
  };

  const onChange = (e) => {
    const searchText = state.selected ? "" : e.target.value;
    if (!searchText) {
      onEmpty();
    }
    if (!searchText || searchText.length < 3) {
      setState({ ...state, searchText, selected: false });
      setData({
        hasNextPage: false,
        isNextPageLoading: false,
        nextPageUrl: null,
        items: [],
      });
      return;
    }
    search(searchText, () => datasource(searchText, page, perPage));
  };

  const loadMore = () => {
    if (data.isNextPageLoading || !data.nextPageUrl) {
      return;
    }
    search(state.searchText, () => axios.get(data.nextPageUrl));
  };
  return (
    <VirtualSelect
      searchText={state.searchText}
      width={state.width}
      items={data.items}
      onChange={onChange}
      hasNextPage={data.hasNextPage}
      isNextPageLoading={data.isNextPageLoading}
      loadNextPage={loadMore}
      onSelected={onSelectedItem}
      {...rest}
    />
  );
}

const isExactMatch = (searchText, apiResults) => {
  if (apiResults.length === 0 || apiResults.length > 1) {
    return false;
  }
  const i = apiResults[0];
  return i.id === searchText || i.name === searchText;
};

VirtualSelectContainer.propTypes = {
  showMessage: PropTypes.func,
  initialSearchText: PropTypes.string,
  width: PropTypes.string,
  hideSuggestions: PropTypes.bool,
  placeholder: PropTypes.string,
  datasource: PropTypes.func.isRequired,
  onSelected: PropTypes.func.isRequired,
};
export default VirtualSelectContainer;
