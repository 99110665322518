import React from "react";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/AddCircle";
import { TextField } from "@material-ui/core";
import { setComment, setCustomer, setTransactionOptions } from "../../../../actions/cart";
import api from "../../../../api";
import UserAvatar from "../UserAvatar";
import VirtualSelectContainer from "../../../controls/virtual-select/VirtualSelectContainer";
import { withRouter } from "react-router";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";

function DetailsForm(props) {
  const datasource = (val) => api.customer.searchByIdAndGetByPages(val);
  const onSelected = (val) => props.setCustomer(val);
  const onCreateNewClick = () => {
    props.history.push("customers/new");
  };
  const handleChange = (name) => (event) => {
    props.setTransactionOptions({ ...props.options, [name]: event.target.checked });
  };

  return (
    <React.Fragment>
      <UserAvatar user={props.user} />
      <form noValidate autoComplete="off">
        <div className={props.classes.container}>
          <VirtualSelectContainer
            datasource={datasource}
            onSelected={onSelected}
            placeholder="Digite la identificación del cliente"
            initialSearchText={props.customer ? props.customer.name : null}
          />
          <Tooltip title="Registrar Cliente">
            <IconButton aria-label="Registrar Cliente" color="primary" onClick={onCreateNewClick}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </div>
        <TextField
          id="sale-comment"
          label="Comentario"
          multiline
          maxRows="4"
          value={props.comments}
          style={{ width: "100%" }}
          onChange={(ev) => props.setComment(ev.target.value)}
          margin="normal"
        />
        <FormControl component="fieldset" className={props.classes.formControl}>
          <FormLabel component="legend">Opciones</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.options.forRemission}
                  onChange={handleChange("forRemission")}
                  value="forRemission"
                />
              }
              label="Marcar para remisión"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.options.skipResolution}
                  onChange={handleChange("skipResolution")}
                  value="skipResolution"
                />
              }
              label="Saltar para facturar electrónicamente"
            />
          </FormGroup>
        </FormControl>
      </form>
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    comments: state.cart.summary.comments,
    customer: state.cart.summary.customer,
    boxId: state.cart.boxId,
    options: state.cart.options || {},
  };
}

const mapDispatchToProps = {
  setComment,
  setCustomer,
  setTransactionOptions,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    withStyles(
      (theme) => ({
        container: { display: "flex", flexFlow: "row" },
        formControl: { marginTop: theme.spacing(3) },
      }),
      { withTheme: true }
    )(DetailsForm)
  )
);
