import React from "react";
import { useEffect, useState } from "react";
import api from "../../api";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import axios from "../../api/axiosClient";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/HelpOutline";
import Dropdown from "../controls/dropdown/Dropdown";
import { Typography } from "@material-ui/core";

function BoxList(props) {
  const [boxes, setBoxes] = useState([]);
  const { setFirstLoad } = props;
  const selectedBoxId = props.selectedBoxId;
  const label = props.canOpenBox ? (
    <React.Fragment>
      <div style={{ display: "block", flexFlow: "row" }}>
        {"Seleccionar la caja "}
        <Tooltip
          placement="right"
          title={
            <React.Fragment>
              <Typography color="inherit">
                ¿La caja que buscas no se encuentra en la lista?{" "}
                <Link to="/cashRegister/open">Presiona aquí para abrirla</Link>
              </Typography>
            </React.Fragment>
          }
          interactive
        >
          <IconButton style={{ padding: 0 }} color="primary">
            <AddIcon />
          </IconButton>
        </Tooltip>
      </div>
    </React.Fragment>
  ) : (
    ""
  );

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    (async () => {
      try {
        const res = await api.cashRegister.fetchAll();
        const _boxes = res.data.filter((box) => box.state === "Abierto").map((d) => ({ value: d.id, label: d.name }));
        setBoxes(_boxes);
        setFirstLoad(_boxes[0]);
      } catch (err) {
        console.error(err);
      }
    })();
    return () => {
      source.cancel("Cancelado por unmount");
    };
  }, [setFirstLoad]);

  return (
    <Dropdown
      name="boxId"
      className={props.className}
      value={selectedBoxId}
      error={!!props.errorSelectedBoxId}
      datasource={boxes}
      onChange={props.onBoxDropdownChange}
      placeholder=""
      label={label}
      {...props.DropdownProps}
    />
  );
}

BoxList.propTypes = {
  onBoxDropdownChange: PropTypes.func.isRequired,
};

export default BoxList;
